// ClientContext.tsx
import React, { createContext, useContext, useState } from 'react';
var ClientContext = createContext(undefined);
// Define a context provider component
export var ClientContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState([]), sectionsData = _b[0], setSectionsData = _b[1];
    return (React.createElement(ClientContext.Provider, { value: { sections: sectionsData, setSections: setSectionsData } }, children));
};
// Define a custom hook to access the context
export var useClientContext = function () {
    var context = useContext(ClientContext);
    if (!context) {
        throw new Error('useClientContext must be used within a ClientContextProvider');
    }
    return context;
};
