var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { loggedUser } from "./UserContext/UserReducer";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import Navbar from "./components/Navbar/Navbar";
import { toastConfig } from "./shared/constants";
import NoPermissions from "./pages/NoPermissions/NoPermissions";
var AuthGuardian = function (props) {
    var RenderComponent = props.RenderComponent, authentication = props.authentication;
    var userData = useSelector(loggedUser);
    var validateUserPermissions = function () {
        if (userData.authToken &&
            userData.refreshToken &&
            (authentication === undefined ||
                authentication.includes(userData.role))) {
            // toast("Se ha iniciado sesión.", {...toastConfig, type: "success"})
        }
        else {
            toast("No tienes permiso para acceder a esta página.", __assign(__assign({}, toastConfig), { type: "error" }));
        }
    };
    // useEffect(() => {
    //   console.log(userData)
    //   validateUserPermissions()
    // }, [])
    // Verify if user is logged in
    return (React.createElement(React.Fragment, null, userData.idToken
        && userData.idToken !== "null"
        && userData.idToken !== ""
        && userData.refreshToken ?
        (React.createElement(React.Fragment, null,
            React.createElement(Navbar, null),
            (authentication === undefined ||
                authentication.includes(userData.role)) ?
                (React.createElement(React.Fragment, null, RenderComponent))
                :
                    (React.createElement(React.Fragment, null,
                        React.createElement(NoPermissions, null)))))
        :
            (React.createElement(React.Fragment, null,
                React.createElement(Navigate, { to: '/login' })))));
};
export default AuthGuardian;
