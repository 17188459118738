import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { loggedUser } from "../../UserContext/UserReducer";
import { Roles } from "../../shared/constants";
import ResponsiveGrid from "../../components/ResponsiveGrid/ResponsiveGrid";
import Card from "../../components/Card/Card";
import userIcon from "../../assets/icons/userIcon.png";
import buildingIcon from "../../assets/icons/buildingIcon.png";
import parchmentIcon from "../../assets/icons/parchmentIcon.png";
import formIcon from "../../assets/icons/formIcon.png";
import dashboardIcon from "../../assets/icons/dashboardIcon.png";
import "./Admin.scss";
var Admin = function () {
    // TODO validate with user data if user can access specific option based on user's role
    var userData = useSelector(loggedUser);
    var navigate = useNavigate();
    var _a = useState([]), cardsToDisplay = _a[0], setCardsToDisplay = _a[1];
    var currentDate = new Date();
    var currentMonth = currentDate.getMonth(); // Implicitly subtract 1 from month number
    var currentYear = currentDate.getFullYear();
    if (currentMonth === 0) {
        currentMonth = 12;
        currentYear -= 1;
    }
    var adminModules = [
        {
            name: "Usuarios",
            renderElem: React.createElement(Card, { title: "Usuarios", icon: userIcon, onClick: function () { return navigate("/admin/users"); } }),
            allowedRoles: [Roles.Admin, Roles.Superadmin]
        },
        {
            name: "Empresas",
            renderElem: React.createElement(Card, { title: "Empresas / Contadores", icon: buildingIcon, onClick: function () { return navigate('/admin/companies'); } }),
            allowedRoles: [Roles.Superadmin]
        },
        {
            name: "Clientes",
            renderElem: React.createElement(Card, { title: "Clientes", icon: parchmentIcon, onClick: function () { return navigate('/admin/clients'); } }),
            allowedRoles: [Roles.Admin, Roles.Superadmin]
        },
        {
            name: "Actividad",
            renderElem: React.createElement(Card, { title: "Actividad", icon: formIcon, onClick: function () { return navigate('/admin/activity'); } }),
            allowedRoles: [Roles.Superadmin]
        },
        {
            name: "Dashboard",
            renderElem: React.createElement(Card, { title: "Dashboard", icon: dashboardIcon, onClick: function () { return navigate("/admin/dashboard/".concat(currentYear, "/").concat(currentMonth)); } }),
            allowedRoles: [Roles.Superadmin]
        },
        // {
        //   name: "Ajustes",
        //   renderElem: 
        //     <Card
        //       title="Ajustes" 
        //       icon={gearIcon}
        //       onClick={() => alert("Ajustes")}
        //     />,
        //   allowedRoles: [Roles.Superadmin]
        // },
    ];
    var initCards = function () {
        var finalCards = [];
        adminModules.forEach(function (card) {
            if (card.allowedRoles.includes(userData.role)) {
                finalCards.push(card);
            }
        });
        setCardsToDisplay(finalCards);
    };
    useEffect(function () {
        if (cardsToDisplay.length === 0)
            initCards();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "adminContainer" },
            React.createElement(ResponsiveGrid, { expRows: Math.ceil(cardsToDisplay.length / 3), elements: cardsToDisplay, gridHeight: 42, gridWidth: 80 }),
            React.createElement("div", { className: "adminVersion" }, "".concat(process.env.VERSION)))));
};
export default Admin;
