var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var api_url = process.env.API_URL;
var fel_url = process.env.FEL_URL;
export var getExcelSignedURL = function (idToken, nitParam, monthParam, yearParam, actionType, documentType) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    id: "".concat(nitParam, "_").concat(monthParam, "_").concat(yearParam, "_").concat(actionType),
                    documentType: documentType
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/upload"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_1 = _a.sent();
                console.log(e_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getClientsExcelSignedURL = function (idToken, currDate, documentType) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    id: "new_clients_".concat(String(currDate.getFullYear()).slice(-2), "_").concat(String(currDate.getMonth() + 1).padStart(2, '0'), "_").concat(String(currDate.getDate()).padStart(2, '0')),
                    documentType: documentType
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/upload"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_2 = _a.sent();
                console.log(e_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var uploadExcelDocument = function (signedURL, file) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = file;
                reqOpt = {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/vnd.ms-excel",
                    },
                    body: reqBody,
                };
                return [4 /*yield*/, fetch("".concat(signedURL), reqOpt).then(function (resp) {
                        return resp.text();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_3 = _a.sent();
                console.log(e_3);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getDocumentTypes = function (idToken) { return __awaiter(void 0, void 0, void 0, function () {
    var reqOpt, response, data, errorMessage, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqOpt = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/documents/types"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_4 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var getTaxTypes = function (idToken) { return __awaiter(void 0, void 0, void 0, function () {
    var reqOpt, response, data, errorMessage, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqOpt = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/taxes/types"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_5 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var uploadFEL = function (idToken, company, nitParam, monthParam, yearParam, isPurchase, fileExtension) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    key: "".concat(company, "/").concat(nitParam, "_").concat(monthParam, "_").concat(yearParam, "_").concat(isPurchase === 0 ? "sales" : "purchases", ".").concat(fileExtension),
                    nit: nitParam,
                    month: parseInt(monthParam),
                    year: parseInt(yearParam),
                    is_purchases: isPurchase,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(fel_url, "/uploadFEL"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_6 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var bulkCreateClients = function (idToken, company, currDate, fileExtension) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    key: "".concat(company, "/new_clients_").concat(String(currDate.getFullYear()).slice(-2), "_").concat(String(currDate.getMonth() + 1).padStart(2, '0'), "_").concat(String(currDate.getDate()).padStart(2, '0'), ".").concat(fileExtension),
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(fel_url, "/bulkUploadClients"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_7 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var createDocument = function (idToken, documentId, nit, total, date, // yyyy-mm-dd
isPurchase, docTypeId, name, serie, number, docNit, taxId, tax, quant, desc, exent) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    documentId: documentId,
                    nit: nit,
                    total: total,
                    date: date,
                    isPurchase: isPurchase,
                    docTypeId: docTypeId,
                    name: name,
                    serie: serie,
                    number: number,
                    docNit: docNit,
                    taxId: taxId,
                    tax: tax,
                    quant: quant,
                    desc: desc,
                    exent: exent,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/documents/create"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_8 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var getCorrelative = function (idToken, nitParam, monthParam, yearParam, isPurchase) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nitParam,
                    month: parseInt(monthParam),
                    year: parseInt(yearParam),
                    isPurchase: isPurchase,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/correlative"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_9 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var getLatestCorrelative = function (idToken, nitParam, isPurchase) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nitParam,
                    isPurchase: isPurchase,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/correlative/latest"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_10 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var createCorrelative = function (idToken, nitParam, monthParam, yearParam, isPurchase, folio, lastFolio) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nitParam,
                    month: monthParam,
                    year: yearParam,
                    isPurchase: isPurchase,
                    folio: folio,
                    lastFolio: lastFolio,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/correlative/create"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_11 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var getPDFSignedURL = function (idToken, docID, documentType) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    id: "".concat(docID),
                    documentType: documentType
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/upload"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_12 = _a.sent();
                console.log(e_12);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var uploadPDFDocument = function (signedURL, file) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_13;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = file;
                reqOpt = {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/pdf",
                    },
                    body: reqBody,
                };
                return [4 /*yield*/, fetch("".concat(signedURL), reqOpt).then(function (resp) {
                        return resp.text();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_13 = _a.sent();
                console.log(e_13);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var deletePDFDocument = function (idToken, documentId) { return __awaiter(void 0, void 0, void 0, function () {
    var reqOpt, response, e_14;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqOpt = {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/documents/").concat(documentId, "?type=pdf"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_14 = _a.sent();
                console.log(e_14);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var assignPDF = function (idToken, document_id, fileExtension) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_15;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    documentType: fileExtension,
                    documentId: document_id,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/documents/pdf"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_15 = _a.sent();
                console.log(e_15);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var assignRetentionPDF = function (idToken, document_id, fileExtension) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_16;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    documentType: fileExtension,
                    documentId: document_id,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/retenciones/pdf"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_16 = _a.sent();
                console.log(e_16);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var uploadRetentions = function (idToken, company, nitParam, monthParam, yearParam, taxType, fileExtension) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_17;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    nit: nitParam,
                    month: parseInt(monthParam),
                    year: parseInt(yearParam),
                    key: "".concat(company, "/").concat(nitParam, "_").concat(monthParam, "_").concat(yearParam, "_").concat(taxType, ".").concat(fileExtension)
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(fel_url, "/uploadOtherDocument"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_17 = _a.sent();
                console.log(e_17);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var deleteRetention = function (idToken, document_id, fileExtension) { return __awaiter(void 0, void 0, void 0, function () {
    var reqOpt, response, data, errorMessage, e_18;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqOpt = {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/retenciones/").concat(document_id).concat(fileExtension ? ("?type=" + fileExtension) : ""), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_18 = _a.sent();
                console.log(e_18);
                return [3 /*break*/, 7];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var getPresentedForm = function (idToken, nitParam, month, year, formId) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_19;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nitParam,
                    month: month,
                    year: year,
                    formId: formId,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/formularioPresentado"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_19 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
