var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import logo from "../../assets/logo-login.svg";
import { toast } from 'react-toastify';
import { toastConfig } from '../../shared/constants';
import 'react-toastify/dist/ReactToastify.css';
import { challengeLogin, logIn, forgotPassword, confirmForgotPassword } from '../../services/auth';
import { SET_USER_CHALLENGED, SET_USER_LOGGED, loggedUser } from '../../UserContext/UserReducer';
import { useDispatch, useSelector } from 'react-redux';
import { LoginSteps, Roles } from '../../shared/constants';
import "./Login.scss";
import { setCookie } from '../../shared/utils';
var Login = function () {
    var dispatch = useDispatch();
    var userData = useSelector(loggedUser);
    var navigate = useNavigate();
    var _a = useState(""), user = _a[0], setUser = _a[1];
    var _b = useState(""), password = _b[0], setPassword = _b[1];
    var _c = useState(""), newPassword = _c[0], setNewPassword = _c[1];
    var _d = useState(""), confirmNewPassword = _d[0], setConfirmNewPassword = _d[1];
    var _e = useState(""), emailCode = _e[0], setEmailCode = _e[1];
    var _f = useState(LoginSteps.Main), currentStep = _f[0], setCurrentStep = _f[1];
    var _g = useState({}), loginResp = _g[0], setLoginResp = _g[1];
    var _h = useState(false), loadingLogin = _h[0], setLoadingLogin = _h[1];
    var validateMainLogin = function () {
        var loginIsValid = true;
        if (user === "") {
            loginIsValid = false;
            toast("El usuario no puede estar vacío.", __assign(__assign({}, toastConfig), { type: "warning" }));
        }
        if (password === "") {
            loginIsValid = false;
            toast("La contraseña no puede estar vacía.", __assign(__assign({}, toastConfig), { type: "warning" }));
        }
        return loginIsValid;
    };
    var validateNewPassword = function () {
        var passwordIsValid = true;
        if (newPassword !== confirmNewPassword) {
            passwordIsValid = false;
            toast("La contraseña no coincide.", __assign(__assign({}, toastConfig), { type: "warning" }));
        }
        if (newPassword.length < 8 && passwordIsValid) {
            passwordIsValid = false;
            toast("La contraseña debe tener al menos 8 caracteres.", __assign(__assign({}, toastConfig), { type: "warning" }));
        }
        if (passwordIsValid) {
            // Define regular expressions for each validation rule
            var uppercaseRegex = /[A-Z]/;
            var lowercaseRegex = /[a-z]/;
            var numberRegex = /[0-9]/;
            var specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~`]+/; // Add your desired special characters here
            // Test the input string against each regex
            var hasUppercase = uppercaseRegex.test(newPassword);
            var hasLowercase = lowercaseRegex.test(newPassword);
            var hasNumber = numberRegex.test(newPassword);
            var hasSpecialChar = specialCharRegex.test(newPassword);
            if (!hasUppercase) {
                passwordIsValid = false;
                toast("La contraseña debe tener al menos 1 letra mayúscula.", __assign(__assign({}, toastConfig), { type: "warning" }));
            }
            if (!hasLowercase) {
                passwordIsValid = false;
                toast("La contraseña debe tener al menos 1 letra minúscula.", __assign(__assign({}, toastConfig), { type: "warning" }));
            }
            if (!hasNumber) {
                passwordIsValid = false;
                toast("La contraseña debe tener al menos 1 número.", __assign(__assign({}, toastConfig), { type: "warning" }));
            }
            if (!hasSpecialChar) {
                passwordIsValid = false;
                toast("La contraseña debe tener al menos 1 caracter especial.", __assign(__assign({}, toastConfig), { type: "warning" }));
            }
        }
        return passwordIsValid;
    };
    var validateForgotPassword = function () {
        var passwordIsValid = validateNewPassword();
        if (emailCode === "") {
            passwordIsValid = false;
            toast("El código no puede estar vacío.", __assign(__assign({}, toastConfig), { type: "warning" }));
        }
        return passwordIsValid;
    };
    var initForgotPassword = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!(user === "")) return [3 /*break*/, 1];
                    toast("Por favor ingresar usuario para cambiar la contraseña.", __assign(__assign({}, toastConfig), { type: "warning" }));
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, forgotPassword(user)];
                case 2:
                    resp = _a.sent();
                    console.log(resp);
                    if (resp === null || resp === void 0 ? void 0 : resp.CodeDeliveryDetails) {
                        toast("Un C\u00F3digo de Verificaci\u00F3n fue enviado a ".concat(resp.CodeDeliveryDetails.Destination), __assign(__assign({}, toastConfig), { type: "warning" }));
                        setCurrentStep(LoginSteps.Forgot);
                    }
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var regularLogin = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!validateMainLogin()) return [3 /*break*/, 2];
                    setLoadingLogin(true);
                    return [4 /*yield*/, logIn(user, password)];
                case 1:
                    resp = _e.sent();
                    setLoginResp(resp);
                    if (resp === null || resp === void 0 ? void 0 : resp.ChallengeName) {
                        dispatch(SET_USER_CHALLENGED({
                            name: (_a = resp.ChallengeParameters) === null || _a === void 0 ? void 0 : _a.USER_ID_FOR_SRP,
                            sessionToken: resp.Session
                        }));
                        setCurrentStep(LoginSteps.Challenge);
                        setLoadingLogin(false);
                    }
                    else if (resp) {
                        if (((_b = resp.AuthenticationResult) === null || _b === void 0 ? void 0 : _b.AccessToken)
                            && ((_c = resp.AuthenticationResult) === null || _c === void 0 ? void 0 : _c.RefreshToken)
                            && ((_d = resp.AuthenticationResult) === null || _d === void 0 ? void 0 : _d.IdToken)) { // Successful login
                            dispatch(SET_USER_LOGGED({
                                name: user,
                                authToken: resp.AuthenticationResult.AccessToken,
                                idToken: resp.AuthenticationResult.IdToken,
                                refreshToken: resp.AuthenticationResult.RefreshToken,
                                groups: resp.groups,
                                role: (resp.groups && resp.groups.includes(Roles.Superadmin))
                                    ? Roles.Superadmin
                                    : (resp.groups && resp.groups.includes(Roles.Admin))
                                        ? Roles.Admin
                                        : (resp.groups && resp.groups.includes(Roles.Bot))
                                            ? Roles.Bot
                                            : Roles.User
                            }));
                            if (resp.cookies) {
                                setCookie("CloudFront-Key-Pair-Id", resp.cookies["CloudFront-Key-Pair-Id"] || "");
                                setCookie("CloudFront-Policy", resp.cookies["CloudFront-Policy"] || "");
                                setCookie("CloudFront-Signature", resp.cookies["CloudFront-Signature"] || "");
                            }
                            toast("Se ha iniciado sesión.", __assign(__assign({}, toastConfig), { type: "success" }));
                            setCurrentStep(LoginSteps.Success);
                            setLoadingLogin(false);
                        }
                        else if (resp.message) { // Authentication error
                            toast(resp.message, __assign(__assign({}, toastConfig), { type: "error" }));
                            setLoadingLogin(false);
                        }
                    }
                    else {
                        toast("Error de servidor.", __assign(__assign({}, toastConfig), { type: "error" }));
                        setLoadingLogin(false);
                    }
                    _e.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var startChallengeLogin = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        var _a, _b, _c, _d;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    if (!validateNewPassword()) return [3 /*break*/, 2];
                    return [4 /*yield*/, challengeLogin(user, newPassword, loginResp.ChallengeName, loginResp.Session)];
                case 1:
                    resp = _e.sent();
                    if (resp) {
                        if (((_a = resp.AuthenticationResult) === null || _a === void 0 ? void 0 : _a.AccessToken)
                            && ((_b = resp.AuthenticationResult) === null || _b === void 0 ? void 0 : _b.RefreshToken)
                            && ((_c = resp.AuthenticationResult) === null || _c === void 0 ? void 0 : _c.IdToken)) {
                            dispatch(SET_USER_LOGGED({
                                name: ((_d = loginResp.ChallengeParameters) === null || _d === void 0 ? void 0 : _d.USER_ID_FOR_SRP) || user,
                                authToken: resp.AuthenticationResult.AccessToken,
                                idToken: resp.AuthenticationResult.IdToken,
                                refreshToken: resp.AuthenticationResult.RefreshToken,
                                groups: resp.groups,
                                role: (resp.groups && resp.groups.includes(Roles.Superadmin))
                                    ? Roles.Superadmin
                                    : (resp.groups && resp.groups.includes(Roles.Admin))
                                        ? Roles.Admin
                                        : (resp.groups && resp.groups.includes(Roles.Bot))
                                            ? Roles.Bot
                                            : Roles.User
                            }));
                            if (resp.cookies) {
                                setCookie("CloudFront-Key-Pair-Id", resp.cookies["CloudFront-Key-Pair-Id"] || "");
                                setCookie("CloudFront-Policy", resp.cookies["CloudFront-Policy"] || "");
                                setCookie("CloudFront-Signature", resp.cookies["CloudFront-Signature"] || "");
                            }
                            toast("Se ha iniciado sesión.", __assign(__assign({}, toastConfig), { type: "success" }));
                            setCurrentStep(LoginSteps.Success);
                        }
                        else if (resp.message) { // Authentication error
                            toast(resp.message, __assign(__assign({}, toastConfig), { type: "error" }));
                        }
                    }
                    _e.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var startForgotChange = function () { return __awaiter(void 0, void 0, void 0, function () {
        var resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateForgotPassword()) return [3 /*break*/, 2];
                    return [4 /*yield*/, confirmForgotPassword(user, newPassword, emailCode)];
                case 1:
                    resp = _a.sent();
                    if (resp === null || resp === void 0 ? void 0 : resp.message) {
                        if (resp.message === "success") {
                            toast("Se ha cambiado la contraseña.", __assign(__assign({}, toastConfig), { type: "success" }));
                            setPassword("");
                            setCurrentStep(LoginSteps.Main);
                        }
                        else {
                            toast(resp.message, __assign(__assign({}, toastConfig), { type: "error" }));
                        }
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var inputEnterMapper = function (ev, callback) {
        if (ev.key === "Enter") {
            callback();
        }
    };
    useEffect(function () {
        if (userData.idToken && userData.idToken !== "null" && userData.idToken !== "") {
            // navigate('/')
        }
    }, []);
    return (React.createElement(React.Fragment, null,
        currentStep === LoginSteps.Main && // Regular login
            React.createElement("div", { className: "loginContainer ".concat(currentStep === LoginSteps.Main ? "" : "slideOutAnimClass") },
                React.createElement("div", { className: "loginLogo" },
                    React.createElement("img", { src: logo, draggable: false })),
                React.createElement("input", { className: "loginInput", type: "text", placeholder: "Usuario", name: "username", id: "username", autoComplete: "username", value: user, onChange: function (ev) { return setUser(ev.target.value); } }),
                React.createElement("input", { className: "loginInputPassword", type: "password", placeholder: "Contrase\u00F1a", name: "password", id: "password", autoComplete: "current-password", value: password, onChange: function (ev) { return setPassword(ev.target.value); }, onKeyDown: loadingLogin ? function () { } : function (ev) { return inputEnterMapper(ev, regularLogin); } }),
                React.createElement("div", { className: "loginForgotPassword", tabIndex: 0, onClick: function () { return initForgotPassword(); } }, "Olvid\u00E9 mi contrase\u00F1a..."),
                !loadingLogin
                    ?
                        React.createElement("button", { className: "loginButton", onClick: function () { return regularLogin(); } }, "Ingresar")
                    :
                        React.createElement("button", { className: "loginButton", disabled: true }, "Cargando...")),
        currentStep === LoginSteps.Challenge && // Change current password (challenge user)
            React.createElement("div", { className: 'loginChallengeContainer' },
                React.createElement("div", { className: 'topLogoContainer' },
                    React.createElement("div", { className: "cornerLogo" },
                        React.createElement("img", { src: logo }))),
                React.createElement("div", { className: 'loginChallengeTitle' }, "Cambiar Contrase\u00F1a"),
                React.createElement("div", { className: 'loginInstructions' },
                    "Tu contrase\u00F1a debe contener al menos:",
                    React.createElement("ul", null,
                        React.createElement("li", null,
                            React.createElement("b", { className: 'highlightedText' }, "1 letra"),
                            " may\u00FAscula"),
                        React.createElement("li", null,
                            React.createElement("b", { className: 'highlightedText' }, "1 n\u00FAmero")),
                        React.createElement("li", null,
                            React.createElement("b", { className: 'highlightedText' }, "1 s\u00EDmbolo"),
                            " o caracter especial (",
                            React.createElement("b", { className: 'highlightedText' }, "@ # $ + - = !"),
                            ")"),
                        React.createElement("li", null,
                            React.createElement("b", { className: 'highlightedText' }, "Al menos 8"),
                            " caracteres"))),
                React.createElement("input", { className: "loginChallengeInput", type: "password", name: "password", placeholder: "Nueva Contrase\u00F1a", value: newPassword, onChange: function (ev) { return setNewPassword(ev.target.value); } }),
                React.createElement("input", { className: "loginChallengeInput", type: "password", placeholder: "Confirma Nueva Contrase\u00F1a", value: confirmNewPassword, onChange: function (ev) { return setConfirmNewPassword(ev.target.value); }, onKeyDown: function (ev) { return inputEnterMapper(ev, startChallengeLogin); } }),
                React.createElement("button", { className: "loginButton", onClick: function () { return startChallengeLogin(); } }, "Guardar Contrase\u00F1a")),
        currentStep === LoginSteps.Forgot && // Forgot Password
            React.createElement("div", { className: 'loginChallengeContainer' },
                React.createElement("div", { className: 'topLogoContainer' },
                    React.createElement("div", { className: "cornerLogo", style: { cursor: "pointer" }, onClick: function () { return setCurrentStep(LoginSteps.Main); } },
                        React.createElement("img", { src: logo }))),
                React.createElement("div", { className: 'loginChallengeTitle' }, "Restaurar Contrase\u00F1a"),
                React.createElement("input", { className: "loginForgotInput", name: "confirmationCode", type: "text", autoComplete: "none", placeholder: "C\u00F3digo de Verificaci\u00F3n", value: emailCode, onChange: function (ev) { return setEmailCode(ev.target.value); } }),
                React.createElement("input", { className: "loginForgotInput", name: "password", type: "password", placeholder: "Nueva Contrase\u00F1a", value: newPassword, onChange: function (ev) { return setNewPassword(ev.target.value); } }),
                React.createElement("input", { className: "loginForgotInput", type: "password", placeholder: "Confirma Nueva Contrase\u00F1a", value: confirmNewPassword, onChange: function (ev) { return setConfirmNewPassword(ev.target.value); }, onKeyDown: function (ev) { return inputEnterMapper(ev, startForgotChange); } }),
                React.createElement("button", { className: "loginButton", onClick: function () { return startForgotChange(); } }, "Guardar Contrase\u00F1a")),
        currentStep === LoginSteps.Success &&
            React.createElement(Navigate, { to: "/" })));
};
export default Login;
