import React from "react";
import "./Card.scss";
;
var Card = function (props) {
    var title = props.title, icon = props.icon, vertical = props.vertical, onClick = props.onClick;
    return (React.createElement("div", { className: "cardContainer", style: {
            flexDirection: vertical ? "column" : "row"
        }, tabIndex: 0, onClick: function () {
            if (onClick)
                onClick();
        } },
        icon &&
            React.createElement("div", { className: "cardImage" },
                React.createElement("img", { src: icon, alt: title })),
        React.createElement("div", { className: "cardTitle" }, title)));
};
export default Card;
