import React from "react";
import "./ResponsiveGrid.scss";
var ResponsiveGrid = function (props) {
    var expRows = props.expRows, gridWidth = props.gridWidth, gridHeight = props.gridHeight, gridGap = props.gridGap, elements = props.elements;
    var cols = Math.ceil(elements.length / expRows);
    return (React.createElement("div", { className: "wrapper", style: {
            gridTemplateColumns: "repeat(".concat(cols, ", ").concat(Math.round((gridWidth || 70) / cols), "vw)"),
            gap: gridGap || "40px"
        } }, elements.map(function (elem, ind) {
        return (React.createElement("div", { key: elem.name, style: {
                gridColumn: "".concat((ind + 1) > cols ? (ind - cols) + 1 : (ind + 1)),
                gridRow: "".concat(Math.ceil((ind + 1) / cols)),
                height: "".concat(Math.round((gridHeight || 26) / cols), "vw")
            } }, elem.renderElem));
    })));
};
export default ResponsiveGrid;
