import React from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
var SummaryMonthGenerator = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3;
    var data = _a.data, nitInfo = _a.nitInfo, params = _a.params;
    var sectionNames = data.map(function (e) { return e.sectionName; });
    var tables = data.map(function (e) { return e.tables.map(function (table) { return table; }); });
    var firstColumnValues = data.flatMap(function (e) { return e.tables.flatMap(function (table) { var _a; return (_a = table[0]) === null || _a === void 0 ? void 0 : _a.columns[0]; }); });
    var primerArray = tables[0], segundoArray = tables[1], tercerArray = tables[2];
    var primerArrayTable = primerArray === null || primerArray === void 0 ? void 0 : primerArray[0];
    var arrayISRTrimestral = segundoArray === null || segundoArray === void 0 ? void 0 : segundoArray[0];
    var arrayISRTrimestralCreditoFiscal = segundoArray === null || segundoArray === void 0 ? void 0 : segundoArray[1];
    var arrayISRTrimestralImpuestoPagar = segundoArray === null || segundoArray === void 0 ? void 0 : segundoArray[2];
    var arrayISRTrimestralIndicadorComercial = segundoArray === null || segundoArray === void 0 ? void 0 : segundoArray[3];
    var arrayISRTrimestralOpRealizadas = segundoArray === null || segundoArray === void 0 ? void 0 : segundoArray[4];
    var primerArrayTableCompras = primerArray === null || primerArray === void 0 ? void 0 : primerArray[1];
    var arrayPeqContribuyente = primerArray === null || primerArray === void 0 ? void 0 : primerArray[0];
    var arraySAT1311 = segundoArray === null || segundoArray === void 0 ? void 0 : segundoArray[0];
    var arraySAT2237 = tercerArray === null || tercerArray === void 0 ? void 0 : tercerArray[0];
    var arrayCreditoFiscal = tercerArray === null || tercerArray === void 0 ? void 0 : tercerArray[1];
    var determinacionImpuestoPagar = tercerArray === null || tercerArray === void 0 ? void 0 : tercerArray[2];
    var indicadoresComerciales = tercerArray === null || tercerArray === void 0 ? void 0 : tercerArray[3];
    var ArraycantidadOperacionesRealizadas = tercerArray === null || tercerArray === void 0 ? void 0 : tercerArray[4];
    var styles = StyleSheet.create({
        header: {
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
            padding: 10,
            width: '100%',
            backgroundColor: '#cccccc',
        },
        title: {
            fontSize: 16,
            fontWeight: 'bold',
            textAlign: 'center',
            width: '100%',
        },
        subtitle: {
            fontSize: 14,
            textAlign: 'center',
            marginTop: 5,
            width: '100%',
        },
        date: {
            fontSize: 12,
            textAlign: 'right',
            width: '50%',
            paddingRight: '5%'
        },
        logo: {
            width: 25,
            height: 25,
        },
        logoContainer: {
            marginRight: 'auto',
        },
        page: {
            flexDirection: 'row',
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1,
        },
        table: {
            width: '100%',
            marginBottom: 5,
        },
        tableRow: {
            flexDirection: 'row',
        },
        tableCol: {
            width: '50%',
            border: '1px solid black',
        },
        tableHeader: {
            backgroundColor: '#cccccc',
            fontWeight: 'bold',
        },
        cell: {
            padding: 5,
            fontSize: 12,
        },
        solidLine: {
            border: '1px solid black',
        }
    });
    var generateTableDataDynamic = function (dataArray) {
        return (dataArray || []).slice(1).map(function (item) {
            var _a, _b;
            var rowData = [];
            var color = null;
            for (var i = 0; i < item.columns.length; i++) {
                var header = (_a = item.columns[i]) === null || _a === void 0 ? void 0 : _a.header;
                if (header !== null && header !== undefined) {
                    rowData.push(header);
                }
                else {
                    rowData.push('');
                }
                if ((_b = item.columns[i]) === null || _b === void 0 ? void 0 : _b.bgColor) {
                    color = item.columns[i].bgColor;
                }
            }
            return {
                rowData: rowData,
                color: color
            };
        });
    };
    var DynamicTable = function (_a) {
        var header = _a.header, data = _a.data, title = _a.title;
        return (React.createElement(View, { style: styles.section },
            React.createElement(Text, null, title),
            React.createElement(View, { style: styles.table },
                React.createElement(View, { style: [styles.tableRow] },
                    React.createElement(View, { style: [styles.tableCol, styles.tableHeader] },
                        React.createElement(Text, { style: styles.cell }, header === null || header === void 0 ? void 0 : header[0])),
                    React.createElement(View, { style: [styles.tableCol, styles.tableHeader] },
                        React.createElement(Text, { style: styles.cell }, header === null || header === void 0 ? void 0 : header[1])),
                    (header === null || header === void 0 ? void 0 : header[2]) && (React.createElement(View, { style: [styles.tableCol, styles.tableHeader] },
                        React.createElement(Text, { style: styles.cell }, header === null || header === void 0 ? void 0 : header[2])))),
                data.map(function (rowData, rowIndex) { return (React.createElement(View, { key: rowIndex, style: [styles.tableRow, { backgroundColor: rowData.color }] }, rowData.rowData.map(function (cellData, cellIndex) { return (React.createElement(View, { key: cellIndex, style: styles.tableCol },
                    React.createElement(Text, { style: styles.cell }, cellData))); }))); }))));
    };
    var IsrTrimestral = function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o;
        return (React.createElement(React.Fragment, null,
            React.createElement(Page, null,
                React.createElement(DynamicTable, { title: sectionNames[1], header: [(_a = firstColumnValues[1]) === null || _a === void 0 ? void 0 : _a.header, (_b = arrayISRTrimestral === null || arrayISRTrimestral === void 0 ? void 0 : arrayISRTrimestral[0].columns[1]) === null || _b === void 0 ? void 0 : _b.header, (_c = arrayISRTrimestral === null || arrayISRTrimestral === void 0 ? void 0 : arrayISRTrimestral[0].columns[2]) === null || _c === void 0 ? void 0 : _c.header], data: generateTableDataDynamic(arrayISRTrimestral) })),
            React.createElement(Page, null,
                React.createElement(DynamicTable, { title: sectionNames[2], header: [(_d = firstColumnValues[2]) === null || _d === void 0 ? void 0 : _d.header, (_e = arrayISRTrimestralCreditoFiscal === null || arrayISRTrimestralCreditoFiscal === void 0 ? void 0 : arrayISRTrimestralCreditoFiscal[0].columns[1]) === null || _e === void 0 ? void 0 : _e.header, (_f = arrayISRTrimestralCreditoFiscal === null || arrayISRTrimestralCreditoFiscal === void 0 ? void 0 : arrayISRTrimestralCreditoFiscal[0].columns[2]) === null || _f === void 0 ? void 0 : _f.header], data: generateTableDataDynamic(arrayISRTrimestralCreditoFiscal) })),
            React.createElement(Page, null,
                React.createElement(DynamicTable, { header: [(_g = firstColumnValues[3]) === null || _g === void 0 ? void 0 : _g.header, ''], data: generateTableDataDynamic(arrayISRTrimestralImpuestoPagar) }),
                React.createElement(DynamicTable, { header: [(_h = firstColumnValues[4]) === null || _h === void 0 ? void 0 : _h.header, ''], data: generateTableDataDynamic(arrayISRTrimestralIndicadorComercial) })),
            React.createElement(Page, null,
                React.createElement(DynamicTable, { header: [(_j = firstColumnValues[5]) === null || _j === void 0 ? void 0 : _j.header, (_l = (_k = arrayISRTrimestralOpRealizadas === null || arrayISRTrimestralOpRealizadas === void 0 ? void 0 : arrayISRTrimestralOpRealizadas[0]) === null || _k === void 0 ? void 0 : _k.columns[1]) === null || _l === void 0 ? void 0 : _l.header, (_o = (_m = arrayISRTrimestralOpRealizadas === null || arrayISRTrimestralOpRealizadas === void 0 ? void 0 : arrayISRTrimestralOpRealizadas[0]) === null || _m === void 0 ? void 0 : _m.columns[2]) === null || _o === void 0 ? void 0 : _o.header], data: generateTableDataDynamic(arrayISRTrimestralOpRealizadas) }))));
    };
    return (React.createElement(Document, null,
        React.createElement(Page, null,
            React.createElement(View, { style: styles.header },
                React.createElement(View, null,
                    React.createElement(Text, { style: styles.title }, "".concat(nitInfo.name, ": ").concat(nitInfo.nit)),
                    React.createElement(Text, { style: styles.subtitle }, "Resumen del mes: ".concat(params.month < 10 ? '0' + params.month : params.month, "/").concat(params.year))),
                React.createElement(Text, { style: styles.date }, "Generado: ".concat(new Date().toLocaleDateString()))),
            ((_b = data[0]) === null || _b === void 0 ? void 0 : _b.formId) !== 1 && ((_c = data[0]) === null || _c === void 0 ? void 0 : _c.formId) !== 2046 && (React.createElement(React.Fragment, null, primerArray && (React.createElement(React.Fragment, null,
                React.createElement(DynamicTable, { title: (_d = firstColumnValues[0]) === null || _d === void 0 ? void 0 : _d.header, data: generateTableDataDynamic(primerArrayTable) }),
                React.createElement(DynamicTable, { title: (_e = firstColumnValues[1]) === null || _e === void 0 ? void 0 : _e.header, data: generateTableDataDynamic(primerArrayTableCompras) }))))),
            ((_f = data[0]) === null || _f === void 0 ? void 0 : _f.formId) !== 1 && ((_g = data[0]) === null || _g === void 0 ? void 0 : _g.formId) == 2046 && (React.createElement(DynamicTable, { title: (_h = firstColumnValues[0]) === null || _h === void 0 ? void 0 : _h.header, header: ['', 'Monto'], data: generateTableDataDynamic(arrayPeqContribuyente) })),
            ((_j = data[0]) === null || _j === void 0 ? void 0 : _j.formId) == 1 && (React.createElement(DynamicTable, { title: (_k = firstColumnValues[0]) === null || _k === void 0 ? void 0 : _k.header, data: generateTableDataDynamic(primerArrayTable) }))),
        ((_l = data[0]) === null || _l === void 0 ? void 0 : _l.formId) !== 2046 && ((_m = data[0]) === null || _m === void 0 ? void 0 : _m.formId) !== 1 && (React.createElement(React.Fragment, null, tercerArray && (React.createElement(React.Fragment, null,
            React.createElement(Page, null,
                React.createElement(DynamicTable, { title: sectionNames[1], data: generateTableDataDynamic(arraySAT1311) }),
                React.createElement(DynamicTable, { title: sectionNames[2], header: [(_o = firstColumnValues[3]) === null || _o === void 0 ? void 0 : _o.header, (_p = arraySAT2237 === null || arraySAT2237 === void 0 ? void 0 : arraySAT2237[0].columns[1]) === null || _p === void 0 ? void 0 : _p.header, (_q = arraySAT2237 === null || arraySAT2237 === void 0 ? void 0 : arraySAT2237[0].columns[2]) === null || _q === void 0 ? void 0 : _q.header], data: generateTableDataDynamic(arraySAT2237) })),
            React.createElement(Page, null,
                React.createElement(DynamicTable, { header: [(_r = firstColumnValues[4]) === null || _r === void 0 ? void 0 : _r.header, (_t = (_s = arrayCreditoFiscal === null || arrayCreditoFiscal === void 0 ? void 0 : arrayCreditoFiscal[0]) === null || _s === void 0 ? void 0 : _s.columns[1]) === null || _t === void 0 ? void 0 : _t.header, (_v = (_u = arrayCreditoFiscal === null || arrayCreditoFiscal === void 0 ? void 0 : arrayCreditoFiscal[0]) === null || _u === void 0 ? void 0 : _u.columns[2]) === null || _v === void 0 ? void 0 : _v.header], data: generateTableDataDynamic(arrayCreditoFiscal) })),
            React.createElement(Page, null,
                React.createElement(DynamicTable, { header: [(_w = firstColumnValues[5]) === null || _w === void 0 ? void 0 : _w.header, ''], data: generateTableDataDynamic(determinacionImpuestoPagar) }),
                React.createElement(DynamicTable, { header: [(_x = firstColumnValues[6]) === null || _x === void 0 ? void 0 : _x.header, 'Monto'], data: generateTableDataDynamic(indicadoresComerciales) })),
            React.createElement(Page, null,
                React.createElement(DynamicTable, { header: [(_y = firstColumnValues[7]) === null || _y === void 0 ? void 0 : _y.header, (_0 = (_z = ArraycantidadOperacionesRealizadas === null || ArraycantidadOperacionesRealizadas === void 0 ? void 0 : ArraycantidadOperacionesRealizadas[0]) === null || _z === void 0 ? void 0 : _z.columns[1]) === null || _0 === void 0 ? void 0 : _0.header, (_2 = (_1 = ArraycantidadOperacionesRealizadas === null || ArraycantidadOperacionesRealizadas === void 0 ? void 0 : ArraycantidadOperacionesRealizadas[0]) === null || _1 === void 0 ? void 0 : _1.columns[2]) === null || _2 === void 0 ? void 0 : _2.header], data: generateTableDataDynamic(ArraycantidadOperacionesRealizadas) })))))),
        ((_3 = data[0]) === null || _3 === void 0 ? void 0 : _3.formId) == 1 && (React.createElement(IsrTrimestral, null))));
};
export default SummaryMonthGenerator;
