var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { utils, writeFile } from 'xlsx';
/**
 * Orders one array of strings alphabetically
 */
export var arrayOrderByString = function (arr) {
    arr.sort(function (a, b) {
        var stringA = a.toUpperCase();
        var stringB = b.toUpperCase();
        if (stringA < stringB) {
            return -1;
        }
        if (stringA > stringB) {
            return 1;
        }
        return 0;
    });
};
/**
 * Orders one array of objects alphabetically based on property param and saves it on same array's reference
 * @param arr array that will be ordered
 * @param property property that will be used to order the array
 */
export var arrayOrderByProperty = function (arr, property) {
    arr.sort(function (a, b) {
        var propA = String(a[property]).toUpperCase();
        var propB = String(b[property]).toUpperCase();
        if (propA < propB) {
            return -1;
        }
        if (propA > propB) {
            return 1;
        }
        return 0;
    });
};
/**
 * Orders one array of objects alphabetically based on nested property param and saves it on same array's reference
 * @param arr array that will be ordered
 * @param nestedProperty property that contains
 */
export var arrayOrderByNestedProperty = function (arr, nestedProperty) {
    return arr.slice().sort(function (a, b) {
        var propA = String(getNestedProperty(a, nestedProperty)).toUpperCase();
        var propB = String(getNestedProperty(b, nestedProperty)).toUpperCase();
        if (propA < propB) {
            return -1;
        }
        if (propA > propB) {
            return 1;
        }
        return 0;
    });
};
/**
 * Returns nested property of an object
 * @param obj object to be read
 * @param property nested property to be extracted
 */
export var getNestedProperty = function (obj, property, separator) {
    if (separator === void 0) { separator = "."; }
    if (property === undefined)
        return obj;
    var properties = property.split(separator);
    var value = obj;
    for (var _i = 0, properties_1 = properties; _i < properties_1.length; _i++) {
        var prop = properties_1[_i];
        value = value[prop];
        if (value === undefined) {
            return null;
        }
    }
    return value;
};
/**
 * Formats string to be on lower case and remove accents
 * @param oldValue string to format
 * @returns string with new format
 */
export var formatCompanyName = function (oldValue) {
    var newValue = oldValue
        .toLowerCase()
        .replace("á", "a")
        .replace("é", "e")
        .replace("í", "i")
        .replace("ó", "o")
        .replace("ú", "u")
        .replace(/\s/g, '');
    return newValue;
};
/**
 * Groups the objects of an array based on a property
 * @param inputArray array of objects
 * @param property property of the objects to be used to group
 * @returns array arrays of grouped objects
 */
export var groupObjectsByProperty = function (inputArray, property) {
    var grouped = inputArray.reduce(function (result, currentObject) {
        var key = String(currentObject[property]);
        // Create an array for the current key if it doesn't exist
        if (!result[key]) {
            result[key] = [];
        }
        // Push the current object into the corresponding key array
        result[key].push(currentObject);
        return result;
    }, {});
    // Convert the grouped object back to an array of arrays
    var groupedArray = Object.values(grouped);
    return groupedArray;
};
/**
 * Formats date to use **dd/mm/yy** format
 * @param date date object
 * @returns string with date formatted like **dd/mm/yy**
 */
export var formatDate = function (date) {
    // Extract day, month, and year from the Date object
    var day = String(date.getDate()).padStart(2, '0');
    var month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
    var year = String(date.getFullYear()).slice(-2); // Get the last two digits of the year
    // Combine the parts into the desired format
    return "".concat(day, "/").concat(month, "/").concat(year);
};
/**
 * Formats date to use **dd/mm/yy** format
 * @param date date object
 * @returns string with date formatted like **dd/mm/yy**
 */
export var formatDateMDYYYY = function (date) {
    // Extract day, month, and year from the Date object
    var day = String(date.getDate()).padStart(2, '0');
    var month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
    var year = String(date.getFullYear());
    // Combine the parts into the desired format
    return "".concat(month, "/").concat(day, "/").concat(year);
};
/**
 * Format date to use **dd/mm/yyyy hh:mm:ss** format
 * @param date date object
 * @returns string with date formatted like **dd/mm/yyyy hh:mm:ss**
 */
export var formatDateToDatetime = function (date) {
    var year = date.getFullYear();
    var month = String(date.getMonth() + 1).padStart(2, '0');
    var day = String(date.getDate()).padStart(2, '0');
    var hours = String(date.getHours()).padStart(2, '0');
    var minutes = String(date.getMinutes()).padStart(2, '0');
    var seconds = String(date.getSeconds()).padStart(2, '0');
    return "".concat(day, "/").concat(month, "/").concat(year, " ").concat(hours, ":").concat(minutes, ":").concat(seconds);
};
/**
 * Formats date to use **yyyy-mm-dd** format
 * @param date date object
 * @returns string with date formatted like **yyyy-mm-dd**
 */
export var formatDate_to_YMD = function (date) {
    // Extract day, month, and year from the Date object
    var day = String(date.getDate()).padStart(2, '0');
    var month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so we add 1
    var year = String(date.getFullYear());
    // Combine the parts into the desired format
    return "".concat(year, "-").concat(month, "-").concat(day);
};
export var getLastDayOfMonth = function (monthStr) {
    // Convert the input string to a number
    var month = parseInt(monthStr, 10);
    // Check if the input is a valid month number (between 1 and 12)
    if (month >= 1 && month <= 12) {
        // Create a new Date object for the next month
        var nextMonth = new Date();
        nextMonth.setMonth(month);
        // Set the date to the last day of the current month
        nextMonth.setDate(0);
        // Get the day component (last day of the month)
        var lastDay = nextMonth.getDate();
        // Convert the day to a string and return it
        return lastDay.toString();
    }
    else {
        // If the input is not a valid month number, return an error message
        return 'Invalid month';
    }
};
export var getPreviousMonth = function (currMonth) {
    if (currMonth === "1") { // For January return December
        return "12";
    }
    else {
        return "".concat(parseInt(currMonth) - 1);
    }
};
export var getNextMonth = function (currMonth) {
    if (currMonth === "12") { // For December return January
        return "1";
    }
    else {
        return "".concat(parseInt(currMonth) + 1);
    }
};
export var getPreviousMonthYear = function (currMonth, currYear) {
    if (currMonth === "1") { // For January return previous year
        return "".concat(parseInt(currYear) - 1);
    }
    else {
        return "".concat(currYear);
    }
};
export var getNextMonthYear = function (currMonth, currYear) {
    if (currMonth === "12") { // For December return next year
        return "".concat(parseInt(currYear) + 1);
    }
    else {
        return "".concat(currYear);
    }
};
export var getNextDownloadMonth = function (separatorDate) {
    if (separatorDate === void 0) { separatorDate = 6; }
    var currDate = new Date();
    if (currDate.getDate() > separatorDate) {
        var nextDate = new Date();
        nextDate.setMonth(currDate.getMonth() + 1);
        return nextDate.toLocaleString('default', { month: 'long' });
    }
    else {
        return currDate.toLocaleString('default', { month: 'long' });
    }
};
export var setCookie = function (cname, cvalue, domain) {
    document.cookie = cname + "=" + cvalue + ";" + (domain ? "domain=".concat(domain, ";") : "");
};
export var getCookie = function (cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(';');
    for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
};
export var clearAllCookies = function () {
    // Get all the cookies as a string
    var allCookies = document.cookie;
    // Split the string into individual cookies
    var cookieArray = allCookies.split(";");
    // Iterate through the cookies and expire each one
    for (var i = 0; i < cookieArray.length; i++) {
        var cookie = cookieArray[i];
        var eqPos = cookie.indexOf("=");
        var cookieName = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
        document.cookie = "".concat(cookieName, "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;");
    }
};
/**
 * Converts an array of arrays of strings or numbers into a file (xlsx by default) and saves it into the user's system
 * @param data array of arrays of strings or numbers to be saved
 * @param fileName name of the file to be saved
 * @param extension extension of the file to be saved (default is xlsx)
 */
export var saveDataToFile = function (data, fileName, extension, sheet) {
    if (extension === void 0) { extension = "xlsx"; }
    if (sheet === void 0) { sheet = "Sheet 1"; }
    var workbook = utils.book_new();
    var worksheet = utils.aoa_to_sheet(data);
    utils.book_append_sheet(workbook, worksheet, sheet);
    writeFile(workbook, "".concat(fileName, ".").concat(extension), { compression: true });
};
/**
 * Download blob data into users computer
 * @param blob blob data to be saved
 * @param fileName name of the file to be saved
 */
export var downloadBlob = function (blob, fileName) { return __awaiter(void 0, void 0, void 0, function () {
    var arr, link;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, blob.arrayBuffer()
                // const  blobObj = new Blob([atob(blob.)], { type: "application/pdf" });
            ];
            case 1:
                arr = _a.sent();
                link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                return [2 /*return*/];
        }
    });
}); };
/**
 * Checks if string is parseable to float
 * @param inputString string to test
 * @returns boolean indicating if string can be parsed to float
 */
export var isParseableToFloat = function (inputString) {
    // Use the parseFloat function to attempt parsing the string as a float
    var floatValue = parseFloat(inputString);
    // Check if the result is a number and not NaN
    return !isNaN(floatValue) && typeof floatValue === 'number';
};
export var createDateFromDMYFormat = function (dateString) {
    var _a = dateString.split('/'), day = _a[0], month = _a[1], year = _a[2];
    var isoDateString = "".concat(year, "-").concat(month, "-").concat(day, "T00:00:00.000");
    return new Date(isoDateString);
};
/**
 * Stores value in local storage
 * @param key string to use as a key to store the value
 * @param value value to be stringified to be stored in local storage
 */
export var setLocalStorageItem = function (key, value) {
    try {
        localStorage.setItem(key, JSON.stringify(value));
    }
    catch (error) {
        console.error('Error storing data in local storage:', error);
    }
};
/**
 * Retrieves value from local storage
 * @param key string that represents the value to be retrieved
 */
export var getLocalStorageItem = function (key) {
    try {
        var item = localStorage.getItem(key);
        if (item) {
            return JSON.parse(item);
        }
    }
    catch (error) {
        console.error('Error retrieving data from local storage:', error);
    }
    return null;
};
