var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import Bills from "./Bills";
import DateTransitioner from "../../components/DateTransitioner/DateTransitioner";
import { getClient, getClientsPurchases, getClientsSales } from "../../services/clientsService";
import { useSelector } from "react-redux";
import { loggedUser } from "../../UserContext/UserReducer";
import { formatDate, isParseableToFloat } from "../../shared/utils";
import { genColumnsMapping, popupConfig, toastConfig } from "../../shared/constants";
import uploadIcon from "../../assets/icons/whiteUploadIcon.png";
import Swal from "sweetalert2";
import Loader from "../../components/Loader/Loader";
import { toast } from "react-toastify";
import { getCorrelative, getExcelSignedURL, getLatestCorrelative, uploadExcelDocument, uploadFEL } from "../../services/documentsService";
import NewDocPopup from "../../components/NewDocPopup/NewDocPopup";
import NewDocIcon from "../../assets/icons/whiteCreateDocIcon.png";
// Render data for sales
var Sales = function () {
    // To access NIT: params.nit
    var params = useParams();
    var userData = useSelector(loggedUser);
    var fileInputRef = useRef(null);
    var _a = useState({
        nit: "",
        salesData: [],
        generatorData: [],
        exclusiveGeneratorData: [],
        excelGenData: [],
    }), data = _a[0], setData = _a[1];
    var _b = useState({
        nit: "",
        salesData: [],
        generatorData: [],
        exclusiveGeneratorData: [],
        excelGenData: [],
    }), discardedData = _b[0], setDiscardedData = _b[1];
    var _c = useState(params.month), currentDisplayMonth = _c[0], setCurrentDisplayMonth = _c[1];
    var _d = useState(params.year), currentDisplayYear = _d[0], setCurrentDisplayYear = _d[1];
    var _e = useState(false), loadingFile = _e[0], setLoadingFile = _e[1];
    var _f = useState("1"), folio = _f[0], setFolio = _f[1];
    var _g = useState({
        nit: params.nit,
        company_id: "",
        name: "",
        birthdate: "",
        credito_fiscal: 0,
        download_pdf: 0,
        has_movement: 0,
        is_automated: 0,
        nit_delegado: "",
        password: "",
        regime_id: 0,
        regime_task_name: "",
        invalid_password: 0,
    }), nitInfo = _g[0], setNitInfo = _g[1];
    var _h = useState([]), expandedRowsState = _h[0], setExpandedRowsState = _h[1];
    var _j = useState([]), discExpandedRowsState = _j[0], setDiscExpandedRowsState = _j[1];
    var _k = useState(true), childrenColapsed = _k[0], setChildrenColapsed = _k[1];
    var _l = useState(false), createDocIsOpen = _l[0], setCreateDocIsOpen = _l[1];
    var _m = useState(false), folioIsDownloaded = _m[0], setFolioIsDownloaded = _m[1];
    var _o = useState([]), littleStateData = _o[0], setLittleStateData = _o[1];
    // Columns to display
    var cols = [
        {
            name: "expandIndicator",
            display: childrenColapsed ?
                React.createElement("div", { style: { fontSize: '2vw', fontWeight: 500 } }, "+")
                :
                    React.createElement("div", { style: { fontSize: '2vw', fontWeight: 500 } }, "-"),
            widthPercent: 3,
            onClick: function () {
                var _a, _b;
                var length = ((_a = data.salesData) === null || _a === void 0 ? void 0 : _a.length) || 0;
                var discLength = ((_b = discardedData.salesData) === null || _b === void 0 ? void 0 : _b.length) || 0;
                setExpandedRowsState(Array.from({ length: length }, function () { return childrenColapsed; }));
                setDiscExpandedRowsState(Array.from({ length: length }, function () { return childrenColapsed; }));
                setChildrenColapsed(!childrenColapsed);
            }
        },
        {
            name: "fileActions",
            display: "",
            widthPercent: 5,
            avoidClickExpansion: true,
        },
        {
            name: "linked_isr",
            display: "Retención",
            rawName: "linked_isr",
            widthPercent: 5,
        },
        {
            name: "docType",
            display: "Tipo",
            rawName: "document_name",
            widthPercent: 3,
            hideInChildren: true,
        },
        {
            name: "serialNumber",
            display: "Serie",
            rawName: "serie",
            hideInChildren: true,
        },
        {
            name: "docNumber",
            display: "Número",
            rawName: "number",
            hideInChildren: true,
        },
        {
            name: "docNit",
            display: "Nit Receptor",
            rawName: "doc_nit",
            hideInChildren: true,
        },
        {
            name: "entityName",
            display: "Nombre",
            widthPercent: 13,
            rawName: "name",
            childRawName: "item_desc",
            shouldUpperCase: true,
            fontSize: 0.6
        },
        {
            name: "date",
            display: "Fecha",
            widthPercent: 7,
            rawName: "date",
            hideInChildren: true,
            isDate: true,
        },
        {
            name: "goods",
            display: "Bien",
            rawName: "monto_gravable",
            shouldFormatCurrency: true,
            isIndexable: true,
            taxId: 1,
        },
        {
            name: "services",
            display: "Servicio",
            rawName: "monto_gravable",
            shouldFormatCurrency: true,
            isIndexable: true,
            taxId: 2,
        },
        {
            name: "export",
            display: "Exportación",
            rawName: "monto_gravable",
            shouldFormatCurrency: true,
            isIndexable: true,
            taxId: 5,
        },
        {
            name: "exent",
            display: "Exento",
            rawName: "exento",
            shouldFormatCurrency: true,
        },
        {
            name: "taxes",
            rawName: "tax",
            shouldFormatCurrency: true,
            display: "IVA",
        },
        {
            name: "total",
            display: "Total",
            rawName: "total",
            shouldFormatCurrency: true,
        },
        {
            name: "actions",
            display: "Acciones",
            widthPercent: 9,
            avoidClickExpansion: true,
        },
    ];
    var initData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, currentCorrelative, clientData, clientInfo, littleContributorData, lastCorrelative, rawData, rawDiscardedData, regularGenData, regularDiscardedGenData;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoadingFile(true);
                    return [4 /*yield*/, Promise.all([
                            getCorrelative(userData.idToken, params.nit, params.month, params.year, 0),
                            getClientsSales(userData.idToken, params.nit, params.month, params.year),
                            getClient(userData.idToken, params.nit),
                        ])];
                case 1:
                    _a = _b.sent(), currentCorrelative = _a[0], clientData = _a[1], clientInfo = _a[2];
                    if (!currentCorrelative.error) return [3 /*break*/, 2];
                    toast(clientInfo.message ? clientInfo.message : "Error de servidor", __assign(__assign({}, toastConfig), { type: 'error' }));
                    return [3 /*break*/, 5];
                case 2:
                    if (!Array.isArray(currentCorrelative)) return [3 /*break*/, 5];
                    if (!(currentCorrelative.length > 0)) return [3 /*break*/, 3];
                    setFolio(currentCorrelative[0].folio);
                    setFolioIsDownloaded(true);
                    return [3 /*break*/, 5];
                case 3: return [4 /*yield*/, getLatestCorrelative(userData.idToken, params.nit, 0)];
                case 4:
                    lastCorrelative = _b.sent();
                    if (lastCorrelative.error) {
                        toast(clientInfo.message ? clientInfo.message : "Error de servidor", __assign(__assign({}, toastConfig), { type: 'error' }));
                    }
                    else {
                        if (Array.isArray(lastCorrelative)) {
                            if (lastCorrelative.length > 0) { // Last registered folio
                                setFolio(lastCorrelative[0].last_folio + 1);
                                setFolioIsDownloaded(false);
                            }
                        }
                    }
                    _b.label = 5;
                case 5:
                    if (!(clientInfo === null || clientInfo === void 0 ? void 0 : clientInfo.error)) return [3 /*break*/, 6];
                    toast(clientInfo.message || "Ocurrió un error al cargar la información", __assign(__assign({}, toastConfig), { type: 'error' }));
                    return [3 /*break*/, 8];
                case 6:
                    if (!clientInfo) return [3 /*break*/, 8];
                    setNitInfo(clientInfo);
                    if (!(clientInfo.regime_id === 3)) return [3 /*break*/, 8];
                    return [4 /*yield*/, getClientsPurchases(userData.idToken, params.nit, params.month, params.year)];
                case 7:
                    littleContributorData = _b.sent();
                    if (littleContributorData) {
                        setLittleStateData(littleContributorData);
                    }
                    _b.label = 8;
                case 8:
                    if (clientData && Array.isArray(clientData)) {
                        rawData = clientData.filter(function (elem) { return elem.is_discarded === 0; });
                        rawDiscardedData = clientData.filter(function (elem) { return elem.is_discarded === 1; });
                        regularGenData = littleContributorData ? getlittleGeneratorData(__spreadArray(__spreadArray([], littleContributorData, true), rawData, true)) : getGeneratorData(rawData);
                        regularDiscardedGenData = littleContributorData ? getlittleGeneratorData(__spreadArray(__spreadArray([], littleContributorData, true), rawData, true)) : getGeneratorData(rawDiscardedData);
                        setData({
                            nit: params.nit,
                            salesData: rawData,
                            generatorData: regularGenData,
                            exclusiveGeneratorData: littleContributorData ? getGeneratorData(rawData) : regularGenData,
                            excelGenData: getDiscardedGeneratorData(regularGenData),
                        });
                        setDiscardedData({
                            nit: params.nit,
                            salesData: rawDiscardedData,
                            generatorData: regularDiscardedGenData,
                            exclusiveGeneratorData: littleContributorData ? getGeneratorData(rawDiscardedData) : regularDiscardedGenData,
                            excelGenData: getDiscardedGeneratorData(regularDiscardedGenData),
                        });
                    }
                    else {
                        if (clientData === null || clientData === void 0 ? void 0 : clientData.message) {
                            toast(clientData.message, __assign(__assign({}, toastConfig), { type: 'error' }));
                        }
                        setData({
                            nit: "",
                            salesData: [],
                            generatorData: [],
                            exclusiveGeneratorData: [],
                            excelGenData: [],
                        });
                    }
                    setLoadingFile(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var getGeneratorData = function (rawData) {
        var genData = [];
        rawData.forEach(function (doc, currDocInd) {
            var genDataRow = {
                docId: doc.document_id,
                emision: formatDate(new Date(doc.date.replace(/-/g, '\/').replace(/T.+/, ''))),
                type: doc.document_name,
                serial: doc.serie,
                number: doc.number,
                nit: doc.doc_nit,
                name: doc.name.toUpperCase(),
                total: doc.total,
                linked_isr: 'Icon',
            };
            doc.items.forEach(function (item) {
                var prevValue = genDataRow;
                if (item.tax_type_id === 1) {
                    if (prevValue.goods) {
                        prevValue.goods = typeof prevValue.goods !== 'string' ? prevValue.goods + item.monto_gravable : item.monto_gravable;
                    }
                    else {
                        prevValue.goods = item.monto_gravable;
                    }
                }
                else if (item.tax_type_id === 2) {
                    if (prevValue.services) {
                        prevValue.services = typeof prevValue.services !== 'string' ? prevValue.services + item.monto_gravable : item.monto_gravable;
                    }
                    else {
                        prevValue.services = item.monto_gravable;
                    }
                }
                else if (item.tax_type_id === 5) {
                    if (prevValue.services) {
                        prevValue.export = typeof prevValue.export !== 'string' ? prevValue.export + item.monto_gravable : item.monto_gravable;
                    }
                    else {
                        prevValue.export = item.monto_gravable;
                    }
                }
                if (prevValue.taxes) {
                    prevValue.taxes = typeof prevValue.taxes !== 'string' ? prevValue.taxes + item.tax : item.tax;
                }
                else {
                    prevValue.taxes = item.tax;
                }
                if (prevValue.exent) {
                    prevValue.exent = typeof prevValue.exent !== 'string' ? prevValue.exent + item.exento : item.exento;
                }
                else {
                    prevValue.exent = item.exento;
                }
            });
            if (doc.items.length > 0 || currDocInd > 0) { // Ignore docs that don't have children items and are in first place
                genData.push(genDataRow);
            }
        });
        return genData;
    };
    var getlittleGeneratorData = function (rawData) {
        var genData = [];
        rawData.forEach(function (doc, currDocInd) {
            var genDataRow = {
                docId: doc.document_id,
                emision: formatDate(new Date(doc.date.replace(/-/g, '\/').replace(/T.+/, ''))),
                type: doc.document_name,
                serial: doc.serie,
                number: doc.number,
                nit: doc.doc_nit,
                name: doc.name.toUpperCase(),
                total: doc.total,
                isPurchase: doc.is_purchase,
                linked_isr: 'Icon',
            };
            doc.items.forEach(function (item) {
                var prevValue = genDataRow;
                if (item.tax_type_id === 1) {
                    if (prevValue.goods) {
                        prevValue.goods = typeof prevValue.goods !== 'string' ? prevValue.goods + item.monto_gravable : item.monto_gravable;
                    }
                    else {
                        prevValue.goods = item.monto_gravable;
                    }
                }
                else if (item.tax_type_id === 2) {
                    if (prevValue.services) {
                        prevValue.services = typeof prevValue.services !== 'string' ? prevValue.services + item.monto_gravable : item.monto_gravable;
                    }
                    else {
                        prevValue.services = item.monto_gravable;
                    }
                }
                else if (item.tax_type_id === 5) {
                    if (prevValue.services) {
                        prevValue.export = typeof prevValue.export !== 'string' ? prevValue.export + item.monto_gravable : item.monto_gravable;
                    }
                    else {
                        prevValue.export = item.monto_gravable;
                    }
                }
                else if (item.tax_type_id === 4) {
                    if (prevValue.gas) {
                        prevValue.gas = typeof prevValue.gas !== 'string' ? prevValue.gas + item.monto_gravable : item.monto_gravable;
                    }
                    else {
                        prevValue.gas = item.monto_gravable;
                    }
                }
                else if (item.tax_type_id === 3) {
                    if (prevValue.little) {
                        prevValue.little = typeof prevValue.little !== 'string' ? prevValue.little + item.monto_gravable : item.monto_gravable;
                    }
                    else {
                        prevValue.little = item.monto_gravable;
                    }
                }
                else if (item.tax_type_id === 6) {
                    if (prevValue.import) {
                        prevValue.import = typeof prevValue.import !== 'string' ? prevValue.import + item.monto_gravable : item.monto_gravable;
                    }
                    else {
                        prevValue.import = item.monto_gravable;
                    }
                }
                if (prevValue.taxes) {
                    prevValue.taxes = typeof prevValue.taxes !== 'string' ? prevValue.taxes + item.tax : item.tax;
                }
                else {
                    prevValue.taxes = item.tax;
                }
                if (prevValue.exent) {
                    prevValue.exent = typeof prevValue.exent !== 'string' ? prevValue.exent + item.exento : item.exento;
                }
                else {
                    prevValue.exent = item.exento;
                }
            });
            if (doc.items.length > 0 || currDocInd > 0) { // Ignore docs that don't have children items and are in first place
                genData.push(genDataRow);
            }
        });
        return genData;
    };
    var getDiscardedGeneratorData = function (rawData) {
        var resultData = [];
        var dataColumns = [];
        var totalColumns = [];
        var emptyRow = [];
        var totalColNames = [
            'export',
            'goods',
            'services',
            'exent',
            'taxes',
            'total',
        ];
        var excelColumns = [
            'docId',
            'emision',
            'type',
            'serial',
            'number',
            'nit',
            'name',
            'total',
            'goods',
            'services',
            'export',
            'exent',
            'taxes'
        ];
        // For each row
        rawData.forEach(function (row, rowInd) {
            var resultRow = [];
            // For each col - value
            excelColumns.forEach(function (colVal, colValInd) {
                if (colVal === 'docId') {
                    resultRow.push(row[colVal].split('_')[0]);
                }
                else {
                    resultRow.push(row[colVal]);
                }
                // First row that contains the titles
                if (rowInd === 0) {
                    var mapping = __assign({}, genColumnsMapping);
                    if (mapping[colVal]) {
                        dataColumns.push(mapping[colVal]);
                    }
                    else {
                        dataColumns.push(colVal);
                    }
                    emptyRow.push('');
                }
                // Total Calculation - This goes on the last row
                if (totalColNames.includes(colVal)) {
                    if (rowInd === 0) { // If first row, it should add all the columns
                        totalColumns.push(isParseableToFloat(row[colVal]) ? parseFloat(row[colVal]) : 0);
                    }
                    else if (totalColumns.length > colValInd) { // Other rows should look for the specific column
                        totalColumns[colValInd] += isParseableToFloat(row[colVal]) ? parseFloat(row[colVal]) : 0;
                        if (rowInd === rawData.length - 1) { // Last row should round decimals
                            totalColumns[colValInd] = totalColumns[colValInd].toFixed(2);
                        }
                    }
                }
                else {
                    if (rowInd === 0) { // Only first row adds the columns to the array
                        if (colValInd === 0) {
                            totalColumns.push('TOTAL');
                        }
                        else {
                            totalColumns.push('---');
                        }
                    }
                }
            });
            if (rowInd === 0) {
                resultData.push(dataColumns);
            }
            resultData.push(resultRow);
        });
        // Push totals row
        resultData.push(emptyRow);
        resultData.push(totalColumns);
        return resultData;
    };
    var onFileChange = function (ev) {
        var file = ev.target.files ? ev.target.files[0] : null;
        if (file) {
            Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Procesar archivo?', html: "\n          <div><b>Nombre del archivo:</b> ".concat(file.name, "</div>\n        "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, procesar', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                var fileExtension, signedResp, fileUploadResp, felUploadResp;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.isConfirmed) return [3 /*break*/, 5];
                            // Process File
                            setLoadingFile(true);
                            fileExtension = file.name.split('.').pop();
                            return [4 /*yield*/, getExcelSignedURL(userData.idToken, params.nit, params.month, params.year, 'sales', fileExtension)];
                        case 1:
                            signedResp = _a.sent();
                            if (!signedResp) return [3 /*break*/, 5];
                            if (!signedResp.message) return [3 /*break*/, 2];
                            toast(signedResp.message, __assign(__assign({}, toastConfig), { type: 'error' }));
                            return [3 /*break*/, 5];
                        case 2: return [4 /*yield*/, uploadExcelDocument(signedResp.signedURL, file)];
                        case 3:
                            fileUploadResp = _a.sent();
                            return [4 /*yield*/, uploadFEL(userData.idToken, nitInfo.company_id, params.nit, params.month, params.year, 0, fileExtension)];
                        case 4:
                            felUploadResp = _a.sent();
                            if (felUploadResp) {
                                if (felUploadResp.error) {
                                    toast(felUploadResp.message || "Error de servidor.", __assign(__assign({}, toastConfig), { type: 'error' }));
                                }
                                else {
                                    initData(); // Refresh data
                                    toast('FEL importado exitosamente', __assign(__assign({}, toastConfig), { type: 'success' }));
                                }
                            }
                            setLoadingFile(false);
                            _a.label = 5;
                        case 5: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    var generatePDFData = function () {
        setData(function (prevState) { return (__assign(__assign({}, prevState), { generatorData: getGeneratorData(prevState.salesData) })); });
    };
    useEffect(function () {
        var _a;
        if (params.month !== currentDisplayMonth
            || params.year !== currentDisplayYear
            || ((_a = data === null || data === void 0 ? void 0 : data.salesData) === null || _a === void 0 ? void 0 : _a.length) === 0) {
            initData();
            setCurrentDisplayMonth(params.month);
            setCurrentDisplayYear(params.year);
        }
    }, [params]);
    return (React.createElement("div", { className: "salesContainer" },
        React.createElement(NewDocPopup, { openState: createDocIsOpen, setIsOpen: setCreateDocIsOpen, isPurchase: false, refreshData: initData, params: params, regimeId: nitInfo.regime_id }),
        React.createElement("div", { className: "billsNitTitle" }, "".concat(params.nit, " - ").concat(nitInfo.name)),
        React.createElement(DateTransitioner, { redirectionPath: "/sales/".concat(params.nit), defaultMonth: currentDisplayMonth, defaultYear: currentDisplayYear }),
        loadingFile
            ? React.createElement("div", { className: "billsLoadingContainer" },
                React.createElement(Loader, null))
            : React.createElement(React.Fragment, null,
                React.createElement("div", { className: "purchasesActions" },
                    React.createElement("div", { className: "folioContainer" },
                        React.createElement("div", null, "Folio:"),
                        React.createElement("input", { className: "billsTransInput", type: "text", placeholder: "Folio", value: folio, onChange: function (ev) {
                                var inputValue = ev.target.value;
                                if (/^\d+$/.test(inputValue) || inputValue === "") { // Only accept numbers
                                    setFolio(inputValue);
                                }
                            }, disabled: folioIsDownloaded })),
                    React.createElement("div", { className: "billsImportOptions" },
                        React.createElement("button", { className: "billsFilledButton", onClick: function () {
                                if (fileInputRef) {
                                    fileInputRef.current.click();
                                }
                            }, title: "Importar FEL" },
                            React.createElement("input", { type: "file", accept: ".xls, .xlsx", onChange: function (ev) { return onFileChange(ev); }, ref: fileInputRef, style: { display: 'none' } }),
                            React.createElement("div", null, "Importar FEL"),
                            React.createElement("img", { src: uploadIcon, alt: "Subir archivo", style: { marginLeft: '0.5vw' } })),
                        React.createElement("button", { className: "billsFilledButton", onClick: function (ev) {
                                ev.stopPropagation();
                                setCreateDocIsOpen(true);
                            } },
                            React.createElement("div", null, "Crear Documento"),
                            React.createElement("img", { src: NewDocIcon, alt: "", style: { marginLeft: "0.5vw" } })))),
                React.createElement("div", { className: "salesTitle" }, "Ventas del Mes"),
                React.createElement(Bills, { data: data.salesData, exclusiveGeneratorData: data.exclusiveGeneratorData, setData: function (newSalesState) {
                        var regularGenData = littleStateData.length > 0 ? getlittleGeneratorData(__spreadArray(__spreadArray([], littleStateData, true), newSalesState, true)) : getGeneratorData(newSalesState);
                        setData(__assign(__assign({}, data), { salesData: newSalesState, generatorData: regularGenData, excelGenData: getDiscardedGeneratorData(regularGenData) }));
                    }, addOtherData: function (newSalesRecord) {
                        var rawNewRecords = __spreadArray(__spreadArray([], discardedData.salesData, true), newSalesRecord, true);
                        var regularOtherGenData = littleStateData.length > 0 ? getlittleGeneratorData(__spreadArray(__spreadArray([], littleStateData, true), rawNewRecords, true)) : getGeneratorData(rawNewRecords);
                        setDiscardedData(__assign(__assign({}, discardedData), { salesData: rawNewRecords, generatorData: regularOtherGenData, excelGenData: getDiscardedGeneratorData(regularOtherGenData) }));
                    }, cols: cols, userData: userData, nitInfo: nitInfo, expandedRows: expandedRowsState, setExpandedRows: setExpandedRowsState, params: __assign(__assign({}, params), { nitName: nitInfo.name, company_id: nitInfo.company_id }), refreshData: function () { return initData(); }, taxTypeIds: [1, 2, 5], salesGeneratorData: data.generatorData, excelGeneratorData: data.excelGenData, excelDownloadText: "Descargar Listado de Ventas", generatorDownloadText: "Descargar Libro de Ventas", generatorDownloadedTitle: "LibroDeVentas", generatorTitle: "LIBRO DE VENTAS", genInvoice: parseInt(folio), bookWasAlreadyDownloaded: folioIsDownloaded, setBookWasDownloaded: setFolioIsDownloaded, setChildrenColapsed: setChildrenColapsed }),
                React.createElement("div", { className: "salesTitle" }, "Ventas Descartadas"),
                React.createElement(Bills, { data: discardedData.salesData, exclusiveGeneratorData: discardedData.exclusiveGeneratorData, setData: function (newSalesState) {
                        var regularGenData = littleStateData.length > 0 ? getlittleGeneratorData(__spreadArray(__spreadArray([], littleStateData, true), newSalesState, true)) : getGeneratorData(newSalesState);
                        setDiscardedData(__assign(__assign({}, discardedData), { salesData: newSalesState, generatorData: regularGenData, excelGenData: getDiscardedGeneratorData(regularGenData) }));
                    }, addOtherData: function (newSalesRecord) {
                        var rawNewRecords = __spreadArray(__spreadArray([], data.salesData, true), newSalesRecord, true);
                        var regularOtherGenData = littleStateData.length > 0 ? getlittleGeneratorData(__spreadArray(__spreadArray([], littleStateData, true), rawNewRecords, true)) : getGeneratorData(rawNewRecords);
                        setData(__assign(__assign({}, data), { salesData: rawNewRecords, generatorData: regularOtherGenData, excelGenData: getDiscardedGeneratorData(regularOtherGenData) }));
                    }, cols: cols, userData: userData, nitInfo: nitInfo, params: __assign(__assign({}, params), { nitName: nitInfo.name, company_id: nitInfo.company_id }), expandedRows: discExpandedRowsState, setExpandedRows: setDiscExpandedRowsState, refreshData: function () { return initData(); }, taxTypeIds: [1, 2, 5], discardedGeneratorData: discardedData.excelGenData, discardedRegularGenData: discardedData.generatorData, generatorDownloadText: "Descargar Listado de Ventas Descartadas", generatorDownloadedTitle: "ListadoDeVentasDescartadas", generatorTitle: "LISTADO DE VENTAS DESCARTADAS", genInvoice: parseInt(folio), setChildrenColapsed: setChildrenColapsed }))));
};
export default Sales;
