import React, { useEffect, useState } from "react";
import pendingIcon from '../../assets/icons/pendingIcon.png';
import doneIcon from '../../assets/icons/doneIcon.png';
import errorIcon from '../../assets/icons/errorIcon.png';
import './ClientStateBar.scss';
var ClientStateBar = function (props) {
    var states = props.states, width = props.width, identifier = props.identifier, iconWidth = props.iconWidth;
    var _a = useState([]), hoveredSections = _a[0], setHoveredSections = _a[1];
    var changeHoveredSection = function (secInd, newState) {
        var length = states.length || 0;
        var stateCopy = Array.from({ length: length }, function () { return false; });
        if (secInd < hoveredSections.length && newState) {
            stateCopy[secInd] = newState;
        }
        setHoveredSections(stateCopy);
    };
    useEffect(function () {
        var length = states.length || 0;
        setHoveredSections(Array.from({ length: length }, function () { return false; }));
    }, []);
    return (React.createElement("div", { className: "clientStateBarContainer", style: { width: "".concat(width, "vw") || undefined } }, states.map(function (stateElem, stateElemInd) {
        return (React.createElement(React.Fragment, { key: "clientstate-".concat(identifier, "-").concat(stateElemInd) },
            stateElemInd > 0 &&
                React.createElement("div", { className: "clientStateBarProgressLine" },
                    React.createElement("div", { className: "clientStateBarProgressLineContent", style: {
                            backgroundColor: stateElem.completed ? "#31E263" : (stateElem.error ? "#FE4D31" : "#7F7F7F"),
                            animationDelay: "".concat(stateElemInd > 0 ? stateElemInd - 0.5 : 0, "s"), // Should subtract half of the animation duration
                        } })),
            React.createElement("div", { className: "clientStateBarStateContainer" },
                React.createElement("img", { alt: "img-".concat(stateElemInd), src: stateElem.completed ? doneIcon : (stateElem.error ? errorIcon : pendingIcon), 
                    // title={stateElem.completed ? "Completo" : (stateElem.error ? "Error" : "En curso")}
                    onMouseEnter: function () { return changeHoveredSection(stateElemInd, true); }, onMouseLeave: function () { return changeHoveredSection(stateElemInd); }, style: {
                        width: iconWidth || undefined,
                        animationDelay: "".concat(stateElemInd, "s")
                    } }),
                React.createElement("div", null, stateElem.name),
                hoveredSections[stateElemInd] && stateElem.info &&
                    React.createElement("div", { className: "clientStateBarInfoPopup", style: {
                            backgroundColor: stateElem.completed ? "#DFF2DA" : (stateElem.error ? "#E3D8D6" : "#F3F3F3"),
                        } }, stateElem.info))));
    })));
};
export default ClientStateBar;
