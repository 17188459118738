var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var api_url = process.env.API_URL;
export var logIn = function (user, password) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    username: user,
                    password: password
                };
                reqOpt = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    withCredentials: true,
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/login"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_1 = _a.sent();
                console.log(e_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var refreshToken = function (refreshToken) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    refresh: refreshToken,
                };
                reqOpt = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/refresh"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_2 = _a.sent();
                console.log(e_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var challengeLogin = function (user, newPassword, challengeName, session) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    username: user,
                    password: newPassword,
                    challenge: challengeName,
                    session: session,
                };
                reqOpt = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/login/challenge"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_3 = _a.sent();
                console.log(e_3);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var forgotPassword = function (user) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    username: user
                };
                reqOpt = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/forgot"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_4 = _a.sent();
                console.log(e_4);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var confirmForgotPassword = function (user, password, confirmationCode) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    username: user,
                    password: password,
                    code: confirmationCode,
                };
                reqOpt = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/forgot/confirm"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_5 = _a.sent();
                console.log(e_5);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var listUsers = function (filter, limit, attributes, paginationToken) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    filter: filter,
                    limit: limit,
                    attributes: attributes,
                    paginationToken: paginationToken,
                };
                reqOpt = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/users"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_6 = _a.sent();
                console.log(e_6);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var createUser = function (username, idToken, attributes, clientmetadata) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    username: username,
                    attributes: attributes,
                    clientmetadata: clientmetadata,
                    delivery: ["EMAIL"],
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken),
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/user/create"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_7 = _a.sent();
                console.log(e_7);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var deleteUser = function (username, idToken) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    username: username,
                };
                reqOpt = {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken),
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/user/delete"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_8 = _a.sent();
                console.log(e_8);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var enableUser = function (username, idToken) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    username: username,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken),
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/user/enable"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_9 = _a.sent();
                console.log(e_9);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var disableUser = function (username, idToken) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    username: username,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken),
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/user/disable"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_10 = _a.sent();
                console.log(e_10);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var addUserToGroup = function (username, idToken, groupname) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    username: username,
                    groupname: groupname,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken),
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/user/addtogroup"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_11 = _a.sent();
                console.log(e_11);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var removeUserFromGroup = function (username, idToken, groupname) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    username: username,
                    groupname: groupname,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken),
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/user/removefromgroup"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_12 = _a.sent();
                console.log(e_12);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var updateUser = function (username, idToken, attributes) { return __awaiter(void 0, void 0, void 0, function () {
    var cleanAttrs_1, reqBody, reqOpt, response, e_13;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                cleanAttrs_1 = attributes;
                attributes.forEach(function (attr, ind) {
                    if (attr.Name === "email") {
                        cleanAttrs_1.push({ Name: "email_verified", Value: "true" });
                    }
                });
                reqBody = {
                    username: username,
                    attributes: cleanAttrs_1,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken),
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/user/edit"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_13 = _a.sent();
                console.log(e_13);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
