import React from "react";
import { loggedUser } from "../../UserContext/UserReducer";
import { useSelector } from "react-redux/es/hooks/useSelector";
import "./Home.scss";
import Clients from "../Clients/Clients";
var Home = function () {
    var userData = useSelector(loggedUser);
    return (React.createElement("div", { className: 'homeContainer' },
        React.createElement(Clients, null)));
};
export default Home;
