import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo-login.svg";
import "./NotFound.scss";
var NotFound = function () {
    return (React.createElement("div", { className: "notFoundContainer" },
        React.createElement("img", { className: "notFoundLogo", src: logo }),
        React.createElement("div", { className: "notFoundTitle" }, "Ups... La p\u00E1gina que tratas de acceder no existe."),
        React.createElement("div", { className: "notFoundSubTitle" }, "Ponte en contacto con un administrador si crees que se trata de un error o"),
        React.createElement(Link, { className: "notFoundLink", to: "/" }, "vuelve al men\u00FA principal.")));
};
export default NotFound;
