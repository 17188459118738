var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastConfig } from "../../../shared/constants";
import "./CreateCompany.scss";
import { createCompany, editCompany } from "../../../services/companyService";
import { useSelector } from "react-redux";
import { loggedUser } from "../../../UserContext/UserReducer";
import { getCompanies } from "../../../services/clientsService";
var CreateCompany = function () {
    var params = useParams();
    var navigate = useNavigate();
    var userData = useSelector(loggedUser);
    var _a = useState(""), nameInput = _a[0], setNameInput = _a[1];
    var _b = useState(""), nitInput = _b[0], setNitInput = _b[1];
    var createNewCompany = function () { return __awaiter(void 0, void 0, void 0, function () {
        var createResp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateFields()) return [3 /*break*/, 2];
                    return [4 /*yield*/, createCompany(userData.idToken, nameInput, nitInput)];
                case 1:
                    createResp = _a.sent();
                    if (createResp) {
                        toast("Empresa creada.", __assign(__assign({}, toastConfig), { type: "success" }));
                        navigate("/admin/companies");
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var validateFields = function () {
        var fieldsAreValid = true;
        if (nameInput === "") {
            fieldsAreValid = false;
            toast("El nombre no puede estar vacío.", __assign(__assign({}, toastConfig), { type: "warning" }));
        }
        if (nitInput === "") {
            fieldsAreValid = false;
            toast("El NIT no puede estar vacío.", __assign(__assign({}, toastConfig), { type: "warning" }));
        }
        return fieldsAreValid;
    };
    var editExistingCompany = function () { return __awaiter(void 0, void 0, void 0, function () {
        var editResp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateFields()) return [3 /*break*/, 2];
                    return [4 /*yield*/, editCompany(userData.idToken, params.id, nameInput)];
                case 1:
                    editResp = _a.sent();
                    if (editResp) {
                        toast("Empresa actualizada.", __assign(__assign({}, toastConfig), { type: "success" }));
                        navigate("/admin/companies");
                    }
                    return [3 /*break*/, 3];
                case 2:
                    toast("El nombre no puede estar vacío.", __assign(__assign({}, toastConfig), { type: "warning" }));
                    _a.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    var initEditData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var companiesResp, toEdit;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCompanies(userData.idToken)];
                case 1:
                    companiesResp = _a.sent();
                    if (companiesResp && Array.isArray(companiesResp)) {
                        toEdit = companiesResp.find(function (comp) { return comp.company_id === params.id; });
                        if (toEdit) {
                            setNameInput(toEdit.company_name);
                            setNitInput(toEdit.company_id);
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (params.id) {
            initEditData();
        }
    }, []);
    return (React.createElement("div", { className: "createCompanyContainer" },
        React.createElement("div", { className: "createCompanyFormContainer" },
            React.createElement("div", { className: "createCompanyTitle" }, params.id ? 'Editar Empresa' : 'Crear Empresa / Contador'),
            React.createElement("div", { className: "createCompanyFieldContainer" },
                React.createElement("div", { className: "createCompanyFieldTitle" },
                    "Nombre ",
                    React.createElement("b", { style: { color: "#F68218" } }, "*")),
                React.createElement("input", { className: "createCompanyFieldInput", placeholder: "Empresa Guatemala S.A.", value: nameInput, onChange: function (ev) { return setNameInput(ev.target.value); } })),
            React.createElement("div", { className: "createCompanyFieldContainer" },
                React.createElement("div", { className: "createCompanyFieldTitle" },
                    "NIT ",
                    React.createElement("b", { style: { color: "#F68218" } }, "*")),
                React.createElement("input", { className: "createCompanyFieldInput", placeholder: "123456789", value: nitInput, onChange: params.id ? undefined : function (ev) { return setNitInput(ev.target.value); }, disabled: params.id ? true : false })),
            React.createElement("button", { className: "createCompanyButton", onClick: params.id ? function () { return editExistingCompany(); } : function () { return createNewCompany(); } }, params.id ? "Guardar cambios" : "Crear empresa / contador"))));
};
export default CreateCompany;
