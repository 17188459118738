var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useRef, useState } from 'react';
import TableActions from '../TableActions/TableActions';
import { toast } from 'react-toastify';
import { ActionTypes, Actions, toastConfig } from '../../shared/constants';
import { editSummaryValue } from '../../services/clientsService';
import "./InputForm.scss";
var InputForm = function (props) {
    var rowPart = props.rowPart, token = props.token, month = props.month, nit = props.nit, year = props.year, resolvedCellValues = props.resolvedCellValues;
    var formatValue = function (value) {
        // Si el valor es un número, formatea los miles y devuelve el resultado
        if (!isNaN(Number(value))) {
            // Dividir el número en partes enteras y decimales
            var parts = value.toString().split('.');
            // Formatear la parte entera con comas para los miles
            parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            // Unir las partes de nuevo
            return parts.join('.');
        }
        // Si no es un número, devuelve el valor sin cambios
        return value;
    };
    var _a = useState(formatValue(resolvedCellValues === null || resolvedCellValues === void 0 ? void 0 : resolvedCellValues.value) || "0"), originalValue = _a[0], setOriginalValue = _a[1];
    var _b = useState(false), showTableActions = _b[0], setShowTableActions = _b[1];
    var _c = useState({
        key: rowPart.value_id,
        value: (resolvedCellValues === null || resolvedCellValues === void 0 ? void 0 : resolvedCellValues.value) ? formatValue(resolvedCellValues.value) : "0"
    }), resolvedCell = _c[0], setResolvedCell = _c[1];
    var inputRef = useRef(null);
    var changeValue = function (ev, index) {
        setResolvedCell({
            key: index,
            value: ev
        });
    };
    var handleBlur = function () {
        setTimeout(function () {
            setShowTableActions(false);
            if (resolvedCell.value === "" || resolvedCell.value !== originalValue) {
                setResolvedCell({
                    key: rowPart === null || rowPart === void 0 ? void 0 : rowPart.value_id,
                    value: originalValue
                });
            }
        }, 200);
    };
    var saveValue = function () { return __awaiter(void 0, void 0, void 0, function () {
        var hasDecimals, formattedValue, creditResp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    hasDecimals = resolvedCell === null || resolvedCell === void 0 ? void 0 : resolvedCell.value.includes('.');
                    if (rowPart.form_id === 1311 || rowPart.form_id === 2046) {
                        if (hasDecimals) {
                            // Si tiene decimales, no se formatea
                            formattedValue = resolvedCell === null || resolvedCell === void 0 ? void 0 : resolvedCell.value;
                        }
                        else {
                            // Si es un número entero, se agrega '.00'
                            formattedValue = parseFloat(resolvedCell === null || resolvedCell === void 0 ? void 0 : resolvedCell.value).toFixed(2);
                        }
                    }
                    else if (rowPart.form_id === 2237) {
                        if (hasDecimals) {
                            // Si tiene decimales, se aproxima a un número entero
                            formattedValue = Math.round(parseFloat(resolvedCell === null || resolvedCell === void 0 ? void 0 : resolvedCell.value));
                        }
                        else {
                            // Si es un número entero, no se hace nadaF
                            formattedValue = resolvedCell === null || resolvedCell === void 0 ? void 0 : resolvedCell.value;
                        }
                    }
                    return [4 /*yield*/, editSummaryValue(token, nit, month, year, resolvedCell === null || resolvedCell === void 0 ? void 0 : resolvedCell.key, formattedValue)];
                case 1:
                    creditResp = _a.sent();
                    if (creditResp.error) {
                        toast(creditResp.message || "No fue posible guardar valor actualizado.", __assign(__assign({}, toastConfig), { type: "error" }));
                    }
                    else if (creditResp) {
                        toast("Se ha guardado el valor.", __assign(__assign({}, toastConfig), { type: "success" }));
                        setResolvedCell(function (prevState) { return (__assign(__assign({}, prevState), { value: formattedValue })); });
                    }
                    else {
                        toast("No fue posible guardar el valor.", __assign(__assign({}, toastConfig), { type: "error" }));
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    return (React.createElement("div", { className: "summaryCreditContainer" },
        React.createElement("input", { value: resolvedCell.value, ref: inputRef, type: 'text', className: "summaryYellowInput", onChange: function (ev) {
                var newValue = ev.target.value;
                if (!isNaN(parseFloat(ev.target.value)) || ev.target.value === "") {
                    changeValue(newValue, rowPart.value_id);
                }
                else {
                    toast("El valor solamente puede contener números.", __assign(__assign({}, toastConfig), { type: "warning" }));
                }
            }, onKeyDown: function (ev) {
                if (ev.key === "Enter") {
                    saveValue();
                    handleBlur();
                    inputRef.current.blur();
                }
            }, onFocus: function () {
                setShowTableActions(true);
                // Si el valor es 0 o 0.00 deja el input en blanco
                if (resolvedCell.value === "0" || resolvedCell.value === "0.00") {
                    setResolvedCell({
                        key: rowPart.value_id,
                        value: ""
                    });
                }
            }, onBlur: function () { return handleBlur(); }, style: {
                width: "5vw",
                textAlign: "left",
            } }),
        (showTableActions && resolvedCell.value !== "") && (React.createElement(TableActions, { actionList: [
                {
                    action: Actions.Accept,
                    altText: "Guardar",
                    titleText: "Guardar cambios",
                    type: ActionTypes.Button,
                    onClick: function () {
                        saveValue();
                    }
                },
                {
                    action: Actions.Close,
                    iconWidth: "1vw",
                    altText: "Cancelar",
                    titleText: "Cancelar cambios",
                    type: ActionTypes.Button,
                    onClick: function () {
                        setResolvedCell({
                            key: resolvedCell === null || resolvedCell === void 0 ? void 0 : resolvedCell.key,
                            value: originalValue
                        });
                    }
                }
            ] }))));
};
export default InputForm;
