var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { loggedUser } from "../../../UserContext/UserReducer";
import { arrayOrderByProperty, formatDateMDYYYY, getNextDownloadMonth, saveDataToFile } from "../../../shared/utils";
import Table from "../../../components/Table/Table";
import TableActions from "../../../components/TableActions/TableActions";
import searchIcon from "../../../assets/icons/searchIcon.png";
import addIcon from "../../../assets/icons/addIcon.png";
import excelIcon from "../../../assets/icons/excelIcon_white.png";
import Loader from "../../../components/Loader/Loader";
import { deleteClient, enableNit, getClients, getCompanies } from "../../../services/clientsService";
import "./AdminClients.scss";
import { Actions, Roles, popupConfig, toastConfig } from "../../../shared/constants";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { bulkCreateClients, getClientsExcelSignedURL, uploadExcelDocument } from "../../../services/documentsService";
import warningIcon from '../../../assets/icons/warningIcon.png';
import InvalidPassword from "../../../components/InvalidPassword/InvalidPassword";
var AdminClients = function () {
    var cols = [
        {
            display: "NIT",
            name: "nit",
            onClickEvent: function () { orderUsers("nit"); },
        },
        {
            display: "",
            name: "invalid_password",
            width: 10,
        },
        {
            display: "Nombre",
            name: "name",
            width: 20,
            onClickEvent: function () { orderUsers("name"); },
        },
        {
            display: "Empresa",
            name: "company",
            onClickEvent: function () { orderUsers("company_name"); },
        },
        {
            display: "Régimen",
            name: "regime",
            onClickEvent: function () { orderUsers("regime_id"); },
        },
        {
            display: "Descarga Compras",
            name: "purchasesDate",
        },
        {
            display: "Descarga Ventas",
            name: "salesDate",
        },
        {
            display: "Acciones",
            name: "actions",
            avoidClicksForRow: true,
        },
    ];
    var userData = useSelector(loggedUser);
    var navigate = useNavigate();
    var fileInputRef = useRef(null);
    var _a = useState([]), clientList = _a[0], setClientList = _a[1];
    var _b = useState([]), filteredClientList = _b[0], setFilteredClientList = _b[1];
    var _c = useState(""), searchText = _c[0], setSearchText = _c[1];
    var _d = useState(false), clientsFetched = _d[0], setClientsFetched = _d[1];
    var _e = useState({}), companyList = _e[0], setCompanyList = _e[1];
    var initData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, companies, newCompList_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getClients(userData.idToken)];
                case 1:
                    response = _a.sent();
                    return [4 /*yield*/, getCompanies(userData.idToken)];
                case 2:
                    companies = _a.sent();
                    setClientList(response);
                    setFilteredClientList(response);
                    if (companies && Array.isArray(companies)) {
                        newCompList_1 = {};
                        companies.forEach(function (elem, ind) {
                            newCompList_1[elem.company_id] = elem.company_name;
                        });
                        setCompanyList(newCompList_1);
                    }
                    setClientsFetched(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var exportToExcel = function () {
        var header = ["Nit", "Nit de la empresa-contador", "ID de Régimen", "Nombre", "Movimiento (Sí/No)", "Crédito Fiscal (Quetzales)", "Credito Retenciones de IVA", "Descarga Automática de archivos PDF (Sí/No)", "Contraseña de Portal", "Nit Delegado", "Automatizado (Sí/No)", "Fecha de Nacimiento (MM/DD/AA)"];
        var data = __spreadArray([header], filteredClientList.map(function (client) { return [
            client.nit,
            client.company_id,
            client.regime_id,
            client.name,
            (client.has_movement === 1) ? "Sí" : "No",
            "",
            "",
            (client.download_pdf === 1) ? "Sí" : "No",
            "",
            (client.nit_delegado == "undefined") ? '' : (client.nit_delegado == "null") ? '' : client.nit_delegado,
            (client.is_automated === 1) ? "Sí" : "No",
            formatDateMDYYYY(new Date(client.birthdate))
        ]; }), true);
        saveDataToFile(data, "clientes", "xlsx", 'Formato');
    };
    var getClientRows = function (allClients) {
        return allClients.map(function (client, clientInd) {
            return {
                nit: client.nit,
                invalid_password: (client.invalid_password === 1) ? React.createElement(InvalidPassword, { title: "Actualizar contrase\u00F1a", onClick: function () { navigate("/admin/client/edit/".concat(client.nit)); }, icon: warningIcon }) : "",
                name: client.name,
                regime: client.regime_name,
                company: client.company_name,
                purchasesDate: "".concat(client.purchases_download_date, " ").concat(getNextDownloadMonth(client.purchases_download_date)),
                salesDate: "".concat(client.sales_download_date, " ").concat(getNextDownloadMonth(client.sales_download_date)),
                actions: React.createElement(TableActions, { actionList: [
                        {
                            action: Actions.Delete,
                            onClick: function () {
                                Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Seguro?', html: "\n                      <div>\n                        \u00BFQuieres eliminar la cuenta de <b>".concat(client.name, "</b> con nit\n                        <b>").concat(client.nit, "</b>? Se eliminar\u00E1 toda su informaci\u00F3n relacionada, incluyendo facturas y documentos.\n                      </div>\n                    "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, entiendo las consecuencias', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                    var deleteResp;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!result.isConfirmed) return [3 /*break*/, 2];
                                                return [4 /*yield*/, deleteClient(userData.idToken, client.nit)];
                                            case 1:
                                                deleteResp = _a.sent();
                                                if (deleteResp) {
                                                    toast("Cliente eliminado correctamente", __assign(__assign({}, toastConfig), { type: "success" }));
                                                    initData();
                                                }
                                                _a.label = 2;
                                            case 2: return [2 /*return*/];
                                        }
                                    });
                                }); });
                            },
                            altText: "eliminar",
                            titleText: "Eliminar",
                            keyId: "".concat(clientInd),
                        },
                        {
                            action: Actions.Edit,
                            onClick: function () {
                                navigate("/admin/client/edit/".concat(client.nit));
                            },
                            altText: "editar",
                            titleText: "Editar",
                            keyId: "".concat(clientInd),
                        },
                        {
                            action: client.is_enabled === 1 ? Actions.Enable : Actions.Disable,
                            onClick: function () {
                                Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Seguro?', html: "\n                      <div>\n                        \u00BFQuieres ".concat(client.is_enabled === 1 ? 'deshabilitar' : 'habilitar', " la cuenta de <b>").concat(client.name, "</b> con nit\n                        <b>").concat(client.nit, "</b>? ").concat(client.is_enabled === 1 ? 'Su información permanecerá en el sistema, pero no será tomado en cuenta por el bot.' : 'Será tomado en cuenta por el bot nuevamente', ".\n                      </div>\n                    "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, entiendo las consecuencias', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                    var editResp;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!result.isConfirmed) return [3 /*break*/, 2];
                                                return [4 /*yield*/, enableNit(userData.idToken, client.nit, client.is_enabled === 1 ? 0 : 1)];
                                            case 1:
                                                editResp = _a.sent();
                                                if (editResp.error) {
                                                    toast(editResp.message || "Ocurrió un error.", __assign(__assign({}, toastConfig), { type: "error" }));
                                                }
                                                else if (editResp) {
                                                    toast("Cliente ".concat(client.is_enabled === 1 ? 'deshabilitado' : 'habilitado', " correctamente"), __assign(__assign({}, toastConfig), { type: "success" }));
                                                    initData();
                                                }
                                                _a.label = 2;
                                            case 2: return [2 /*return*/];
                                        }
                                    });
                                }); });
                            },
                            altText: client.is_enabled === 1 ? "deshabilitar" : "habilitar",
                            titleText: client.is_enabled === 1 ? "Deshabilitar" : "Habilitar",
                            keyId: "".concat(clientInd),
                        },
                    ] }),
                customStyles: {
                    nit: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    name: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    regime: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    company: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    purchasesDate: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    salesDate: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    actions: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    invalid_password: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                        padding: "1vw 0vw",
                    },
                }
            };
        });
    };
    var orderUsers = function (property) {
        var newArray = __spreadArray([], clientList, true);
        arrayOrderByProperty(newArray, property);
        setFilteredClientList(newArray);
    };
    var searchHandler = function (ev) {
        setSearchText(ev.target.value);
        var searchVal = ev.target.value.toLowerCase();
        if (searchVal === "") {
            setFilteredClientList(clientList);
        }
        else {
            var filteredResults = clientList.filter(function (client) { return client.name.toLowerCase().includes(searchVal)
                || client.nit.includes(searchVal); });
            setFilteredClientList(filteredResults);
        }
    };
    var onFileChange = function (ev) {
        var file = ev.target.files ? ev.target.files[0] : null;
        if (file) {
            Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Procesar archivo?', html: "\n          <div><b>Nombre del archivo:</b> ".concat(file.name, "</div>\n        "), inputLabel: Object.keys(companyList).length > 1 ? "Por favor seleccione la empresa-contador a cargo" : undefined, input: Object.keys(companyList).length > 1 ? "select" : undefined, inputOptions: Object.keys(companyList).length > 1 ? companyList : undefined, icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, procesar', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                var fileExtension, signedResp, fileUploadResp, bulkUploadResp;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.isConfirmed) return [3 /*break*/, 5];
                            // Process File
                            setClientsFetched(false);
                            fileExtension = file.name.split('.').pop();
                            return [4 /*yield*/, getClientsExcelSignedURL(userData.idToken, new Date(), fileExtension)];
                        case 1:
                            signedResp = _a.sent();
                            if (!signedResp) return [3 /*break*/, 5];
                            if (!signedResp.message) return [3 /*break*/, 2];
                            toast(signedResp.message, __assign(__assign({}, toastConfig), { type: 'error' }));
                            return [3 /*break*/, 5];
                        case 2: return [4 /*yield*/, uploadExcelDocument(signedResp.signedURL, file)];
                        case 3:
                            fileUploadResp = _a.sent();
                            return [4 /*yield*/, bulkCreateClients(userData.idToken, Object.keys(companyList).length === 1 ? Object.keys(companyList)[0] : result.value, new Date(), fileExtension)];
                        case 4:
                            bulkUploadResp = _a.sent();
                            if (bulkUploadResp) {
                                if (bulkUploadResp.error) {
                                    toast(bulkUploadResp.message || "Error de servidor.", __assign(__assign({}, toastConfig), { type: 'error' }));
                                }
                                else {
                                    initData(); // Refresh data
                                    toast('Clientes agregados con éxito', __assign(__assign({}, toastConfig), { type: 'success' }));
                                }
                            }
                            setClientsFetched(true);
                            _a.label = 5;
                        case 5:
                            fileInputRef.current.value = null;
                            return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    useEffect(function () {
        if (clientList.length === 0) {
            initData();
        }
    }, []);
    return (React.createElement("div", { className: "adminClientsContainer" },
        React.createElement("div", { className: "adminClientsToolbar" },
            React.createElement("div", { className: "adminClientsToolbarLeft" },
                React.createElement("div", { className: "adminClientsTitle" }, "Clientes")),
            React.createElement("div", { className: "adminClientsToolbarRight" },
                React.createElement("div", { className: "adminClientsSearchbar" },
                    React.createElement("img", { src: searchIcon, alt: "Search" }),
                    React.createElement("input", { placeholder: "Buscar por NIT o por nombre...", onChange: function (ev) { return searchHandler(ev); } })),
                React.createElement("button", { className: "adminClientsFilledButton", title: "Agregar clientes con Excel", onClick: function () {
                        if (fileInputRef) {
                            fileInputRef.current.click();
                        }
                    }, disabled: !clientsFetched },
                    React.createElement("input", { type: "file", accept: ".xls, .xlsx", onChange: function (ev) { return onFileChange(ev); }, ref: fileInputRef, style: { display: 'none' } }),
                    React.createElement("div", null, "Agregar Clientes"),
                    React.createElement("img", { src: excelIcon, alt: "Crear" })),
                React.createElement("button", { className: "adminClientsFilledButton", onClick: exportToExcel },
                    "Descargar Clientes",
                    React.createElement("img", { style: { marginRight: '0.5vw' }, src: excelIcon, alt: "[xlsx]" })),
                React.createElement(Link, { className: "adminClientsButton", title: "Crear Cliente", to: "/admin/client/create" },
                    React.createElement("img", { src: addIcon, alt: "Crear" })))),
        filteredClientList.length > 0 && !clientsFetched
            ?
                React.createElement("div", { className: "clientsNoResults" },
                    React.createElement(Loader, null))
            : filteredClientList.length > 0 &&
                React.createElement(Table, { columns: userData.role === Roles.Superadmin ? cols : cols.filter(function (elem) { return elem.name !== "company"; }), rows: getClientRows(filteredClientList), width: "95vw", paginate: true }),
        filteredClientList.length === 0 && searchText !== ""
            ? React.createElement("div", { className: "clientsNoResults" }, "No se encontraron resultados.")
            : filteredClientList.length === 0 && !clientsFetched
                ?
                    React.createElement("div", { className: "clientsNoResults" },
                        React.createElement(Loader, null))
                : filteredClientList.length === 0 &&
                    React.createElement("div", { className: "clientsNoResults" }, "Usted no tiene asignado ning\u00FAn cliente.")));
};
export default AdminClients;
