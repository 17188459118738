var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import "./Others.scss";
import Table from "../../components/Table/Table";
import TableActions from "../../components/TableActions/TableActions";
import { ActionTypes, Actions, popupConfig, toastConfig } from "../../shared/constants";
import DateTransitioner from "../../components/DateTransitioner/DateTransitioner";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { getClient, getIsrRetentions, getIvaRetentions } from "../../services/clientsService";
import { useSelector } from "react-redux";
import { loggedUser } from "../../UserContext/UserReducer";
import { formatDate } from "../../shared/utils";
import { assignRetentionPDF, deleteRetention, getExcelSignedURL, getPDFSignedURL, uploadExcelDocument, uploadPDFDocument, uploadRetentions } from "../../services/documentsService";
import uploadIcon from "../../assets/icons/whiteUploadIcon.png";
import invoiceIcon from "../../assets/icons/invoice_icon.png";
import Loader from "../../components/Loader/Loader";
import NewRetentionPopup from "../../components/NewRetentionPopup/NewRetentionPopup";
import Tooltip from "../../components/Tooltip/Tooltip";
var Others = function () {
    var params = useParams();
    var userData = useSelector(loggedUser);
    var fileInputRef = useRef(null);
    var tableISRCols = [
        {
            display: '',
            name: 'pdfActions',
        },
        {
            display: 'NIT Retenedor',
            name: 'doc_nit',
        },
        {
            display: 'Factura asociada',
            name: 'linked_invoice',
        },
        {
            display: 'Nombre Retenedor',
            name: 'name',
        },
        {
            display: 'Constancia',
            name: 'document_id',
        },
        {
            display: 'Fecha Emisión',
            name: 'date',
        },
        {
            display: 'Total Retención',
            name: 'total',
        },
        {
            display: 'Acciones',
            name: 'actions',
        },
    ];
    var tableIVACols = [
        {
            display: '',
            name: 'pdfActions',
        },
        {
            display: 'NIT Retenedor',
            name: 'doc_nit',
        },
        {
            display: 'Nombre Retenedor',
            name: 'name',
        },
        {
            display: 'Constancia',
            name: 'document_id',
        },
        {
            display: 'Fecha Emisión',
            name: 'date',
        },
        {
            display: 'Total Retención',
            name: 'total',
        },
        {
            display: 'Acciones',
            name: 'actions',
        },
    ];
    var _a = useState({ nit: params.nit, company_id: "", name: "" }), nitInfo = _a[0], setNitInfo = _a[1];
    var _b = useState(params.month), currentDisplayMonth = _b[0], setCurrentDisplayMonth = _b[1];
    var _c = useState(params.year), currentDisplayYear = _c[0], setCurrentDisplayYear = _c[1];
    var _d = useState(false), uploadIva = _d[0], setUploadIva = _d[1]; // State to determine if uploaded file will be for IVA (true) or ISR (false)
    var _e = useState(false), loadingData = _e[0], setLoadingData = _e[1];
    var _f = useState([]), isrData = _f[0], setIsrData = _f[1];
    var _g = useState([]), ivaData = _g[0], setIvaData = _g[1];
    var _h = useState(false), createRetenOpen = _h[0], setCreateRetenOpen = _h[1];
    var initData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var clientData, isrResp, ivaResp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoadingData(true);
                    return [4 /*yield*/, getClient(userData.idToken, params.nit)];
                case 1:
                    clientData = _a.sent();
                    return [4 /*yield*/, getIsrRetentions(userData.idToken, params.nit, params.month, params.year)];
                case 2:
                    isrResp = _a.sent();
                    return [4 /*yield*/, getIvaRetentions(userData.idToken, params.nit, params.month, params.year)];
                case 3:
                    ivaResp = _a.sent();
                    if (clientData) {
                        setNitInfo(clientData);
                    }
                    if (isrResp)
                        setIsrData(isrResp);
                    if (ivaResp)
                        setIvaData(ivaResp);
                    setLoadingData(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var getTableRows = function (dataToMap, tableCols) {
        var formatter = new Intl.NumberFormat('es-GT', {
            style: 'currency',
            currency: 'GTQ',
            maximumFractionDigits: 2,
        });
        return dataToMap.map(function (elem, ind) {
            var currRow = {};
            tableCols.forEach(function (col, colInd) {
                if (elem[col.name] && col.name === "date") {
                    currRow[col.name] = formatDate(new Date(elem[col.name]));
                }
                else if (elem[col.name] && col.name === "total") {
                    currRow[col.name] = formatter.format(elem[col.name]);
                }
                else if (col.name === "linked_invoice") {
                    currRow[col.name] =
                        React.createElement(Tooltip, { text: (elem['factura_num'] && elem['factura_serie']) ? "Numero: ".concat(elem['factura_num'], " Serie: ").concat(elem['factura_serie']) : "" },
                            React.createElement("img", { className: "linkedInvoiceIndicator", src: invoiceIcon, alt: "Serie: Numero: " }));
                }
                else if (elem[col.name]) {
                    currRow[col.name] = elem[col.name];
                }
            });
            currRow["pdfActions"] = React.createElement(TableActions, { actionList: [
                    {
                        action: Actions.PDF,
                        titleText: 'Ver PDF',
                        altText: 'ver',
                        type: elem.pdf_url ? ActionTypes.Link : ActionTypes.None,
                        navigationURL: elem.pdf_url,
                        keyId: "".concat(ind, "-viewRetentionPdf-").concat(elem.document_id),
                    },
                    {
                        action: Actions.Delete,
                        altText: 'eliminar',
                        titleText: 'Eliminar Archivo',
                        type: elem.pdf_url ? ActionTypes.Button : ActionTypes.None,
                        onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Quieres eliminar el PDF de esta retención?', html: "\n                  <div><b>Nombre</b> ".concat(elem.name, "</div>\n                  <div><b>Constancia</b> ").concat(elem.document_id, "</div>\n                  <div><b>Nit </b>").concat(elem.doc_nit, "</div>\n                  <div><b>Fecha</b> ").concat(formatDate(new Date(elem.date)), "</div>\n                  <div><b>Total</b> ").concat(formatter.format(elem.total), "</div>\n                "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, eliminar', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                    var newCurrPurchaseCopy;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!result.isConfirmed) return [3 /*break*/, 2];
                                                return [4 /*yield*/, deleteRetention(userData.idToken, elem.document_id, 'pdf')];
                                            case 1:
                                                _a.sent();
                                                newCurrPurchaseCopy = elem;
                                                newCurrPurchaseCopy.pdf_url = "";
                                                // Update state
                                                toast('PDF eliminado exitosamente', __assign(__assign({}, toastConfig), { type: 'success' }));
                                                initData();
                                                _a.label = 2;
                                            case 2: return [2 /*return*/];
                                        }
                                    });
                                }); });
                                return [2 /*return*/];
                            });
                        }); },
                        keyId: "".concat(ind, "-deleteRetentionPDF-").concat(elem.document_id),
                    },
                    {
                        action: Actions.UploadFile,
                        type: !elem.pdf_url ? ActionTypes.FileExplorer : ActionTypes.None,
                        altText: "subir",
                        titleText: "Subir archivo",
                        acceptExtensions: ".pdf",
                        onFileChange: function (ev) { return __awaiter(void 0, void 0, void 0, function () {
                            var file, fileExtension, signedResp, fileUploadResp, assignResp, newRetenObj;
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        file = ev.target.files ? ev.target.files[0] : null;
                                        if (!file) return [3 /*break*/, 4];
                                        fileExtension = file.name.split('.').pop();
                                        return [4 /*yield*/, getPDFSignedURL(userData.idToken, elem.document_id, fileExtension)];
                                    case 1:
                                        signedResp = _a.sent();
                                        if (!signedResp) return [3 /*break*/, 4];
                                        return [4 /*yield*/, uploadPDFDocument(signedResp.signedURL, file)];
                                    case 2:
                                        fileUploadResp = _a.sent();
                                        return [4 /*yield*/, assignRetentionPDF(userData.idToken, elem.document_id, fileExtension)];
                                    case 3:
                                        assignResp = _a.sent();
                                        newRetenObj = elem;
                                        newRetenObj.pdf_url = assignResp ? assignResp.pdf_url : "";
                                        // Update state
                                        toast('PDF subido exitosamente', __assign(__assign({}, toastConfig), { type: 'success' }));
                                        initData();
                                        _a.label = 4;
                                    case 4: return [2 /*return*/];
                                }
                            });
                        }); }
                    }
                ] });
            currRow["actions"] = React.createElement(TableActions, { actionList: [
                    // {
                    //   action: Actions.MoveLeft,
                    //   altText: 'moverMesAnterior',
                    //   titleText: 'Mover al Mes Anterior',
                    //   type: ActionTypes.Button,
                    //   onClick: () => {
                    //     Swal.fire({
                    //       ...popupConfig,
                    //       title: '¿Quieres mover esta retención al mes anterior?',
                    //       html:`
                    //         <div><b>Nombre: </b>${elem["name"] || ""}</div>
                    //         <div><b>NIT Retenedor: </b>${elem["retainer"] || ""}</div>
                    //         <div><b>No. Constancia: </b>${elem["constNumber"] || ""}</div>
                    //         <div><b>Estado Constancia: </b>${elem["state"] || ""}</div>
                    //         <div><b>Fecha de Emisión: </b>${elem["date"] || ""}</div>
                    //         <div><b>Renta Imponible: </b>${elem["rent"] || ""}</div>
                    //         <div><b>Total Retención: </b>${elem["totalRetain"] || ""}</div>
                    //         <div><b>Total: </b>${elem["total"] || ""}</div>
                    //       `
                    //       ,
                    //       icon: 'warning',
                    //       showCancelButton: true,
                    //       confirmButtonText: 'Sí, mover al mes anterior',
                    //       cancelButtonText: 'Cancelar',
                    //     }).then((result) => {
                    //       if (result.isConfirmed) {
                    //         // TODO add move endpoint
                    //         toast('Se ha movido al mes anterior', {...toastConfig, type: 'success'})
                    //       }
                    //     })
                    //   },
                    // },
                    // {
                    //   action: Actions.MoveRight,
                    //   altText: 'moverMesSiguiente',
                    //   titleText: 'Mover al Mes Siguiente',
                    //   type: ActionTypes.Button,
                    //   onClick: () => {
                    //     Swal.fire({
                    //       ...popupConfig,
                    //       title: '¿Quieres mover esta retención al mes siguiente?',
                    //       html:`
                    //         <div><b>Nombre: </b>${elem["name"] || ""}</div>
                    //         <div><b>NIT Retenedor: </b>${elem["retainer"] || ""}</div>
                    //         <div><b>No. Constancia: </b>${elem["constNumber"] || ""}</div>
                    //         <div><b>Estado Constancia: </b>${elem["state"] || ""}</div>
                    //         <div><b>Fecha de Emisión: </b>${elem["date"] || ""}</div>
                    //         <div><b>Renta Imponible: </b>${elem["rent"] || ""}</div>
                    //         <div><b>Total Retención: </b>${elem["totalRetain"] || ""}</div>
                    //         <div><b>Total: </b>${elem["total"] || ""}</div>
                    //       `
                    //       ,
                    //       icon: 'warning',
                    //       showCancelButton: true,
                    //       confirmButtonText: 'Sí, mover al mes siguiente',
                    //       cancelButtonText: 'Cancelar',
                    //     }).then((result) => {
                    //       if (result.isConfirmed) {
                    //         // TODO add move endpoint
                    //         toast('Se ha movido al mes siguiente', {...toastConfig, type: 'success'})
                    //       }
                    //     })
                    //   },
                    // },
                    {
                        action: Actions.Delete,
                        altText: 'eliminar',
                        titleText: 'Eliminar Retención',
                        type: ActionTypes.Button,
                        onClick: function () {
                            Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Quieres eliminar esta retención?', html: "\n                  <div><b>Nombre: </b>".concat(elem["name"] || "", "</div>\n                  <div><b>NIT Retenedor: </b>").concat(elem["doc_nit"] || "", "</div>\n                  <div><b>No. Constancia: </b>").concat(elem["document_id"] || "", "</div>\n                  <div><b>Fecha de Emisi\u00F3n: </b>").concat(formatDate(new Date(elem["date"])) || "", "</div>\n                  <div><b>Total Retenci\u00F3n: </b>").concat(elem["total"] || "", "</div>\n                "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, entiendo las consecuencias', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                var deleteResp;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!result.isConfirmed) return [3 /*break*/, 2];
                                            return [4 /*yield*/, deleteRetention(userData.idToken, elem.document_id)];
                                        case 1:
                                            deleteResp = _a.sent();
                                            if (deleteResp) {
                                                toast('Se ha eliminado la retención', __assign(__assign({}, toastConfig), { type: 'success' }));
                                                initData();
                                            }
                                            else {
                                                toast('Error al eliminar la retención', __assign(__assign({}, toastConfig), { type: 'error' }));
                                            }
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); });
                        },
                    },
                ] });
            return currRow;
        });
    };
    var onFileChange = function (ev) {
        var file = ev.target.files ? ev.target.files[0] : null;
        if (file) {
            Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Procesar archivo?', html: "\n          <div><b>Nombre del archivo:</b> ".concat(file.name, "</div>\n        "), inputLabel: "Por favor seleccione el tipo de retención", input: "select", inputOptions: { ISR: "ISR", IVA: "IVA" }, icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, procesar', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                var fileExtension, signedResp, fileUploadResp, felUploadResp;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.isConfirmed) return [3 /*break*/, 4];
                            // Process File
                            setLoadingData(true);
                            fileExtension = file.name.split('.').pop();
                            return [4 /*yield*/, getExcelSignedURL(userData.idToken, params.nit, params.month, params.year, result.value, fileExtension)];
                        case 1:
                            signedResp = _a.sent();
                            if (!signedResp) return [3 /*break*/, 4];
                            return [4 /*yield*/, uploadExcelDocument(signedResp.signedURL, file)];
                        case 2:
                            fileUploadResp = _a.sent();
                            return [4 /*yield*/, uploadRetentions(userData.idToken, nitInfo.company_id, params.nit, params.month, params.year, result.value, fileExtension)];
                        case 3:
                            felUploadResp = _a.sent();
                            setLoadingData(false);
                            toast('Retenciones importadas exitosamente', __assign(__assign({}, toastConfig), { type: 'success' }));
                            initData(); // Refresh data
                            _a.label = 4;
                        case 4: return [2 /*return*/];
                    }
                });
            }); });
        }
    };
    useEffect(function () {
        if (params.month !== currentDisplayMonth
            || params.year !== currentDisplayYear
            || isrData.length === 0) {
            initData();
            setCurrentDisplayMonth(params.month);
            setCurrentDisplayYear(params.year);
        }
    }, [params]);
    return (React.createElement("div", { className: "othersContainer" },
        React.createElement(NewRetentionPopup, { openState: createRetenOpen, setIsOpen: setCreateRetenOpen, refreshData: initData, params: params }),
        React.createElement("div", { className: "othersNitTitle" }, "".concat(params.nit, " - ").concat(nitInfo.name)),
        React.createElement(DateTransitioner, { defaultMonth: currentDisplayMonth, defaultYear: currentDisplayYear, redirectionPath: "/others/".concat(params.nit) }),
        loadingData
            ? React.createElement(Loader, null)
            : React.createElement(React.Fragment, null,
                React.createElement("div", { className: "othersActions" },
                    React.createElement("button", { className: "othersFilledButton", onClick: function () {
                            if (fileInputRef) {
                                fileInputRef.current.click();
                            }
                        }, title: "Importar FEL" },
                        React.createElement("input", { type: "file", accept: ".xls, .xlsx", onChange: function (ev) { return onFileChange(ev); }, ref: fileInputRef, style: { display: 'none' } }),
                        React.createElement("div", null, "Importar Retenciones"),
                        React.createElement("img", { src: uploadIcon, alt: "Subir archivo", style: { marginLeft: '0.5vw' } })),
                    React.createElement("button", { className: "othersFilledButton", onClick: function (ev) {
                            ev.stopPropagation();
                            setCreateRetenOpen(true);
                        } }, "Agregar Retenci\u00F3n")),
                React.createElement("div", { className: "othersTitle" }, "Retenciones de ISR recibidas"),
                isrData.length > 0
                    ?
                        React.createElement(Table, { columns: tableISRCols, rows: getTableRows(isrData, tableISRCols), width: "95vw", tableContrastColor: "#E2E2E2", tableContrastTextColor: "#5B5B5B", showBorders: true })
                    :
                        React.createElement("div", { className: "othersNoMovement" }, "** Sin Movimiento **"),
                React.createElement("div", { className: "othersTitle" }, "Retenciones de IVA recibidas"),
                ivaData.length > 0
                    ?
                        React.createElement(Table, { columns: tableIVACols, rows: getTableRows(ivaData, tableIVACols), width: "95vw", tableContrastColor: "#E2E2E2", tableContrastTextColor: "#5B5B5B", showBorders: true })
                    :
                        React.createElement("div", { className: "othersNoMovement" }, "** Sin Movimiento **"))));
};
export default Others;
