var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import "./Forms.scss";
import { useParams } from "react-router-dom";
import { getClient, updateClientStatus } from "../../services/clientsService";
import { useSelector } from "react-redux";
import { loggedUser } from "../../UserContext/UserReducer";
import DateTransitioner from "../../components/DateTransitioner/DateTransitioner";
import { ActionTypes, Actions, popupConfig, toastConfig } from "../../shared/constants";
import Loader from "../../components/Loader/Loader";
import Table from "../../components/Table/Table";
import { assignRetentionPDF, deleteRetention, getPDFSignedURL, getPresentedForm, uploadPDFDocument } from "../../services/documentsService";
import TableActions from "../../components/TableActions/TableActions";
import { formatDate } from "../../shared/utils";
import NewFormPopup from "../../components/NewFormPopup/NewFormPopup";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
var Forms = function () {
    var params = useParams();
    var userData = useSelector(loggedUser);
    var _a = useState({
        nit: params.nit,
        company_id: '',
        name: '',
        birthdate: '',
        credito_fiscal: 0,
        download_pdf: 0,
        has_movement: 0,
        is_automated: 0,
        nit_delegado: '',
        password: '',
        regime_id: 0,
        regime_task_name: '',
        invalid_password: 0,
    }), nitInfo = _a[0], setNitInfo = _a[1];
    var _b = useState([]), formDocuments = _b[0], setFormDocuments = _b[1];
    var _c = useState(params.month), currentDisplayMonth = _c[0], setCurrentDisplayMonth = _c[1];
    var _d = useState(params.year), currentDisplayYear = _d[0], setCurrentDisplayYear = _d[1];
    var _e = useState(false), loadingData = _e[0], setLoadingData = _e[1];
    var _f = useState(false), createFormOpen = _f[0], setCreateFormOpen = _f[1];
    var formatter = new Intl.NumberFormat('es-GT', {
        style: 'currency',
        currency: 'GTQ',
        maximumFractionDigits: 2,
    });
    var formsTableColumns = [
        {
            display: "PDF",
            name: "fileActions",
        },
        {
            display: "Formulario",
            name: "formId",
        },
        {
            display: "Estado",
            name: "state",
        },
        {
            display: "No. de Formulario",
            name: "formNumber",
        },
        {
            display: "No. de Acceso",
            name: "formAccess",
        },
        {
            display: "Total a Pagar",
            name: "total",
        },
        {
            display: "Acciones",
            name: "actions"
        },
    ];
    var initData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, clientData, form1311, form2237, form2046, form1608, form1361;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoadingData(true);
                    return [4 /*yield*/, Promise.all([
                            getClient(userData.idToken, params.nit),
                            getPresentedForm(userData.idToken, params.nit, params.month, params.year, 1311),
                            getPresentedForm(userData.idToken, params.nit, params.month, params.year, 2237),
                            getPresentedForm(userData.idToken, params.nit, params.month, params.year, 2046),
                            getPresentedForm(userData.idToken, params.nit, params.month, params.year, 1608),
                            getPresentedForm(userData.idToken, params.nit, params.month, params.year, 1361),
                        ])];
                case 1:
                    _a = _b.sent(), clientData = _a[0], form1311 = _a[1], form2237 = _a[2], form2046 = _a[3], form1608 = _a[4], form1361 = _a[5];
                    if (clientData && !clientData.error) {
                        setNitInfo(clientData);
                    }
                    setFormDocuments(__spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([], form1311, true), form2237, true), form2046, true), form1608, true), form1361, true));
                    setLoadingData(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var getFormDocRows = function (currDocs) {
        return currDocs.map(function (form, formInd) {
            if (form) {
                return {
                    fileActions: React.createElement(TableActions, { actionList: [
                            {
                                action: Actions.PDF,
                                titleText: "Descargar PDF",
                                altText: "descargar",
                                type: form.pdf_url ? ActionTypes.Link : ActionTypes.None,
                                navigationURL: form.pdf_url,
                            },
                            {
                                action: Actions.UploadFile,
                                type: !form.pdf_url ? ActionTypes.FileExplorer : ActionTypes.None,
                                altText: "subir",
                                titleText: "Subir archivo",
                                acceptExtensions: ".pdf",
                                onFileChange: function (ev) { return __awaiter(void 0, void 0, void 0, function () {
                                    var file, fileExtension, signedResp, fileUploadResp, assignResp, newRetenObj;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                file = ev.target.files ? ev.target.files[0] : null;
                                                if (!file) return [3 /*break*/, 4];
                                                fileExtension = file.name.split('.').pop();
                                                return [4 /*yield*/, getPDFSignedURL(userData.idToken, form.document_id, fileExtension)];
                                            case 1:
                                                signedResp = _a.sent();
                                                if (!signedResp) return [3 /*break*/, 4];
                                                return [4 /*yield*/, uploadPDFDocument(signedResp.signedURL, file)];
                                            case 2:
                                                fileUploadResp = _a.sent();
                                                return [4 /*yield*/, assignRetentionPDF(userData.idToken, form.document_id, fileExtension)];
                                            case 3:
                                                assignResp = _a.sent();
                                                newRetenObj = form;
                                                newRetenObj.pdf_url = assignResp ? assignResp.pdf_url : "";
                                                // Update state
                                                toast('PDF subido exitosamente', __assign(__assign({}, toastConfig), { type: 'success' }));
                                                initData();
                                                _a.label = 4;
                                            case 4: return [2 /*return*/];
                                        }
                                    });
                                }); }
                            }
                        ] }),
                    formId: "SAT-".concat(form.other_document_type_id),
                    formNumber: form.document_id,
                    formAccess: form.doc_nit,
                    state: form.name,
                    date: formatDate(new Date(form.date)),
                    total: formatter.format(form.total),
                    actions: React.createElement(TableActions, { actionList: [
                            {
                                action: Actions.Delete,
                                type: ActionTypes.Button,
                                altText: "Eliminar",
                                titleText: "Eliminar Formulario",
                                onClick: function () {
                                    Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Seguro?', html: "\n                      <div>\u00BFQuieres eliminar el formulario <b>SAT-".concat(form.other_document_type_id, "</b> con No. <b>").concat(form.document_id, "</b> y estado <b>").concat(form.name, "</b>?</div>\n                    "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, entiendo las consecuencias', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                        var deleteResp, statusResp;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    if (!result.isConfirmed) return [3 /*break*/, 4];
                                                    return [4 /*yield*/, deleteRetention(userData.idToken, form.document_id)];
                                                case 1:
                                                    deleteResp = _a.sent();
                                                    if (!deleteResp.error) return [3 /*break*/, 2];
                                                    toast(deleteResp.message || 'Error al eliminar el formulario', __assign(__assign({}, toastConfig), { type: 'error' }));
                                                    return [3 /*break*/, 4];
                                                case 2:
                                                    toast('Se ha eliminado el formulario', __assign(__assign({}, toastConfig), { type: 'success' }));
                                                    return [4 /*yield*/, updateClientStatus(userData.idToken, params.nit, parseInt(params.month), parseInt(params.year), form.other_document_type_id, 'pending', '')];
                                                case 3:
                                                    statusResp = _a.sent();
                                                    if (statusResp.error) {
                                                        toast(statusResp.message || 'Error al actualizar el estado', __assign(__assign({}, toastConfig), { type: 'error' }));
                                                    }
                                                    initData();
                                                    _a.label = 4;
                                                case 4: return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                }
                            }
                        ] })
                };
            }
        });
    };
    useEffect(function () {
        if (params.month !== currentDisplayMonth
            || params.year !== currentDisplayYear
            || formDocuments.length === 0) {
            initData();
            setCurrentDisplayMonth(params.month);
            setCurrentDisplayYear(params.year);
        }
    }, [params]);
    return (React.createElement("div", { className: "formsContainer" },
        React.createElement(NewFormPopup, { openState: createFormOpen, setIsOpen: setCreateFormOpen, params: params, refreshData: initData, clientData: nitInfo }),
        React.createElement("div", { className: "formsNitTitle" }, "".concat(params.nit, " - ").concat(nitInfo.name)),
        React.createElement(DateTransitioner, { defaultMonth: currentDisplayMonth, defaultYear: currentDisplayYear, redirectionPath: "/forms/".concat(params.nit) }),
        loadingData
            ? React.createElement(Loader, null)
            : React.createElement(React.Fragment, null,
                React.createElement("div", { className: "formsActions" },
                    React.createElement("button", { className: "formsFilledButton", onClick: function (ev) {
                            ev.stopPropagation();
                            setCreateFormOpen(true);
                        } }, "Subir Formulario")),
                React.createElement("div", { className: "formsTitle" }, "Formularios presentados"),
                formDocuments.length > 0
                    ?
                        React.createElement(Table, { columns: formsTableColumns, rows: getFormDocRows(formDocuments), width: "95vw", tableContrastColor: "#E2E2E2", tableContrastTextColor: "#5B5B5B", showBorders: true })
                    :
                        React.createElement("div", { className: "formsNoMovement" }, "** Sin Movimiento **"))));
};
export default Forms;
