import React, { useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import './AbsoluteToolTip.scss';
var Tooltip = function (_a) {
    var text = _a.text, onClick = _a.onClick, children = _a.children, _b = _a.cursor, cursor = _b === void 0 ? false : _b;
    var _c = useState(false), tooltipVisible = _c[0], setTooltipVisible = _c[1];
    var tooltipRef = useRef(null);
    var _d = useState({ top: 0, left: 0 }), tooltipPosition = _d[0], setTooltipPosition = _d[1];
    var handleMouseEnter = function (event) {
        setTooltipVisible(true);
        if (tooltipRef.current) {
            var boundingRect = tooltipRef.current.getBoundingClientRect();
            var tooltipHeight = tooltipRef.current.clientHeight;
            setTooltipPosition({
                top: boundingRect.top - tooltipHeight + 10,
                left: boundingRect.left + boundingRect.width / 2
            });
        }
    };
    var handleMouseLeave = function () {
        setTooltipVisible(false);
    };
    return (React.createElement("div", { ref: tooltipRef, onClick: onClick, onMouseEnter: handleMouseEnter, onMouseLeave: handleMouseLeave, style: { position: 'relative', cursor: cursor ? 'pointer' : 'default' } },
        children,
        tooltipVisible && ReactDOM.createPortal(React.createElement("div", null,
            React.createElement("div", { style: {
                    position: 'fixed',
                    left: tooltipPosition.left,
                    top: tooltipPosition.top,
                    transform: 'translate(-50%, -97%)',
                    zIndex: 1000,
                    padding: '10px',
                    borderRadius: '10px',
                    backgroundColor: '#efefef',
                    boxShadow: '0 2px 5px rgba(0,0,0,0.1)',
                    maxWidth: '200px',
                    textAlign: 'center',
                    visibility: tooltipVisible ? 'visible' : 'hidden'
                }, className: "tooltip-content" // Added class name here
             }, text ? text.split('\n').map(function (line, index) { return (React.createElement("span", { key: index },
                line,
                index < text.split('\n').length - 1 && React.createElement("br", null))); }) : null),
            React.createElement("svg", { style: {
                    position: 'fixed',
                    left: tooltipPosition.left,
                    top: tooltipPosition.top + 5,
                    transform: 'translate(-50%, 0)',
                    fill: '#efefef',
                    zIndex: 1001 // Ensuring it's above the tooltip box
                }, width: "20", height: "10", viewBox: "0 0 20 10" },
                React.createElement("polygon", { points: "0,0 20,0 10,10" }))), document.body)));
};
export default Tooltip;
