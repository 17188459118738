var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-toastify";
import { toastConfig, months } from "../../shared/constants";
import arrowRight from "../../assets/icons/grayArrowRightIcon.png";
import arrowRightSelected from "../../assets/icons/orangeArrowRightIcon.png";
import arrowLeft from "../../assets/icons/grayArrowLeftIcon.png";
import arrowLeftSelected from "../../assets/icons/orangeArrowLeftIcon.png";
import { getPreviousMonth, getNextMonth } from "../../shared/utils";
import "./DateTransitioner.scss";
var DateTransitioner = function (props) {
    var defaultMonth = props.defaultMonth, defaultYear = props.defaultYear, redirectionPath = props.redirectionPath, minYear = props.minYear, maxYear = props.maxYear;
    var currentDate = new Date();
    var navigate = useNavigate();
    var _a = useState(defaultMonth), monthInput = _a[0], setMonthInput = _a[1];
    var _b = useState(defaultYear), yearInput = _b[0], setYearInput = _b[1];
    var _c = useState(defaultMonth), currMonth = _c[0], setCurrMonth = _c[1];
    var _d = useState(defaultYear), currYear = _d[0], setCurrYear = _d[1];
    var _e = useState(false), leftIsHovered = _e[0], setLeftIsHovered = _e[1];
    var _f = useState(false), rightIsHovered = _f[0], setRightIsHovered = _f[1];
    var yearChangeHandler = function (newValue) {
        var setValue = newValue.replace(/[^0-9]$/, "");
        setYearInput(setValue);
    };
    var yearIsWithinBounds = function (value) {
        var numberedValue = parseInt(value);
        var min = minYear || 2015;
        var max = maxYear || currentDate.getFullYear() + 1;
        return numberedValue >= min && numberedValue <= max;
    };
    var changeDateHandler = function () {
        if (yearInput !== "") {
            if (yearIsWithinBounds(yearInput)) {
                // window.location.href = `${redirectionPath}/${yearInput}/${monthInput}`
                if (yearInput !== currYear || monthInput !== currMonth) {
                    navigate("".concat(redirectionPath, "/").concat(yearInput, "/").concat(monthInput));
                }
                else {
                    toast("Ya se encuentra en ".concat(getMonthName(currMonth), " del ").concat(currYear, "."), __assign(__assign({}, toastConfig), { type: "warning" }));
                }
            }
            else {
                toast("El a\u00F1o debe estar entre ".concat(minYear || 2015, " y ").concat(maxYear || currentDate.getFullYear()), __assign(__assign({}, toastConfig), { type: "warning" }));
            }
        }
        else {
            toast('Debe ingresar un año.', __assign(__assign({}, toastConfig), { type: "warning" }));
        }
    };
    var getMonthName = function (monNum) {
        var _a;
        return (_a = months.find(function (monObj) { return monObj.value === monNum; })) === null || _a === void 0 ? void 0 : _a.name;
    };
    var getPreviousMonthYear = function () {
        if (getPreviousMonth(monthInput) === "12") { // Previous year
            var returnValue = "".concat(parseInt(defaultYear) - 1);
            return yearIsWithinBounds(returnValue) ? returnValue : defaultYear;
        }
        else {
            return defaultYear; // It is still in current year
        }
    };
    var getNextMonthYear = function () {
        if (getNextMonth(monthInput) === "1") { // Next year
            var returnValue = "".concat(parseInt(defaultYear) + 1);
            return yearIsWithinBounds(returnValue) ? returnValue : defaultYear;
        }
        else {
            return defaultYear; // It is still in current year
        }
    };
    var enterKeyHandler = function (ev) {
        if (ev.key === "Enter") {
            changeDateHandler();
        }
    };
    useEffect(function () {
        setCurrMonth(defaultMonth);
        setCurrYear(defaultYear);
        setMonthInput(defaultMonth);
        setYearInput(defaultYear);
    }, [defaultMonth, defaultYear]);
    return (React.createElement("div", { className: "dateTransContainer" },
        React.createElement("div", { className: "dateTransRowContainer" },
            React.createElement("div", null, "Mes"),
            React.createElement("select", { className: "dateTransDropdown", value: monthInput, onChange: function (ev) { return setMonthInput(ev.target.value); } }, months.map(function (month, monthInd) {
                return (React.createElement("option", { key: "".concat(month.name, "-").concat(monthInd), value: month.value }, month.name));
            })),
            React.createElement("div", null, "del"),
            React.createElement("input", { className: "dateTransInput", value: yearInput, onChange: function (ev) { return yearChangeHandler(ev.target.value); }, placeholder: "YYYY", onKeyDown: function (ev) { return enterKeyHandler(ev); } }),
            React.createElement("button", { className: "dateTransFilledButton", onClick: function () { return changeDateHandler(); } }, "Cambiar Fecha")),
        React.createElement("div", { className: "dateTransRowContainer" },
            React.createElement("div", { onMouseEnter: function () { return setLeftIsHovered(true); }, onMouseLeave: function () { return setLeftIsHovered(false); } },
                React.createElement(Link, { to: "".concat(redirectionPath, "/").concat(getPreviousMonthYear(), "/").concat(getPreviousMonth(monthInput)), className: "dateTransButton" },
                    React.createElement("img", { src: leftIsHovered ? arrowLeftSelected : arrowLeft, style: { marginRight: "0.5vw" } }),
                    "Mes Anterior")),
            React.createElement("div", { className: "dateTransCurrentMonth" }, "".concat(getMonthName(currMonth), ", ").concat(currYear)),
            React.createElement("div", { onMouseEnter: function () { return setRightIsHovered(true); }, onMouseLeave: function () { return setRightIsHovered(false); } },
                React.createElement(Link, { to: "".concat(redirectionPath, "/").concat(getNextMonthYear(), "/").concat(getNextMonth(monthInput)), className: "dateTransButton" },
                    "Mes Siguiente",
                    React.createElement("img", { src: rightIsHovered ? arrowRightSelected : arrowRight, style: { marginLeft: "0.5vw" } }))))));
};
export default DateTransitioner;
