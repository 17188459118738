var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import searchIcon from "../../../assets/icons/searchIcon.png";
import { arrayOrderByProperty, formatDateToDatetime } from "../../../shared/utils";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import "./AdminActivity.scss";
import { DataTypes, activityActionTypes, toastConfig } from "../../../shared/constants";
import FilterBar from "../../../components/FilterBar/FilterBar";
import { getActivityLogs } from "../../../services/clientsService";
import { useSelector } from "react-redux";
import { loggedUser } from "../../../UserContext/UserReducer";
import { toast } from "react-toastify";
var AdminActivity = function () {
    var userData = useSelector(loggedUser);
    var cols = [
        {
            display: "ID",
            name: "activity_logs_id",
            onClickEvent: function () { orderActivities("id"); },
        },
        {
            display: "Acción",
            name: "action",
            onClickEvent: function () { orderActivities("action"); },
        },
        {
            display: "Descripción",
            name: "description",
            onClickEvent: function () { orderActivities("description"); },
        },
        {
            display: "Responsable",
            name: "responsible",
            onClickEvent: function () { orderActivities("responsible"); },
        },
        {
            display: "Fecha",
            name: "date",
            onClickEvent: function () { orderActivities("date"); },
        },
    ];
    var _a = useState(""), searchText = _a[0], setSearchText = _a[1];
    var _b = useState([]), activityList = _b[0], setActivityList = _b[1];
    var _c = useState([]), filteredActivityList = _c[0], setFilteredActivityList = _c[1];
    var _d = useState(false), dataFetched = _d[0], setDataFetched = _d[1];
    var _e = useState(false), shouldResetFilters = _e[0], setShouldResetFilters = _e[1];
    var _f = useState(false), filtersAreActive = _f[0], setFiltersAreActive = _f[1];
    var orderActivities = function (property) {
        var newArray = __spreadArray([], activityList, true);
        arrayOrderByProperty(newArray, property);
        setFilteredActivityList(newArray);
    };
    var searchHandler = function (ev) {
        setSearchText(ev.target.value);
        var searchVal = ev.target.value.toLowerCase();
        if (searchVal === "") {
            setFilteredActivityList(activityList);
            setShouldResetFilters(true);
        }
        else {
            var filteredResults = (filtersAreActive ? filteredActivityList : activityList).filter(function (activity) { return activity.action.toLowerCase().includes(searchVal)
                || "".concat(activity.activity_logs_id).toLowerCase().includes(searchVal)
                || activity.date.toLowerCase().includes(searchVal)
                || activity.responsible.toLowerCase().includes(searchVal); });
            setFilteredActivityList(filteredResults);
        }
    };
    var getActivityRows = function (allActivities) {
        return allActivities.map(function (act, ind) {
            return {
                activity_logs_id: act.activity_logs_id,
                action: act.action,
                description: act.description,
                responsible: act.responsible,
                date: formatDateToDatetime(new Date(act.date))
            };
        });
    };
    var initData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var activityData;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getActivityLogs(userData.idToken)];
                case 1:
                    activityData = _a.sent();
                    if (activityData === null || activityData === void 0 ? void 0 : activityData.error) {
                        toast(activityData.message ? activityData.message : 'Error de servidor', __assign(__assign({}, toastConfig), { type: 'error' }));
                    }
                    else {
                        setActivityList(activityData);
                        setFilteredActivityList(activityData);
                    }
                    setDataFetched(true);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (activityList.length === 0) {
            initData();
        }
    }, []);
    return (React.createElement("div", { className: "adminActivitiesContainer" },
        React.createElement("div", { className: "adminActivitiesToolbar" },
            React.createElement("div", { className: "adminActivitiesTitle" }, "Registro de Actividad")),
        React.createElement("div", { className: "adminActivitiesToolbar" },
            React.createElement("div", { className: "adminActivitiesToolbarLeft" },
                React.createElement(FilterBar, { keyList: [
                        {
                            keyName: "action",
                            displayName: "Acción",
                            dataType: DataTypes.String,
                            options: activityActionTypes,
                        },
                        {
                            keyName: "description",
                            displayName: "Descripción",
                            dataType: DataTypes.String,
                        },
                        {
                            keyName: "responsible",
                            displayName: "Responsable",
                            dataType: DataTypes.String,
                        },
                        {
                            keyName: "date",
                            displayName: "Fecha",
                            dataType: DataTypes.Datetime,
                        },
                    ], dataToFilter: filteredActivityList, onFilter: setFilteredActivityList, originalData: activityList, resetFilters: shouldResetFilters, setResetFilters: setShouldResetFilters, setFiltersActive: setFiltersAreActive })),
            React.createElement("div", { className: "adminActivitiesToolbarRight" },
                React.createElement("div", { className: "adminActivitiesSearchbar" },
                    React.createElement("img", { src: searchIcon, alt: "Search" }),
                    React.createElement("input", { placeholder: "Buscar por id, acci\u00F3n, responsable o fecha...", onChange: function (ev) { return searchHandler(ev); } })))),
        filteredActivityList.length > 0 &&
            React.createElement(Table, { columns: cols, rows: getActivityRows(filteredActivityList), width: "95vw", paginate: true }),
        filteredActivityList.length === 0 && searchText !== ""
            ? React.createElement("div", { className: "adminActivitiesNoResults" }, "No se encontraron resultados.")
            : filteredActivityList.length === 0 && !dataFetched
                ?
                    React.createElement("div", { className: "adminActivitiesNoResults" },
                        React.createElement(Loader, null))
                : filteredActivityList.length === 0 &&
                    React.createElement("div", { className: "adminActivitiesNoResults" }, "No se encontraron resultados.")));
};
export default AdminActivity;
