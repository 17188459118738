var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useEffect, useState } from "react";
import "./Table.scss";
var Table = function (props) {
    var columns = props.columns, rows = props.rows, paginate = props.paginate, width = props.width, tableTitle = props.tableTitle, tableContrastColor = props.tableContrastColor, tableContrastTextColor = props.tableContrastTextColor, showBorders = props.showBorders, tableRowPadding = props.tableRowPadding;
    var _a = useState(1), currentPage = _a[0], setCurrentPage = _a[1];
    var _b = useState('10'), resPerPage = _b[0], setResPerPage = _b[1];
    var _c = useState([]), displayRows = _c[0], setDisplayRows = _c[1];
    var _d = useState(1), maxPages = _d[0], setMaxPages = _d[1];
    var calcPagination = function (newCurPage) {
        var numberedResPerPage = parseInt(resPerPage);
        var currPage = newCurPage || currentPage;
        // Initial index = (currentPage - 1) * resPerPage
        var initIndex = (currPage - 1) * numberedResPerPage;
        // End index = (currentPage - 1) * resPerPage + resPerPage
        var endIndex = (currPage - 1) * numberedResPerPage + numberedResPerPage;
        if (rows.length > endIndex) {
            setDisplayRows(rows.slice(initIndex, endIndex));
            setMaxPages(Math.ceil(rows.length / numberedResPerPage));
        }
        else {
            setDisplayRows(rows.slice(initIndex));
            setMaxPages(Math.ceil(rows.length / numberedResPerPage));
        }
    };
    useEffect(function () {
        if (rows.length > 0 && paginate) {
            calcPagination();
        }
        else if (displayRows.length === 0 && !paginate) {
            setDisplayRows(rows);
        }
    }, [rows, currentPage]);
    useEffect(function () {
        if (rows.length > 0 && paginate) {
            setCurrentPage(1);
            calcPagination(1);
        }
    }, [resPerPage]);
    return (React.createElement("table", { style: {
            width: width,
        } },
        React.createElement("colgroup", null, columns.map(function (column, index) {
            return React.createElement("col", { key: "coldef-".concat(index), style: column.width ? { width: "".concat(column.width, "%") } : undefined });
        })),
        React.createElement("thead", { className: "tableHeader" },
            tableTitle &&
                React.createElement("tr", null,
                    React.createElement("th", { className: "tableHeaderRow tableHeaderRowFirstAndLast", colSpan: columns.length, style: { backgroundColor: tableContrastColor, borderColor: tableContrastColor, color: tableContrastTextColor } }, tableTitle)),
            React.createElement("tr", null, columns.map(function (column, index) { return (React.createElement("th", { key: "coltab-".concat(index), className: "tableHeaderRow \n                ".concat(index === 0 && columns.length !== 1 ? "tableHeaderRowFirst" : "", " \n                ").concat(index === (columns.length - 1) && columns.length !== 1 ? "tableHeaderRowLast" : "", " \n                ").concat(columns.length === 1 ? "tableHeaderRowFirstAndLast" : ""), onClick: column.onClickEvent ? function () { return column.onClickEvent(); } : undefined, style: { backgroundColor: tableContrastColor, borderColor: tableContrastColor, color: tableContrastTextColor } }, column.display)); }))),
        React.createElement("tbody", { className: "tableBody", style: { overflowY: "scroll" } }, displayRows.map(function (row, rowIndex) { return (React.createElement("tr", { key: "rowtab-".concat(rowIndex), className: row.onClick ? "tableSelectableRow" : undefined }, columns.map(function (column, columnIndex) { return (React.createElement("td", { key: "rowcoltab-".concat(columnIndex), className: "tableRow", onClick: row.onClick && !column.avoidClicksForRow ? function () { return row.onClick(); } : undefined, style: row.customStyles && row.customStyles[column.name] ? __assign({ cursor: row.onClick ? "pointer" : undefined, borderLeft: showBorders ? "2px solid #CDCDCD" : undefined, borderRight: showBorders ? "2px solid #CDCDCD" : undefined, padding: tableRowPadding, textAlign: column.textAlign, fontWeight: column.fontWeight, color: column.color }, row.customStyles[column.name]) :
                {
                    cursor: row.onClick ? "pointer" : undefined,
                    borderLeft: showBorders ? "2px solid #CDCDCD" : undefined,
                    borderRight: showBorders ? "2px solid #CDCDCD" : undefined,
                    padding: tableRowPadding,
                    textAlign: column.textAlign,
                    fontWeight: column.fontWeight,
                    color: column.color,
                } }, row[column.name])); }))); })),
        paginate &&
            React.createElement("tfoot", null,
                React.createElement("tr", null,
                    React.createElement("td", { className: "tableFooter", colSpan: columns.length },
                        React.createElement("div", { className: "tableFooterContainer" },
                            React.createElement("div", { className: "tableFooterLeft" },
                                React.createElement("button", { className: "tableFilledButton", onClick: function () {
                                        if (currentPage > 1) {
                                            setCurrentPage(currentPage - 1);
                                        }
                                    }, disabled: currentPage === 1 }, "Anterior")),
                            React.createElement("div", { className: "tableFooterMiddle" },
                                React.createElement("div", { className: "tablePageReview" },
                                    React.createElement("div", { className: "tableResPerPage" },
                                        React.createElement("div", null, "Resultados por p\u00E1gina"),
                                        React.createElement("select", { className: "tableDropdown", value: resPerPage, onChange: function (ev) { return setResPerPage(ev.target.value); } },
                                            React.createElement("option", { value: '10' }, "10"),
                                            React.createElement("option", { value: '20' }, "20"),
                                            React.createElement("option", { value: '50' }, "50"),
                                            React.createElement("option", { value: '100' }, "100"))),
                                    React.createElement("div", null, "P\u00E1gina ".concat(currentPage, " de ").concat(maxPages)))),
                            React.createElement("div", { className: "tableFooterRight" },
                                React.createElement("button", { className: "tableFilledButton", onClick: function () {
                                        if (currentPage < maxPages) {
                                            setCurrentPage(currentPage + 1);
                                        }
                                    }, disabled: currentPage === maxPages }, "Siguiente"))))))));
};
export default Table;
