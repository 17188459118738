var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from "react";
import "./NewRetentionPopup.scss";
import { toast } from "react-toastify";
import { retenTypes, toastConfig } from "../../shared/constants";
import { useSelector } from "react-redux";
import { loggedUser } from "../../UserContext/UserReducer";
import { isParseableToFloat } from "../../shared/utils";
import { createRetention } from "../../services/clientsService";
var NewRetentionPopup = function (props) {
    var openState = props.openState, setIsOpen = props.setIsOpen, refreshData = props.refreshData, params = props.params;
    var userData = useSelector(loggedUser);
    var popupRef = useRef(null);
    var _a = useState(""), docIdentifier = _a[0], setDocIdentifier = _a[1];
    var _b = useState("3"), typeInput = _b[0], setTypeInput = _b[1];
    var _c = useState(""), dateInput = _c[0], setDateInput = _c[1];
    var _d = useState(""), docNitInput = _d[0], setDocNitInput = _d[1];
    var _e = useState(""), nameInput = _e[0], setNameInput = _e[1];
    var _f = useState(""), totalInput = _f[0], setTotalInput = _f[1];
    var validateInputs = function () {
        var inputsAreValid = true;
        if (dateInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar una fecha", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (docIdentifier === "") {
            inputsAreValid = false;
            toast("Debe ingresar un número de constancia", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (docNitInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar un n\u00FAmero de NIT", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (nameInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar un nombre", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (totalInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar un total", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        else if (!isParseableToFloat(totalInput)) {
            inputsAreValid = false;
            toast("El total debe ser un número", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        return inputsAreValid;
    };
    var createNewRetention = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newRetentionResp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateInputs()) return [3 /*break*/, 2];
                    return [4 /*yield*/, createRetention(userData.idToken, docIdentifier, params.nit, parseFloat(totalInput), dateInput, typeInput === "" ? 3 : parseInt(typeInput), nameInput, docNitInput)];
                case 1:
                    newRetentionResp = _a.sent();
                    // Notify success or error
                    if (newRetentionResp.error) {
                        toast(newRetentionResp.message ? newRetentionResp.message : "Ocurrió un error, por favor intente de nuevo en un momento", __assign(__assign({}, toastConfig), { type: "error", position: "bottom-center" }));
                    }
                    else {
                        toast("Documento creado exitosamente", __assign(__assign({}, toastConfig), { type: "success", position: "bottom-center" }));
                        // Clear inputs to enter new document
                        clearInputs();
                        refreshData();
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var clearInputs = function () {
        setDocIdentifier("");
        setDateInput("");
        setNameInput("");
        setDocNitInput("");
        setTotalInput("");
    };
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                // Clicked outside the popup
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return function () {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []); // Run the effect only once when the component mounts
    return (openState
        ?
            React.createElement("div", { className: "newRetentionPopupContainer", ref: popupRef },
                React.createElement("div", { className: "newRetentionPopupHeader" }, "Crear Retenci\u00F3n"),
                React.createElement("div", { className: "newRetentionPopupBody" },
                    React.createElement("div", { className: "newRetentionPopupInputContainer", style: { marginTop: "0.5vw" } },
                        React.createElement("div", { className: "newRetentionPopupInputLeft" }, "Tipo"),
                        React.createElement("div", { className: "newRetentionPopupInputRight" },
                            React.createElement("select", { className: "newRetentionPopupDropdown", value: typeInput, onChange: function (ev) { return setTypeInput(ev.target.value); } }, retenTypes.map(function (opt, optInd) {
                                return (React.createElement("option", { key: "newRetenTypeOpt-".concat(opt.id, "-").concat(optInd), value: opt.id }, opt.name));
                            })))),
                    React.createElement("div", { className: "newRetentionPopupInputContainer" },
                        React.createElement("div", { className: "newRetentionPopupInputLeft" }, "Fecha"),
                        React.createElement("div", { className: "newRetentionPopupInputRight" },
                            React.createElement("input", { className: "newRetentionPopupTransInput", type: "date", value: dateInput, onChange: function (ev) { setDateInput(ev.target.value); } }))),
                    React.createElement("div", { className: "newRetentionPopupInputContainer" },
                        React.createElement("div", { className: "newRetentionPopupInputLeft" }, "Constancia"),
                        React.createElement("div", { className: "newRetentionPopupInputRight" },
                            React.createElement("input", { className: "newRetentionPopupTransInput", placeholder: "000000000", value: docIdentifier, onChange: function (ev) { setDocIdentifier(ev.target.value); } }))),
                    React.createElement("div", { className: "newRetentionPopupInputContainer" },
                        React.createElement("div", { className: "newRetentionPopupInputLeft" }, "NIT"),
                        React.createElement("div", { className: "newRetentionPopupInputRight" },
                            React.createElement("input", { className: "newRetentionPopupTransInput", placeholder: "000000000", value: docNitInput, onChange: function (ev) { return setDocNitInput(ev.target.value); } }))),
                    React.createElement("div", { className: "newRetentionPopupInputContainer" },
                        React.createElement("div", { className: "newRetentionPopupInputLeft" }, "Nombre"),
                        React.createElement("div", { className: "newRetentionPopupInputRight" },
                            React.createElement("input", { className: "newRetentionPopupTransInput", placeholder: "Lorena Ipsum", value: nameInput, onChange: function (ev) { return setNameInput(ev.target.value); } }))),
                    React.createElement("div", { className: "newRetentionPopupInputContainer" },
                        React.createElement("div", { className: "newRetentionPopupInputLeft" }, "Total"),
                        React.createElement("div", { className: "newRetentionPopupInputRight" },
                            React.createElement("input", { className: "newRetentionPopupTransInput", value: totalInput, placeholder: "0.00", onChange: function (ev) { return setTotalInput(ev.target.value); } })))),
                React.createElement("div", { className: "newRetentionPopupFooter" },
                    React.createElement("button", { className: "newRetentionPopupFilledButton newRetentionPopupDanger", onClick: function () { return setIsOpen(false); } }, "Cerrar"),
                    React.createElement("button", { className: "newRetentionPopupFilledButton newRetentionPopupDanger", onClick: function () { return clearInputs(); } }, "Limpiar"),
                    React.createElement("button", { className: "newRetentionPopupFilledButton newRetentionPopupSuccess", onClick: function () { return createNewRetention(); } }, "Guardar y nuevo")))
        :
            React.createElement(React.Fragment, null));
};
export default NewRetentionPopup;
