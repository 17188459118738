import React from "react";
import "./InvalidPassword.scss";
;
var InvalidPassword = function (props) {
    var title = props.title, icon = props.icon, vertical = props.vertical, onClick = props.onClick;
    return (React.createElement("div", { className: "warningPasswordContainer", style: {
            flexDirection: vertical ? "column" : "row"
        }, tabIndex: 0, onClick: function () {
            if (onClick)
                onClick();
        } },
        icon &&
            React.createElement("div", { className: "warningPasswordImage" },
                React.createElement("img", { src: icon, alt: title })),
        React.createElement("div", { className: "warningPasswordTitle" }, title)));
};
export default InvalidPassword;
