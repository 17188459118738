var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { toastConfig } from "../../../shared/constants";
import { Roles } from "../../../shared/constants";
import "./CreateUser.scss";
import { addUserToGroup, createUser } from "../../../services/auth";
import { formatCompanyName } from "../../../shared/utils";
import { useSelector } from "react-redux";
import { loggedUser } from "../../../UserContext/UserReducer";
import { getCompanies } from "../../../services/clientsService";
import Loader from "../../../components/Loader/Loader";
var CreateUser = function () {
    var navigate = useNavigate();
    var userData = useSelector(loggedUser);
    var _a = useState(""), userInput = _a[0], setUserInput = _a[1];
    var _b = useState(""), nameInput = _b[0], setNameInput = _b[1];
    var _c = useState(""), companyInput = _c[0], setCompanyInput = _c[1];
    var _d = useState(""), emailInput = _d[0], setEmailInput = _d[1];
    var _e = useState(Roles.User), roleInput = _e[0], setRoleInput = _e[1];
    var _f = useState([]), companies = _f[0], setCompanies = _f[1];
    var _g = useState(false), isCreatingUser = _g[0], setIsCreatingUser = _g[1];
    var validateFields = function () {
        var fieldsAreValid = true;
        if (userInput === "") {
            fieldsAreValid = false;
            toast("El usuario no puede estar vacío.", __assign(__assign({}, toastConfig), { type: "warning" }));
        }
        if (nameInput === "") {
            fieldsAreValid = false;
            toast("El nombre no puede estar vacío.", __assign(__assign({}, toastConfig), { type: "warning" }));
        }
        if (companyInput === "") {
            fieldsAreValid = false;
            toast("La empresa no puede estar vacía.", __assign(__assign({}, toastConfig), { type: "warning" }));
        }
        if (emailInput === "") {
            fieldsAreValid = false;
            toast("El correo no puede estar vacío.", __assign(__assign({}, toastConfig), { type: "warning" }));
        }
        else {
            var emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
            if (!emailRegex.test(emailInput)) {
                fieldsAreValid = false;
                toast("Debe ingresar un correo válido.", __assign(__assign({}, toastConfig), { type: "warning" }));
            }
        }
        return fieldsAreValid;
    };
    var createNewUser = function () { return __awaiter(void 0, void 0, void 0, function () {
        var fields;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateFields()) return [3 /*break*/, 2];
                    setIsCreatingUser(true);
                    fields = [
                        {
                            Name: "name",
                            Value: nameInput,
                        },
                        {
                            Name: "email",
                            Value: emailInput,
                        },
                        {
                            Name: "email_verified",
                            Value: "true",
                        },
                        {
                            Name: "custom:company",
                            Value: formatCompanyName(companyInput),
                        },
                        {
                            Name: "custom:role",
                            Value: roleInput,
                        },
                    ];
                    return [4 /*yield*/, createUser(userInput, userData.idToken, fields).then(function (resp) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        if (!resp.User) return [3 /*break*/, 2];
                                        return [4 /*yield*/, addUserToGroup(userInput, userData.idToken, roleInput)]; // Add user to corresponding group
                                    case 1:
                                        _a.sent(); // Add user to corresponding group
                                        toast("Usuario creado.", __assign(__assign({}, toastConfig), { type: "success" }));
                                        return [3 /*break*/, 3];
                                    case 2:
                                        toast("Error al crear usuario. Por favor, intentar de nuevo.", __assign(__assign({}, toastConfig), { type: "error" }));
                                        _a.label = 3;
                                    case 3: return [2 /*return*/];
                                }
                            });
                        }); }).catch(function (err) {
                            toast("Error al crear usuario. Por favor, intentar de nuevo.", __assign(__assign({}, toastConfig), { type: "error" }));
                        })];
                case 1:
                    _a.sent();
                    navigate("/admin/users");
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var initData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var compResp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCompanies(userData.idToken)];
                case 1:
                    compResp = _a.sent();
                    if (compResp) {
                        if (Array.isArray(compResp)) {
                            setCompanies(compResp);
                            if (compResp.length > 0) {
                                setCompanyInput(compResp[0].company_id);
                            }
                        }
                        else if (compResp.company_id) {
                            setCompanyInput(compResp.company_id);
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        initData();
    }, []);
    return (React.createElement("div", { className: "createUserContainer" },
        React.createElement("div", { className: "createUserFormContainer" },
            React.createElement("div", { className: "createUserTitle" }, "Crear Usuario"),
            React.createElement("div", { className: "createUserRowContainer" },
                React.createElement("div", { className: "createUserFieldContainer" },
                    React.createElement("div", { className: "createUserFieldTitle" },
                        "Usuario ",
                        React.createElement("b", { style: { color: "#F68218" } }, "*")),
                    React.createElement("input", { className: "createUserFieldInput", placeholder: "usuario1", value: userInput, onChange: function (ev) { return setUserInput(ev.target.value); } })),
                React.createElement("div", { className: "createUserFieldContainer" },
                    React.createElement("div", { className: "createUserFieldTitle" },
                        "Nombre ",
                        React.createElement("b", { style: { color: "#F68218" } }, "*")),
                    React.createElement("input", { className: "createUserFieldInput", placeholder: "Roberto Ejemplar", value: nameInput, onChange: function (ev) { return setNameInput(ev.target.value); } }))),
            React.createElement("div", { className: "createUserRowContainer" },
                React.createElement("div", { className: "createUserFieldContainer" },
                    React.createElement("div", { className: "createUserFieldTitle" },
                        "Empresa ",
                        React.createElement("b", { style: { color: "#F68218" } }, "*")),
                    userData.role === Roles.Superadmin
                        ?
                            React.createElement("select", { className: "createUserFieldDropdown", value: companyInput, onChange: function (ev) { return setCompanyInput(ev.target.value); } }, companies.map(function (comp, ind) {
                                return (React.createElement("option", { key: "comp-opt-".concat(ind), value: comp.company_id }, comp.company_name));
                            }))
                        :
                            React.createElement("input", { className: "createUserFieldInput", placeholder: "Roberto Ejemplar", value: companyInput, disabled: true })),
                React.createElement("div", { className: "createUserFieldContainer" },
                    React.createElement("div", { className: "createUserFieldTitle" },
                        "Correo Electr\u00F3nico ",
                        React.createElement("b", { style: { color: "#F68218" } }, "*")),
                    React.createElement("input", { className: "createUserFieldInput", placeholder: "correo@empresa.com", value: emailInput, onChange: function (ev) { return setEmailInput(ev.target.value); } }))),
            React.createElement("div", { className: "createUserOnlyFieldRowContainer" },
                React.createElement("div", { className: "createUserOnlyFieldContainer" },
                    React.createElement("div", { className: "createUserFieldTitle" },
                        "Rol ",
                        React.createElement("b", { style: { color: "#F68218" } }, "*")),
                    React.createElement("select", { className: "createUserFieldDropdown", value: roleInput, onChange: function (ev) { return setRoleInput(ev.target.value); } }, Object.keys(Roles).map(function (role, ind) {
                        return (userData.role === Roles.Superadmin || (userData.role === Roles.Admin && role !== Roles.Superadmin)) && (React.createElement("option", { key: "role-opt-".concat(ind), value: role }, role));
                    })))),
            isCreatingUser
                ?
                    React.createElement("div", { style: { marginTop: "2vw" } },
                        React.createElement(Loader, null))
                :
                    React.createElement("button", { className: "createUserButton", onClick: function () { return createNewUser(); } }, "Crear"))));
};
export default CreateUser;
