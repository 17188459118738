var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState, useMemo } from "react";
import AccordionTable from "../../components/AccordionTable/AccordionTable";
import TableActions from "../../components/TableActions/TableActions";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { toastConfig, ActionTypes, Actions, popupConfig, months } from "../../shared/constants";
import { downloadBlob, formatCompanyName, formatDate, isParseableToFloat, saveDataToFile } from "../../shared/utils";
import { documentsEdit, updateDocumentItemsTax } from "../../services/clientsService";
import { assignPDF, createCorrelative, deletePDFDocument, getPDFSignedURL, uploadPDFDocument } from "../../services/documentsService";
import searchIcon from "../../assets/icons/searchIcon.png";
import pdfIcon from "../../assets/icons/pdfIcon_white.png";
import excelIcon from "../../assets/icons/excelIcon_white.png";
import "./Bills.scss";
import DiscardAllButton from "../../components/DiscardAllButton/DiscardAllButton";
import invoiceIcon from "../../assets/icons/invoice_icon.png";
import AbsoluteToolTip from "../../components/Tooltip/AbsoluteToolTip";
var Bills = function (props) {
    var _a = useState([]), markedFields = _a[0], setMarkedFields = _a[1];
    var data = props.data, setData = props.setData, addOtherData = props.addOtherData, cols = props.cols, userData = props.userData, nitInfo = props.nitInfo, params = props.params, refreshData = props.refreshData, taxTypeIds = props.taxTypeIds, purchaseGeneratorData = props.purchaseGeneratorData, salesGeneratorData = props.salesGeneratorData, exclusiveGeneratorData = props.exclusiveGeneratorData, excelGeneratorData = props.excelGeneratorData, // Data for generating excel file
    excelDownloadText = props.excelDownloadText, discardedGeneratorData = props.discardedGeneratorData, discardedRegularGenData = props.discardedRegularGenData, generatorDownloadText = props.generatorDownloadText, generatorDownloadedTitle = props.generatorDownloadedTitle, generatorTitle = props.generatorTitle, genInvoice = props.genInvoice, // Folio
    bookWasAlreadyDownloaded = props.bookWasAlreadyDownloaded, // Book was downloaded previously
    setBookWasDownloaded = props.setBookWasDownloaded, expandedRows = props.expandedRows, setExpandedRows = props.setExpandedRows, isPurchase = props.isPurchase, setChildrenColapsed = props.setChildrenColapsed;
    // ###########################################################################
    // Worker for pdf generation
    var pdfWorker = useMemo(
    //@ts-ignore
    function () { return new Worker(new URL("../../workers/pdf.worker.ts", import.meta.url)); }, []);
    // Controlador de eventos para cambio en el checkbox
    var handleCheckboxChange = function (documentId, number) {
        // Verifica si el campo ya está marcado
        var isMarked = markedFields.some(function (field) { return field.documentId === documentId && field.number === number; });
        // Actualiza el estado según corresponda
        if (isMarked) {
            // Si ya está marcado, quítalo del array
            setMarkedFields(markedFields.filter(function (field) { return !(field.documentId === documentId && field.number === number); }));
        }
        else {
            // Si no está marcado, agrégalo al array
            setMarkedFields(__spreadArray(__spreadArray([], markedFields, true), [{ documentId: documentId, number: number }], false));
        }
    };
    var handleGeneratePDF = function () { return __awaiter(void 0, void 0, void 0, function () {
        var dataLength, pagesEstimate;
        var _a;
        return __generator(this, function (_b) {
            dataLength = isPurchase ? (purchaseGeneratorData === null || purchaseGeneratorData === void 0 ? void 0 : purchaseGeneratorData.length) || 0 : (salesGeneratorData === null || salesGeneratorData === void 0 ? void 0 : salesGeneratorData.length) || 0;
            pagesEstimate = Math.floor(dataLength / 74);
            Swal.fire(__assign(__assign({}, popupConfig), { title: "\u00BFDescargar libro de ".concat(isPurchase ? 'Compras' : 'Ventas', "?"), html: "\n        <div><b>NIT:</b> ".concat(params.nit, "</div>\n        <div><b>Mes:</b> ").concat(((_a = months.find(function (elem) { return elem.value === params.month; })) === null || _a === void 0 ? void 0 : _a.name) || params.month, "</div>\n        <div><b>A\u00F1o:</b> ").concat(params.year, "</div>\n        <div><b>Tiempo estimado de descarga:</b> ").concat(((pagesEstimate + 1) * 1.56).toFixed(2), " segundos</div>\n      "), inputLabel: "Por favor seleccione el tipo de descarga", input: dataLength === 0 ? "select" : undefined, inputOptions: { standard: 'Estándar (1 mes)', noMove: "Sin Movimiento (Todos los meses)" }, icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, descargar', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                var pdfData, workerParams, workerRequest, response;
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            if (!result.isConfirmed) return [3 /*break*/, 3];
                            setLoadingGenPDF(true);
                            pdfData = purchaseGeneratorData && (purchaseGeneratorData === null || purchaseGeneratorData === void 0 ? void 0 : purchaseGeneratorData.length) > 0
                                ? purchaseGeneratorData
                                : salesGeneratorData;
                            workerParams = __assign(__assign({}, params), { genInvoice: genInvoice, generatorTitle: generatorTitle, noMovement: result.value === 'noMove' });
                            workerRequest = {
                                dataValues: pdfData,
                                params: workerParams,
                                isPurchase: isPurchase,
                                regimeId: nitInfo.regime_id,
                            };
                            if (!bookWasAlreadyDownloaded) return [3 /*break*/, 1];
                            // Send a message to the Web Worker to start generating the PDF
                            // workerRef.current.postMessage(pdfData);
                            if (window.Worker) {
                                pdfWorker.postMessage(JSON.stringify(workerRequest));
                            }
                            return [3 /*break*/, 3];
                        case 1: return [4 /*yield*/, createCorrelative(userData.idToken, params.nit, params.month, params.year, isPurchase ? 1 : 0, genInvoice, genInvoice + pagesEstimate)];
                        case 2:
                            response = _a.sent();
                            if (response.error) {
                                toast(response.message || "Error de servidor", __assign(__assign({}, toastConfig), { type: 'error' }));
                                setLoadingGenPDF(false);
                            }
                            else {
                                if (setBookWasDownloaded) {
                                    setBookWasDownloaded(true);
                                }
                                // Send a message to the Web Worker to start generating the PDF
                                // workerRef.current.postMessage(pdfData);
                                if (window.Worker) {
                                    pdfWorker.postMessage(JSON.stringify(workerRequest));
                                }
                            }
                            _a.label = 3;
                        case 3: return [2 /*return*/];
                    }
                });
            }); });
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        if (window.Worker) {
            pdfWorker.onmessage = function (e) {
                //@ts-ignore
                downloadBlob(e.data, purchaseGeneratorData
                    ? "".concat(generatorDownloadedTitle || "LibroDeCompras", "_").concat(params.nit, "_").concat(params.month, "_").concat(params.year, ".pdf")
                    : "".concat(generatorDownloadedTitle || "LibroDeVentas", "_").concat(params.nit, "_").concat(params.month, "_").concat(params.year, ".pdf"));
                setLoadingGenPDF(false);
            };
        }
    }, [pdfWorker]);
    // ###########################################################################
    var _b = useState(""), searchVal = _b[0], setSearchVal = _b[1];
    var _c = useState("all"), filterType = _c[0], setFilterType = _c[1];
    var _d = useState([]), filterTypesList = _d[0], setFilterTypesList = _d[1];
    // const [expandedRows, setExpandedRows] = useState<boolean[]>([]);
    var _e = useState([]), localData = _e[0], setLocalData = _e[1];
    var _f = useState([]), filteredData = _f[0], setFilteredData = _f[1];
    var _g = useState(false), loadingGenPDF = _g[0], setLoadingGenPDF = _g[1];
    var _h = useState({
        "1": [],
        "2": [],
        "3": [],
        "4": [],
        "5": [],
        "6": [],
    }), taxChanges = _h[0], setTaxChanges = _h[1];
    var formatter = new Intl.NumberFormat('es-GT', {
        style: 'currency',
        currency: 'GTQ',
        maximumFractionDigits: 2,
    });
    var changeItemTaxTypeId = function (doc_id, item_id, currData, newTaxId) {
        return currData.map(function (doc) {
            if (doc_id === doc.document_id) {
                return __assign(__assign({}, doc), { items: doc.items.map(function (item) {
                        if (item.item_id === item_id) {
                            return __assign(__assign({}, item), { tax_type_id: newTaxId });
                        }
                        else {
                            return item;
                        }
                    }) });
            }
            else {
                return doc;
            }
        });
    };
    var addTaxChange = function (doc_id, item_id, currData, newTaxId) {
        var currDoc = currData.find(function (doc) { return doc.document_id === doc_id; });
        if (currDoc) {
            var currItem = currDoc.items.find(function (it) { return it.item_id === item_id; });
            if (currItem) {
                var currChanges_1 = __assign({}, taxChanges);
                Object.keys(currChanges_1).forEach(function (key, ind) {
                    if (key === "".concat(newTaxId)) {
                        // Check if change is new or if it is the original state
                        var origDoc = data.find(function (doc) { return doc.document_id === doc_id; });
                        if (origDoc) {
                            var origItem = origDoc.items.find(function (it) { return it.item_id === item_id; });
                            if (origItem) {
                                if (origItem.tax_type_id !== newTaxId) { // Only add change if original id is different from new one
                                    currChanges_1[key].push(item_id);
                                }
                            }
                        }
                    }
                    else if (currChanges_1[key].includes(item_id)) {
                        currChanges_1[key] = currChanges_1[key].filter(function (elem) { return elem !== item_id; });
                    }
                });
                setTaxChanges(currChanges_1);
            }
        }
    };
    var taxIsInChanges = function (item_id) {
        var isPresent = false;
        var currChanges = __assign({}, taxChanges);
        Object.keys(currChanges).forEach(function (key, ind) {
            if (currChanges[key].includes(item_id)) {
                isPresent = true;
            }
        });
        return isPresent;
    };
    var calcTotalColumn = function (docIdToCalc, columnName) {
        var total = 0;
        var objToIter;
        if (isPurchase) {
            if (purchaseGeneratorData) {
                objToIter = purchaseGeneratorData.find(function (elem) { return elem.docId === docIdToCalc; });
            }
            else if (discardedRegularGenData) {
                objToIter = discardedRegularGenData.find(function (elem) { return elem.docId === docIdToCalc; });
            }
        }
        else { // Sales
            if (salesGeneratorData) {
                objToIter = salesGeneratorData.find(function (elem) { return elem.docId === docIdToCalc; });
            }
            else if (discardedRegularGenData) {
                objToIter = discardedRegularGenData.find(function (elem) { return elem.docId === docIdToCalc; });
            }
        }
        // Check if value exists in current row
        // @ts-ignore
        if (objToIter && objToIter[columnName]) {
            // @ts-ignore
            total += objToIter[columnName];
        }
        return total > 0 ? formatter.format(total) : "";
    };
    // Editar funcion para recibir un array
    var removeDataRow = function (toRemoveIds, setDiscarded) {
        var rowsToRemove = data.filter(function (elem) { return toRemoveIds.includes(elem.document_id); });
        var newData = data.filter(function (elem) { return !toRemoveIds.includes(elem.document_id); });
        if (newData.length < data.length) {
            // Actualiza los campos marcados excluyendo los que se van a eliminar
            setMarkedFields(markedFields.filter(function (field) { return !toRemoveIds.includes(field.documentId); }));
            setData(newData);
            if (setDiscarded === 0 || setDiscarded === 1) {
                rowsToRemove.forEach(function (rowToRemove) {
                    rowToRemove.is_discarded = setDiscarded;
                    addOtherData(Array(rowToRemove));
                });
            }
        }
    };
    var getDataRows = function (currentData) {
        var finalRows = [];
        currentData.forEach(function (currentPurchase, ind) {
            // console.log(`Current purchase:\n${JSON.stringify(currentPurchase)}`)
            var finalColValues = [];
            var finalItemsValues = [];
            var rawItems = currentPurchase.items;
            // Parent rows
            cols.forEach(function (col, colInd) {
                if (colInd === 0) { // First column will always have the down icon
                    finalColValues.push({
                        elem: React.createElement("input", { type: "checkbox", className: "custom-checkbox", style: { transform: "scale(1.7)" }, onChange: function () { return handleCheckboxChange(currentPurchase.document_id, currentPurchase.number); } }),
                        widthPercent: col.widthPercent,
                        fontSize: col.fontSize,
                        avoidClickExpansion: true
                    });
                }
                else if (colInd === 1) { // Second column will always have the pdf actions
                    finalColValues.push({
                        elem: React.createElement(TableActions, { actionList: [
                                {
                                    action: Actions.PDF,
                                    titleText: 'Ver PDF',
                                    altText: 'ver',
                                    type: currentPurchase.pdf_url ? ActionTypes.Link : ActionTypes.None,
                                    navigationURL: currentPurchase.pdf_url,
                                    keyId: "".concat(ind, "-").concat(colInd),
                                },
                                {
                                    action: Actions.Delete,
                                    altText: 'eliminar',
                                    titleText: 'Eliminar Archivo',
                                    type: currentPurchase.pdf_url ? ActionTypes.Button : ActionTypes.None,
                                    onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Quieres eliminar el PDF de esta factura?', html: "\n                        <div><b>Nombre</b> ".concat(currentPurchase.name, "</div>\n                        <div><b>Serie</b> ").concat(currentPurchase.serie, "</div>\n                        <div><b>No. </b>").concat(currentPurchase.number, "</div>\n                        <div><b>Fecha</b> ").concat(formatDate(new Date(currentPurchase.date.replace(/-/g, '\/').replace(/T.+/, ''))), "</div>\n                        <div><b>Total</b> ").concat(formatter.format(currentPurchase.total), "</div>\n                      "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, eliminar', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                                var newCurrPurchaseCopy, newDataState, newFilteredDataState, replaceIndex, filteredReplaceIndex;
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            if (!result.isConfirmed) return [3 /*break*/, 2];
                                                            return [4 /*yield*/, deletePDFDocument(userData.idToken, currentPurchase.document_id)];
                                                        case 1:
                                                            _a.sent();
                                                            newCurrPurchaseCopy = currentPurchase;
                                                            newCurrPurchaseCopy.pdf_url = "";
                                                            newDataState = __spreadArray([], localData, true);
                                                            newFilteredDataState = __spreadArray([], filteredData, true);
                                                            replaceIndex = newDataState.indexOf(currentPurchase);
                                                            filteredReplaceIndex = newFilteredDataState.indexOf(currentPurchase);
                                                            if (replaceIndex > -1) {
                                                                newDataState[replaceIndex] = newCurrPurchaseCopy;
                                                            }
                                                            if (filteredReplaceIndex > -1) {
                                                            }
                                                            setLocalData(newDataState);
                                                            toast('PDF eliminado exitosamente', __assign(__assign({}, toastConfig), { type: 'success' }));
                                                            _a.label = 2;
                                                        case 2: return [2 /*return*/];
                                                    }
                                                });
                                            }); });
                                            return [2 /*return*/];
                                        });
                                    }); },
                                    keyId: "".concat(ind, "-").concat(colInd),
                                },
                                {
                                    action: Actions.UploadFile,
                                    altText: 'subir',
                                    titleText: 'Subir Archivo',
                                    type: currentPurchase.pdf_url === null || currentPurchase.pdf_url === "" ? ActionTypes.FileExplorer : ActionTypes.None,
                                    onFileChange: function (ev) { return __awaiter(void 0, void 0, void 0, function () {
                                        var file, fileExtension, signedResp, fileUploadResp, assignResp, newCurrPurchaseCopy, newDataState, replaceIndex;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    file = ev.target.files ? ev.target.files[0] : null;
                                                    if (!file) return [3 /*break*/, 4];
                                                    fileExtension = file.name.split('.').pop();
                                                    return [4 /*yield*/, getPDFSignedURL(userData.idToken, currentPurchase.document_id, fileExtension)];
                                                case 1:
                                                    signedResp = _a.sent();
                                                    if (!signedResp) return [3 /*break*/, 4];
                                                    return [4 /*yield*/, uploadPDFDocument(signedResp.signedURL, file)];
                                                case 2:
                                                    fileUploadResp = _a.sent();
                                                    return [4 /*yield*/, assignPDF(userData.idToken, currentPurchase.document_id, fileExtension)];
                                                case 3:
                                                    assignResp = _a.sent();
                                                    newCurrPurchaseCopy = currentPurchase;
                                                    newCurrPurchaseCopy.pdf_url = (assignResp === null || assignResp === void 0 ? void 0 : assignResp.message) ? "" : (assignResp === null || assignResp === void 0 ? void 0 : assignResp.pdf_url) ? assignResp.pdf_url : "";
                                                    newDataState = __spreadArray([], localData, true);
                                                    replaceIndex = newDataState.indexOf(currentPurchase);
                                                    if (replaceIndex > -1) {
                                                        newDataState[replaceIndex] = newCurrPurchaseCopy;
                                                    }
                                                    setLocalData(newDataState);
                                                    if (assignResp === null || assignResp === void 0 ? void 0 : assignResp.message) { // Ocurrio un error
                                                        toast(assignResp.message, __assign(__assign({}, toastConfig), { type: 'error' }));
                                                    }
                                                    else {
                                                        toast('PDF subido exitosamente', __assign(__assign({}, toastConfig), { type: 'success' }));
                                                    }
                                                    _a.label = 4;
                                                case 4: return [2 /*return*/];
                                            }
                                        });
                                    }); },
                                    keyId: "".concat(ind, "-").concat(colInd),
                                    acceptExtensions: '.pdf',
                                },
                            ] }),
                        widthPercent: col.widthPercent,
                        fontSize: col.fontSize,
                        avoidClickExpansion: col.avoidClickExpansion,
                    });
                }
                else if (colInd === cols.length - 1) { // Last column will always have the bill's actions
                    finalColValues.push({
                        elem: React.createElement(TableActions, { actionList: [
                                {
                                    action: Actions.MoveLeft,
                                    altText: 'mesAnterior',
                                    titleText: 'Mover al Mes Anterior',
                                    keyId: "".concat(ind, "-").concat(colInd),
                                    onClick: function () {
                                        Swal.fire(__assign(__assign({}, popupConfig), { title: (markedFields.length > 1) ? "\u00BFQuieres mover las siguientes ".concat(markedFields.length, " facturas al mes anterior?") : '¿Quieres mover esta factura al mes anterior?', html: "\n                        <div style=\"".concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Nombre</b> ").concat(currentPurchase.name, "</div>\n                        <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Serie</b> ").concat(currentPurchase.serie, "</div>\n                        <div>\n                          <b>No. </b>\n                          <ul>\n                              ").concat((markedFields.length > 1) ? markedFields.map(function (e) { return "<li>".concat(e.number, "</li>"); }).join('') : "<li>".concat(currentPurchase.number, "</li>"), "\n                          </ul>\n                        </div>\n                        <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Fecha</b> ").concat((markedFields.length > 1) ? formatDate(new Date(currentPurchase.date.replace(/-/g, '\/').replace(/T.+/, ''))) : '', "</div>\n                        <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\">\n                            <b>Total</b> ").concat((markedFields.length > 1) ? '' : formatter.format(currentPurchase.total), "\n                        </div>\n                      "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, mover al mes anterior', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                            var idsToRemove, resp;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        if (!result.isConfirmed) return [3 /*break*/, 2];
                                                        idsToRemove = markedFields.map(function (field) { return field.documentId; });
                                                        return [4 /*yield*/, documentsEdit(userData.idToken, "movePreviousMonth", idsToRemove)];
                                                    case 1:
                                                        resp = _a.sent();
                                                        if (resp.error) {
                                                            toast(resp.message || 'No se pudo mover la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                        }
                                                        else if (resp) {
                                                            toast('Se ha movido la factura', __assign(__assign({}, toastConfig), { type: "success" }));
                                                            refreshData();
                                                        }
                                                        else {
                                                            toast('No se pudo mover la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                        }
                                                        _a.label = 2;
                                                    case 2: return [2 /*return*/];
                                                }
                                            });
                                        }); });
                                    },
                                },
                                {
                                    action: Actions.MoveRight,
                                    altText: 'mesSiguiente',
                                    titleText: 'Mover al Mes Siguiente',
                                    keyId: "".concat(ind, "-").concat(colInd),
                                    onClick: function () {
                                        Swal.fire(__assign(__assign({}, popupConfig), { title: (markedFields.length > 1) ? "\u00BFQuieres mover ".concat(markedFields.length, " facturas al mes siguiente?") : "\u00BFQuieres mover esta factura al mes siguiente?", html: "\n                        <div style=\"".concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Nombre</b> ").concat(currentPurchase.name, "</div>\n                        <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Serie</b> ").concat(currentPurchase.serie, "</div>\n                        <div>\n                          <b>No. </b>\n                          <ul>\n                              ").concat((markedFields.length > 1) ? markedFields.map(function (e) { return "<li>".concat(e.number, "</li>"); }).join('') : "<li>".concat(currentPurchase.number, "</li>"), "\n                          </ul>\n                        </div>\n                        <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Fecha</b> ").concat(formatDate(new Date(currentPurchase.date.replace(/-/g, '\/').replace(/T.+/, ''))), "</div>\n                        <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Total</b> ").concat(formatter.format(currentPurchase.total), "</div>\n                      "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, mover al mes siguiente', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                            var idsToRemove, resp;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        if (!result.isConfirmed) return [3 /*break*/, 2];
                                                        idsToRemove = markedFields.map(function (field) { return field.documentId; });
                                                        return [4 /*yield*/, documentsEdit(userData.idToken, "moveNextMonth", idsToRemove)];
                                                    case 1:
                                                        resp = _a.sent();
                                                        if (resp.error) {
                                                            toast(resp.message || 'No se pudo mover la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                        }
                                                        else if (resp) {
                                                            toast('Se ha movido la factura', __assign(__assign({}, toastConfig), { type: "success" }));
                                                            removeDataRow([currentPurchase.document_id]);
                                                            refreshData();
                                                        }
                                                        else {
                                                            toast('No se pudo mover la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                        }
                                                        _a.label = 2;
                                                    case 2: return [2 /*return*/];
                                                }
                                            });
                                        }); });
                                    },
                                },
                                {
                                    action: Actions.Delete,
                                    altText: 'eliminar',
                                    titleText: 'Eliminar',
                                    keyId: "".concat(ind, "-").concat(colInd),
                                    onClick: function () {
                                        Swal.fire(__assign(__assign({}, popupConfig), { title: (markedFields.length > 1) ? "\u00BFSeguro que quieres eliminar ".concat(markedFields.length, " facturas?") : "\u00BFSeguro que quieres eliminar esta factura?", html: "\n                        <div style=\"".concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Nombre</b> ").concat(currentPurchase.name, "</div>\n                        <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Serie</b> ").concat(currentPurchase.serie, "</div>\n                        <div>\n                        <b>No. </b>\n                          <ul>\n                              ").concat((markedFields.length > 1) ? markedFields.map(function (e) { return "<li>".concat(e.number, "</li>"); }).join('') : "<li>".concat(currentPurchase.number, "</li>"), "\n                          </ul>\n                        </div>\n                        <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Fecha</b> ").concat(formatDate(new Date(currentPurchase.date.replace(/-/g, '\/').replace(/T.+/, ''))), "</div>\n                        <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Total</b> ").concat(formatter.format(currentPurchase.total), "</div>\n                      "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, entiendo las consecuencias', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                            var idsToRemove, resp;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        if (result.isConfirmed) {
                                                            // Delete bill
                                                        }
                                                        idsToRemove = markedFields.map(function (field) { return field.documentId; });
                                                        return [4 /*yield*/, documentsEdit(userData.idToken, "delete", idsToRemove)];
                                                    case 1:
                                                        resp = _a.sent();
                                                        if (resp.error) {
                                                            toast(resp.message || 'No se pudo eliminar la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                        }
                                                        else if (resp) {
                                                            toast('Se ha eliminado la factura', __assign(__assign({}, toastConfig), { type: "success" }));
                                                            removeDataRow(idsToRemove);
                                                            refreshData();
                                                        }
                                                        else {
                                                            toast('No se pudo eliminar la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                        }
                                                        return [2 /*return*/];
                                                }
                                            });
                                        }); });
                                    },
                                },
                                {
                                    action: currentPurchase.is_discarded === 1 ? Actions.Return : Actions.Dismiss,
                                    altText: currentPurchase.is_discarded === 1 ? 'restaurar' : 'descartar',
                                    titleText: currentPurchase.is_discarded === 1 ? 'Restaurar Factura' : 'Descartar Factura',
                                    iconWidth: '1.2vw',
                                    onClick: currentPurchase.is_discarded === 1
                                        ? function () {
                                            Swal.fire(__assign(__assign({}, popupConfig), { title: (markedFields.length > 1) ? "\u00BFQuieres restaurar ".concat(markedFields.length, " facturas?") : '¿Quieres restaurar esta factura?', html: "\n                          <div style=\"".concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Nombre</b> ").concat(currentPurchase.name, "</div>\n                          <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Serie</b> ").concat(currentPurchase.serie, "</div>\n                          <div>\n                          <b>No. </b>\n                            <ul>\n                                ").concat((markedFields.length > 1) ? markedFields.map(function (e) { return "<li>".concat(e.number, "</li>"); }).join('') : "<li>".concat(currentPurchase.number, "</li>"), "\n                            </ul>\n                          </div>\n                          <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Fecha</b> ").concat(formatDate(new Date(currentPurchase.date.replace(/-/g, '\/').replace(/T.+/, ''))), "</div>\n                          <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Total</b> ").concat(formatter.format(currentPurchase.total), "</div>\n                        "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, restaurar', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                                var idsToRemove, resp;
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            if (!result.isConfirmed) return [3 /*break*/, 2];
                                                            idsToRemove = markedFields.map(function (field) { return field.documentId; });
                                                            return [4 /*yield*/, documentsEdit(userData.idToken, "restore", idsToRemove)];
                                                        case 1:
                                                            resp = _a.sent();
                                                            if (resp.error) {
                                                                toast(resp.message || 'No se pudo restaurar la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                            }
                                                            else if (resp) {
                                                                toast('Factura restaurada', __assign(__assign({}, toastConfig), { type: "success" }));
                                                                removeDataRow(idsToRemove, 0);
                                                                refreshData();
                                                            }
                                                            else {
                                                                toast('No se pudo restaurar la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                            }
                                                            _a.label = 2;
                                                        case 2: return [2 /*return*/];
                                                    }
                                                });
                                            }); });
                                        } : function () {
                                        Swal.fire(__assign(__assign({}, popupConfig), { title: (markedFields.length > 1) ? "\u00BFQuieres descartar ".concat(markedFields.length, " facturas?") : '¿Quieres descartar esta factura?', html: "\n                          <div style=\"".concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Nombre</b> ").concat(currentPurchase.name, "</div>\n                          <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Serie</b> ").concat(currentPurchase.serie, "</div>\n                          <div>\n                            <b>No. </b>\n                            <ul>\n                                ").concat((markedFields.length > 1) ? markedFields.map(function (e) { return "<li>".concat(e.number, "</li>"); }).join('') : "<li>".concat(currentPurchase.number, "</li>"), "\n                            </ul>\n                          </div>\n                          <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Fecha</b> ").concat(formatDate(new Date(currentPurchase.date.replace(/-/g, '\/').replace(/T.+/, ''))), "</div>\n                          <div style=\"").concat((markedFields.length <= 1) ? '' : 'display: none;', "\"><b>Total</b> ").concat(formatter.format(currentPurchase.total), "</div>\n                        "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, descartar', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                            var idsToRemove, resp;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        if (!result.isConfirmed) return [3 /*break*/, 2];
                                                        idsToRemove = markedFields.map(function (field) { return field.documentId; });
                                                        return [4 /*yield*/, documentsEdit(userData.idToken, "discard", idsToRemove)];
                                                    case 1:
                                                        resp = _a.sent();
                                                        if (resp.error) {
                                                            toast(resp.message || 'No se pudo descartar la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                        }
                                                        else if (resp) {
                                                            toast('Factura descartada', __assign(__assign({}, toastConfig), { type: "success" }));
                                                            removeDataRow(idsToRemove, 1);
                                                            refreshData();
                                                        }
                                                        else {
                                                            toast('No se pudo descartar la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                        }
                                                        _a.label = 2;
                                                    case 2: return [2 /*return*/];
                                                }
                                            });
                                        }); });
                                    },
                                    keyId: "".concat(ind, "-").concat(colInd),
                                },
                            ] }),
                        widthPercent: col.widthPercent,
                        fontSize: col.fontSize,
                        avoidClickExpansion: col.avoidClickExpansion,
                    });
                }
                else {
                    if (col.shouldFormatCurrency) {
                        var calcRowColsNames = [
                            'goods',
                            'services',
                            'little',
                            'gas',
                            'import',
                            'export',
                            'exent',
                            'taxes'
                        ];
                        // @ts-ignore
                        var numberValue = currentPurchase[col.rawName];
                        if (isNaN(numberValue)) {
                            finalColValues.push({
                                elem: calcRowColsNames.includes(col.name) ? calcTotalColumn(currentPurchase.document_id, col.name) : "",
                                widthPercent: col.widthPercent,
                                fontSize: col.fontSize,
                                avoidClickExpansion: col.avoidClickExpansion,
                            });
                        }
                        else {
                            finalColValues.push({
                                elem: formatter.format(numberValue),
                                widthPercent: col.widthPercent,
                                fontSize: col.fontSize,
                                avoidClickExpansion: col.avoidClickExpansion,
                            });
                        }
                    }
                    else if (col.isDate) {
                        // @ts-ignore
                        var dateVal = currentPurchase[col.rawName];
                        if (dateVal) {
                            // Reference https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
                            dateVal = formatDate(new Date(dateVal.replace(/-/g, '\/').replace(/T.+/, '')));
                        }
                        else {
                            dateVal = "NA";
                        }
                        finalColValues.push({
                            elem: dateVal,
                            widthPercent: col.widthPercent,
                            fontSize: col.fontSize,
                            avoidClickExpansion: col.avoidClickExpansion,
                        });
                    }
                    else if (col.rawName == "linked_isr") {
                        // console.log(`Current purchase\n${currentPurchase}`)
                        // console.log(`Current Data\n${currentData}`)
                        finalColValues.push({
                            elem: currentPurchase.retention_number ?
                                React.createElement(AbsoluteToolTip, { text: "Retenci\u00F3n: ".concat(currentPurchase.retention_number, "\nFecha de emisi\u00F3n: ").concat(currentPurchase.emision_date) },
                                    React.createElement("img", { className: "linkedInvoiceIndicatorBills", src: invoiceIcon, alt: "La retencion correspondiente a esta factura es: ".concat(currentPurchase.retention_number) })) : "",
                            widthPercent: col.widthPercent,
                            fontSize: col.fontSize,
                            avoidClickExpansion: col.avoidClickExpansion,
                        });
                    }
                    else {
                        finalColValues.push({
                            // @ts-ignore
                            elem: currentPurchase[col.rawName] && typeof currentPurchase[col.rawName] === "string" ? currentPurchase[col.rawName].toUpperCase() : currentPurchase[col.rawName] || "",
                            widthPercent: col.widthPercent,
                            fontSize: col.fontSize,
                            avoidClickExpansion: col.avoidClickExpansion,
                        });
                    }
                }
            });
            rawItems.forEach(function (raw, ind) {
                var rowItems = [];
                // Child rows
                cols.forEach(function (col, colInd) {
                    var _a;
                    if (col.isIndexable && raw.tax_type_id === col.taxId) { // Tax type
                        rowItems.push({
                            // @ts-ignore
                            elem: formatter.format(raw[col.rawName]),
                            widthPercent: col.widthPercent,
                            fontSize: col.fontSize,
                            // fontColor: taxIsInChanges(raw.item_id) ? "#0000FF" : undefined,
                            backgroundColor: taxIsInChanges(raw.item_id) ? "#FFFF00" : undefined,
                            onClick: function () {
                                var maxId = Math.max.apply(Math, taxTypeIds);
                                var minId = Math.min.apply(Math, taxTypeIds);
                                var currentIdIndex = taxTypeIds.indexOf(raw.tax_type_id);
                                var newTaxId = raw.tax_type_id < maxId // Should be adding up
                                    ? currentIdIndex >= 0 && taxTypeIds.length - 1 >= currentIdIndex // Found a match with current taxID and length is enought to change to nextOne
                                        ? taxTypeIds[currentIdIndex + 1]
                                        : minId
                                    : minId;
                                // Change current item's tax type
                                addTaxChange(raw.document_id, raw.item_id, currentData, newTaxId);
                                setFilteredData(changeItemTaxTypeId(raw.document_id, raw.item_id, currentData, newTaxId));
                            }
                        });
                    }
                    else if (!col.isIndexable && !col.hideInChildren) { // Regular value (like name or total)
                        rowItems.push(col.shouldFormatCurrency ? {
                            // @ts-ignore
                            elem: formatter.format(raw[col.childRawName || col.rawName]),
                            widthPercent: col.widthPercent,
                            fontSize: col.fontSize,
                        } : {
                            // @ts-ignore
                            elem: (col.shouldUpperCase ? (_a = raw[col.childRawName || col.rawName]) === null || _a === void 0 ? void 0 : _a.toUpperCase() : raw[col.childRawName || col.rawName]) || "",
                            widthPercent: col.widthPercent,
                            fontSize: col.fontSize,
                        });
                    }
                    else { // Everything else should be empty value
                        rowItems.push({
                            elem: "",
                            widthPercent: col.widthPercent,
                            fontSize: col.fontSize,
                        });
                    }
                });
                finalItemsValues.push(rowItems);
            });
            var newRow = {
                colsValues: finalColValues,
                items: finalItemsValues,
            };
            // setRows(prevState => [...prevState, newRow])
            finalRows.push(newRow);
        });
        return finalRows;
    };
    var getTotalRow = function () {
        var lastRow = {
            colsValues: []
        };
        var tempTotals = {};
        if (isPurchase) { // Purchases
            var totalColNames_1 = [
                'import',
                'goods',
                'services',
                'gas',
                'little',
                'exent',
                'taxes',
                'total',
            ];
            if (purchaseGeneratorData) {
                purchaseGeneratorData.forEach(function (row, rowInd) {
                    Object.keys(row).forEach(function (rowCol, rowColInd) {
                        // @ts-ignore
                        if (totalColNames_1.includes(rowCol) && isParseableToFloat(row[rowCol])) {
                            // @ts-ignore
                            if (tempTotals[rowCol]) {
                                // @ts-ignore
                                tempTotals[rowCol] += parseFloat(row[rowCol]);
                            }
                            else {
                                // @ts-ignore
                                tempTotals[rowCol] = parseFloat(row[rowCol]);
                            }
                        }
                    });
                });
            }
            else if (discardedRegularGenData) {
                discardedRegularGenData.forEach(function (row, rowInd) {
                    Object.keys(row).forEach(function (rowCol, rowColInd) {
                        // @ts-ignore
                        if (totalColNames_1.includes(rowCol) && isParseableToFloat(row[rowCol])) {
                            // @ts-ignore
                            if (tempTotals[rowCol]) {
                                // @ts-ignore
                                tempTotals[rowCol] += parseFloat(row[rowCol]);
                            }
                            else {
                                // @ts-ignore
                                tempTotals[rowCol] = parseFloat(row[rowCol]);
                            }
                        }
                    });
                });
            }
        }
        else { // Sales
            var totalColNames_2 = [
                'export',
                'goods',
                'services',
                'exent',
                'taxes',
                'total',
            ];
            if (salesGeneratorData) {
                exclusiveGeneratorData.forEach(function (row, rowInd) {
                    Object.keys(row).forEach(function (rowCol, rowColInd) {
                        // @ts-ignore
                        if (totalColNames_2.includes(rowCol) && isParseableToFloat(row[rowCol])) {
                            // @ts-ignore
                            if (tempTotals[rowCol]) {
                                // @ts-ignore
                                tempTotals[rowCol] += parseFloat(row[rowCol]);
                            }
                            else {
                                // @ts-ignore
                                tempTotals[rowCol] = parseFloat(row[rowCol]);
                            }
                        }
                    });
                });
            }
            else if (discardedRegularGenData) {
                exclusiveGeneratorData.forEach(function (row, rowInd) {
                    Object.keys(row).forEach(function (rowCol, rowColInd) {
                        // @ts-ignore
                        if (totalColNames_2.includes(rowCol) && isParseableToFloat(row[rowCol])) {
                            // @ts-ignore
                            if (tempTotals[rowCol]) {
                                // @ts-ignore
                                tempTotals[rowCol] += parseFloat(row[rowCol]);
                            }
                            else {
                                // @ts-ignore
                                tempTotals[rowCol] = parseFloat(row[rowCol]);
                            }
                        }
                    });
                });
            }
        }
        cols.forEach(function (parCol, parColInd) {
            var currColData = {
                elem: "",
                widthPercent: parCol.widthPercent,
                fontSize: 0.6,
                avoidClickExpansion: parCol.avoidClickExpansion,
                backgroundColor: '#E0E0E07B',
                fontColor: '#5B5B5B',
                fontWeight: 700,
            };
            Object.keys(tempTotals).forEach(function (tempTot, tempTotInd) {
                if (parCol.name === tempTot) {
                    //@ts-ignore
                    currColData.elem = formatter.format(tempTotals[tempTot]);
                }
                else if (parCol.name === 'entityName') {
                    //@ts-ignore
                    currColData.elem = "TOTAL (".concat(filteredData.length, " documentos)");
                }
            });
            lastRow.colsValues.push(currColData);
        });
        return lastRow;
    };
    var initData = function (reset) {
        var length = (data === null || data === void 0 ? void 0 : data.length) || 0;
        setLocalData(data);
        setFilteredData(data);
        // const filterTypesList
        var newFilterTypes = [];
        if (data) {
            data.forEach(function (doc, ind) {
                if (!newFilterTypes.includes(doc.document_name)) {
                    newFilterTypes.push(doc.document_name);
                }
            });
            setFilterTypesList(newFilterTypes);
        }
        if (reset) {
            setTaxChanges({
                "1": [],
                "2": [],
                "3": [],
                "4": [],
                "5": [],
                "6": [],
            });
            setExpandedRows(Array.from({ length: length }, function () { return false; }));
            setChildrenColapsed(true);
        }
    };
    var resetData = function () {
        // Reset data to its original state
        setLocalData(data);
        setFilteredData(data);
        setSearchVal("");
        setFilterType("all");
        setTaxChanges({
            "1": [],
            "2": [],
            "3": [],
            "4": [],
            "5": [],
            "6": [],
        });
    };
    var filterData = function (ev) {
        setSearchVal(ev.target.value);
        if (ev.target.value === "") {
            setFilteredData(data);
        }
        else {
            // Search for matches
            setFilteredData(data.filter(function (doc) {
                return formatCompanyName(doc.name).includes(formatCompanyName(ev.target.value))
                    || formatCompanyName(doc.nit).includes(formatCompanyName(ev.target.value))
                    || formatCompanyName(doc.doc_nit).includes(formatCompanyName(ev.target.value))
                    || formatCompanyName(doc.serie).includes(formatCompanyName(ev.target.value))
                    || formatCompanyName(doc.number).includes(formatCompanyName(ev.target.value))
                    || formatCompanyName(doc.document_id).includes(formatCompanyName(ev.target.value));
            }));
        }
    };
    var filterDataByType = function (ev) {
        setFilterType(ev.target.value);
        if (ev.target.value === "all") {
            setFilteredData(data);
        }
        else {
            setFilteredData(data.filter(function (doc) { return doc.document_name === ev.target.value; }));
        }
    };
    useEffect(function () {
        initData(localData.length === 0);
    }, [data]);
    return (React.createElement("div", { className: "billsContainer" },
        React.createElement("div", { className: "billsSavingOptions", style: { width: "95vw", height: "2vw" } },
            React.createElement("div", { className: "billsLeftOptContainer" }, localData.length > 0 &&
                React.createElement(React.Fragment, null,
                    React.createElement("div", { className: "billsSearchbar" },
                        React.createElement("img", { src: searchIcon, alt: "Search" }),
                        React.createElement("input", { className: "billsTransInput", value: searchVal, style: { width: "13.5vw", textAlign: "start" }, placeholder: "Buscar por nombre o por NIT", onChange: function (ev) { return filterData(ev); } })),
                    filterTypesList.length > 1 &&
                        React.createElement("div", { className: "billsTypeFilter" },
                            React.createElement("div", null, "Filtrar por tipo:"),
                            React.createElement("select", { className: "billsDropdown", value: filterType, onChange: function (ev) { return filterDataByType(ev); } },
                                React.createElement("option", { value: "all" }, "Todos"),
                                filterTypesList.map(function (filter, filterInd) {
                                    return (React.createElement("option", { key: "filterTypeOpt-".concat(filter, "-").concat(filterInd), value: filter }, filter));
                                }))),
                    // This is the state that stores how many marked fields there are
                    (markedFields.length > 0) &&
                        React.createElement(DiscardAllButton, { text: (generatorDownloadText === "Descargar Libro de Ventas" || generatorDownloadText === "Descargar Libro de Compras") ? "Descartar" : "Restaurar", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    // Restore Bill
                                    (generatorDownloadText === "Descargar Listado de Ventas Descartadas" || generatorDownloadText === "Descargar Listado de Compras Descartadas") ?
                                        (Swal.fire(__assign(__assign({}, popupConfig), { title: (markedFields.length > 1) ? "\u00BFQuieres restaurar ".concat(markedFields.length, " facturas?") : '¿Quieres restaurar esta factura?', html: "\n                          <div>\n                          <b>No. </b>\n                            <ul>\n                                ".concat((markedFields.length > 1) ? markedFields.map(function (e) { return "<li>Numero - ".concat(e.number, "</li>"); }).join('') : "", "\n                            </ul>\n                          </div>\n                        "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, restaurar', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                            var idsToRemove, resp;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        if (!result.isConfirmed) return [3 /*break*/, 2];
                                                        idsToRemove = markedFields.map(function (field) { return field.documentId; });
                                                        return [4 /*yield*/, documentsEdit(userData.idToken, "restore", idsToRemove)];
                                                    case 1:
                                                        resp = _a.sent();
                                                        if (resp.error) {
                                                            toast(resp.message || 'No se pudo restaurar la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                        }
                                                        else if (resp) {
                                                            toast('Factura restaurada', __assign(__assign({}, toastConfig), { type: "success" }));
                                                            removeDataRow(idsToRemove, 0);
                                                            refreshData();
                                                        }
                                                        else {
                                                            toast('No se pudo restaurar la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                        }
                                                        _a.label = 2;
                                                    case 2: return [2 /*return*/];
                                                }
                                            });
                                        }); }))
                                        : (Swal.fire(__assign(__assign({}, popupConfig), { title: (markedFields.length > 1) ? "\u00BFQuieres descartar ".concat(markedFields.length, " facturas?") : '¿Quieres descartar esta factura?', html: "\n                          <div>\n                            <b>No. </b>\n                            <ul>\n                                ".concat((markedFields.length > 1) ? markedFields.map(function (e) { return "<li>Numero - ".concat(e.number, "</li>"); }).join('') : "", "\n                            </ul>\n                          </div>\n                        "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, descartar', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                            var idsToRemove, resp;
                                            return __generator(this, function (_a) {
                                                switch (_a.label) {
                                                    case 0:
                                                        if (!result.isConfirmed) return [3 /*break*/, 2];
                                                        idsToRemove = markedFields.map(function (field) { return field.documentId; });
                                                        return [4 /*yield*/, documentsEdit(userData.idToken, "discard", idsToRemove)];
                                                    case 1:
                                                        resp = _a.sent();
                                                        if (resp.error) {
                                                            toast(resp.message || 'No se pudo descartar la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                        }
                                                        else if (resp) {
                                                            toast('Factura descartada', __assign(__assign({}, toastConfig), { type: "success" }));
                                                            removeDataRow(idsToRemove, 1);
                                                            refreshData();
                                                        }
                                                        else {
                                                            toast('No se pudo descartar la factura.', __assign(__assign({}, toastConfig), { type: "error" }));
                                                        }
                                                        _a.label = 2;
                                                    case 2: return [2 /*return*/];
                                                }
                                            });
                                        }); }));
                                    return [2 /*return*/];
                                });
                            }); } }))),
            taxChanges[1].length > 0
                || taxChanges[2].length > 0
                || taxChanges[3].length > 0
                || taxChanges[4].length > 0
                || taxChanges[5].length > 0
                || taxChanges[6].length > 0 ?
                React.createElement("div", { className: "billsRightOptContainer" },
                    React.createElement("button", { className: "billsFilledButton cancelStyle", onClick: function () {
                            Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Seguro?', html: "\n                    \u00BFQuieres descartar tus cambios?\n                  ", icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, descartar', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                return __generator(this, function (_a) {
                                    if (result.isConfirmed) {
                                        resetData();
                                    }
                                    return [2 /*return*/];
                                });
                            }); });
                        } }, "Cancelar"),
                    React.createElement("button", { className: "billsFilledButton saveStyle", onClick: function () {
                            Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Aplicar cambios?', html: "\n                  \u00BFQuieres guardar tus cambios en todos los items seleccionados?\n                ", icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, guardar', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                var changesBody_1, taxChangesCopy_1, response;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!result.isConfirmed) return [3 /*break*/, 2];
                                            changesBody_1 = {};
                                            taxChangesCopy_1 = __assign({}, taxChanges);
                                            Object.keys(taxChangesCopy_1).forEach(function (key) {
                                                if (taxChangesCopy_1[key].length > 0) {
                                                    changesBody_1[key] = taxChangesCopy_1[key];
                                                }
                                            });
                                            return [4 /*yield*/, updateDocumentItemsTax(userData.idToken, params.nit, changesBody_1)];
                                        case 1:
                                            response = _a.sent();
                                            if (response.error) {
                                                toast(response.message || 'No fue posible guardar los cambios.', __assign(__assign({}, toastConfig), { type: 'error' }));
                                            }
                                            else if (response) {
                                                toast('Los cambios se han guardado.', __assign(__assign({}, toastConfig), { type: 'success' }));
                                                if (refreshData) {
                                                    refreshData();
                                                }
                                                resetData();
                                            }
                                            else {
                                                toast('No fue posible guardar los cambios.', __assign(__assign({}, toastConfig), { type: 'error' }));
                                            }
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); });
                        } }, "Guardar"))
                : (purchaseGeneratorData || salesGeneratorData) ?
                    (React.createElement("div", { className: "billsRightOptContainer" },
                        (excelGeneratorData && excelGeneratorData.length > 0) &&
                            React.createElement("button", { className: "billsFilledButton", onClick: function () { return saveDataToFile(excelGeneratorData, generatorDownloadedTitle); } },
                                React.createElement("img", { style: { marginRight: '0.5vw' }, src: excelIcon, alt: "[xlsx]" }),
                                excelDownloadText || "Descargar excel"),
                        React.createElement("button", { className: "billsFilledButton", onClick: function () { return handleGeneratePDF(); }, disabled: loadingGenPDF },
                            React.createElement("img", { style: { marginRight: '0.5vw' }, src: pdfIcon, alt: "[pdf]" }),
                            loadingGenPDF ? "Generando libro..." : "Descargar Libro de ".concat(purchaseGeneratorData ? 'Compras' : 'Ventas'))))
                    : (discardedGeneratorData && discardedGeneratorData.length > 0) &&
                        React.createElement("div", { className: "billsRightOptContainer" },
                            React.createElement("button", { className: "billsFilledButton", onClick: function () { return saveDataToFile(discardedGeneratorData, generatorDownloadedTitle); } },
                                React.createElement("img", { style: { marginRight: '0.5vw' }, src: excelIcon, alt: "[xlsx]" }),
                                generatorDownloadText))),
        (filteredData === null || filteredData === void 0 ? void 0 : filteredData.length) > 0
            ? React.createElement(AccordionTable, { cols: cols, rows: getDataRows(filteredData), expandedRows: expandedRows, setExpandedRows: setExpandedRows, width: "95vw", lastRow: getTotalRow() })
            : React.createElement("div", { className: "billsNoData" }, searchVal.length > 0 ? "No se encontraron resultados." : "No hay datos.")));
};
export default Bills;
