var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { loggedUser } from "../../../UserContext/UserReducer";
import Table from "../../../components/Table/Table";
import TableActions from "../../../components/TableActions/TableActions";
import { addUserToGroup, deleteUser, listUsers, removeUserFromGroup, updateUser } from "../../../services/auth";
import verifiedIcon from "../../../assets/icons/verifiedIcon.png";
import searchIcon from "../../../assets/icons/searchIcon.png";
import addIcon from "../../../assets/icons/addIcon.png";
import { ActionTypes, Actions, Roles, toastConfig, popupConfig } from "../../../shared/constants";
import { arrayOrderByProperty, formatCompanyName } from "../../../shared/utils";
import { enableUser, disableUser } from "../../../services/auth";
import Loader from "../../../components/Loader/Loader";
import Swal from "sweetalert2";
import "./AdminUsers.scss";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { getCompanies } from "../../../services/clientsService";
// Attributes coming from AWS (in case of a new custom attr, it should be mapped here)
var attrsMapping = [
    {
        responseName: "sub",
        localName: "userID",
    },
    {
        responseName: "email_verified",
        localName: "email_verified",
    },
    {
        responseName: "name",
        localName: "name",
    },
    {
        responseName: "email",
        localName: "email",
    },
    {
        responseName: "custom:company",
        localName: "company",
    },
    {
        responseName: "custom:role",
        localName: "role",
    },
];
var AdminUsers = function () {
    var userData = useSelector(loggedUser);
    // Users
    var _a = useState([]), originalUserList = _a[0], setOriginalUserList = _a[1];
    var _b = useState([]), userList = _b[0], setUserList = _b[1];
    var _c = useState([]), filteredUserList = _c[0], setFilteredUserList = _c[1];
    var _d = useState(""), searchText = _d[0], setSearchText = _d[1];
    var _e = useState([]), companies = _e[0], setCompanies = _e[1];
    var _f = useState(false), usersFetched = _f[0], setUsersFetched = _f[1];
    // Editing
    var _g = useState(false), isActiveInput = _g[0], setIsActiveInput = _g[1];
    var _h = useState(""), nameInput = _h[0], setNameInput = _h[1];
    var _j = useState(""), emailInput = _j[0], setEmailInput = _j[1];
    var _k = useState(""), companyInput = _k[0], setCompanyInput = _k[1];
    var _l = useState(Roles.User), roleInput = _l[0], setRoleInput = _l[1];
    var _m = useState(false), isEditing = _m[0], setIsEditing = _m[1];
    var tableColumns = [
        {
            display: "Estado",
            name: "status",
        },
        {
            display: "Nombre",
            name: "name",
            onClickEvent: function () { orderUsers("name"); },
        },
        {
            display: "Usuario",
            name: "user",
            onClickEvent: function () { orderUsers("username"); },
        },
        {
            display: "Correo",
            name: "email",
            onClickEvent: function () { orderUsers("email"); },
        },
        {
            display: "Empresa",
            name: "company",
            onClickEvent: function () { orderUsers("company"); },
        },
        {
            display: "Rol",
            name: "role",
            onClickEvent: function () { orderUsers("role"); },
        },
        {
            display: "",
            name: "actions"
        },
    ];
    var initData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var companyList, response, users, userMapping, currentUser_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCompanies(userData.idToken)];
                case 1:
                    companyList = _a.sent();
                    if (companyList) {
                        setCompanies(companyList);
                    }
                    return [4 /*yield*/, listUsers()];
                case 2:
                    response = _a.sent();
                    users = response.Users;
                    if (users) {
                        userMapping = users.map(function (elem, ind) {
                            var userObject = {
                                userID: "",
                                email: "",
                                email_verified: "",
                                name: "",
                                username: "",
                                isActive: elem.Enabled,
                                createdDate: "",
                                lastModifiedDate: "",
                                company: "",
                                role: "",
                                editing: false,
                            };
                            attrsMapping.forEach(function (attr) {
                                var _a;
                                var currentAttr = elem.Attributes.find(function (elem) { return elem.Name === attr.responseName; });
                                if (currentAttr)
                                    userObject = __assign(__assign({}, userObject), (_a = {}, _a[attr.localName] = currentAttr.Value, _a));
                            });
                            userObject.isActive = elem.Enabled;
                            userObject.createdDate = elem.UserCreateDate;
                            userObject.lastModifiedDate = elem.UserLastModifiedDate;
                            userObject.username = elem.Username;
                            return userObject;
                        });
                        if (userData.role === Roles.Superadmin) {
                            setUserList(userMapping);
                            setOriginalUserList(userMapping);
                            setFilteredUserList(userMapping);
                        }
                        else if (userData.role === Roles.Admin) {
                            currentUser_1 = userMapping.find(function (usr) { return usr.username === userData.name; });
                            if (currentUser_1) {
                                setOriginalUserList(userMapping);
                                setUserList(userMapping.filter(function (elem) { return elem.company === currentUser_1.company; }));
                                setFilteredUserList(userMapping.filter(function (elem) { return elem.company === currentUser_1.company; }));
                            }
                        }
                    }
                    setUsersFetched(true);
                    return [2 /*return*/];
            }
        });
    }); };
    var orderUsers = function (property) {
        var newArray = __spreadArray([], userList, true);
        arrayOrderByProperty(newArray, property);
        setFilteredUserList(newArray);
    };
    var searchHandler = function (ev) {
        setSearchText(ev.target.value);
        var searchVal = ev.target.value.toLowerCase();
        if (searchVal === "") {
            setFilteredUserList(userList);
        }
        else {
            var filteredResults = userList.filter(function (user) { return user.name.toLowerCase().includes(searchVal)
                || user.username.toLowerCase().includes(searchVal); });
            setFilteredUserList(filteredResults);
        }
    };
    var updateUserAttributes = function (userNewValue) { return __awaiter(void 0, void 0, void 0, function () {
        var originalUserValue, changedValues, mappedValues, newAttrs;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    originalUserValue = originalUserList.find(function (user) { return user.username === userNewValue.username; });
                    changedValues = {};
                    mappedValues = {};
                    // Compare previous values with current ones if it is not the same
                    if (originalUserValue && originalUserValue !== userNewValue) {
                        // Iterate over the keys of newObj
                        Object.keys(userNewValue).forEach(function (key) {
                            // Check if the key exists in oldObj and if the values are different
                            if (originalUserValue[key] !== userNewValue[key]) {
                                changedValues[key] = userNewValue[key];
                            }
                        });
                        attrsMapping.forEach(function (attr, ind) {
                            // If value is in the object (this means the attribute has changed) map to AWS attribute names
                            if (changedValues[attr.localName]) {
                                mappedValues[attr.responseName] = changedValues[attr.localName];
                            }
                        });
                    }
                    if (!Object.keys(changedValues).includes("isActive")) return [3 /*break*/, 4];
                    if (!(changedValues["isActive"] === true)) return [3 /*break*/, 2];
                    return [4 /*yield*/, enableUser(userNewValue.username, userData.idToken).then(function () {
                            toast("Usuario habilitado.", __assign(__assign({}, toastConfig), { type: "success" }));
                        }).catch(function (err) {
                            toast("El usaurio no se ha podido habilitar.", __assign(__assign({}, toastConfig), { type: "error" }));
                        })];
                case 1:
                    _a.sent();
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, disableUser(userNewValue.username, userData.idToken).then(function () {
                        toast("Usuario deshabilitado.", __assign(__assign({}, toastConfig), { type: "success" }));
                    }).catch(function (err) {
                        toast("El usuario no se ha podido deshabilitar.", __assign(__assign({}, toastConfig), { type: "error" }));
                    })];
                case 3:
                    _a.sent();
                    _a.label = 4;
                case 4:
                    if (!Object.keys(changedValues).includes("role")) return [3 /*break*/, 7];
                    // Remove user previous permissions
                    return [4 /*yield*/, removeUserFromGroup(originalUserValue.username, userData.idToken, originalUserValue.role).catch(function (err) {
                            toast("No se han podido remover los permisos de ".concat(originalUserValue.role), __assign(__assign({}, toastConfig), { type: "error" }));
                        })];
                case 5:
                    // Remove user previous permissions
                    _a.sent();
                    // Add user new permissions
                    return [4 /*yield*/, addUserToGroup(userNewValue.username, userData.idToken, changedValues["role"]).then(function () {
                            toast("Permisos actualizados.", __assign(__assign({}, toastConfig), { type: "success" }));
                        }).catch(function (err) {
                            toast("No se han podido asignar los permisos de ".concat(changedValues["role"]), __assign(__assign({}, toastConfig), { type: "error" }));
                        })];
                case 6:
                    // Add user new permissions
                    _a.sent();
                    _a.label = 7;
                case 7:
                    if (!(Object.keys(mappedValues).length > 0)) return [3 /*break*/, 9];
                    newAttrs = Object.keys(mappedValues).map(function (attr) {
                        return {
                            Name: attr,
                            Value: mappedValues[attr],
                        };
                    });
                    return [4 /*yield*/, updateUser(userNewValue.username, userData.idToken, newAttrs).then(function () {
                            toast("Datos actualizados.", __assign(__assign({}, toastConfig), { type: "success" }));
                        }).catch(function (err) {
                            toast("Error guardando datos. Por favor intentar de nuevo.", __assign(__assign({}, toastConfig), { type: "error" }));
                        })];
                case 8:
                    _a.sent();
                    _a.label = 9;
                case 9:
                    // Refresh values
                    if (Object.keys(changedValues).includes("isActive") || Object.keys(mappedValues).length > 0) {
                        setUserList([]);
                        setFilteredUserList([]);
                        initData();
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var getUserRows = function (allUsers) {
        return allUsers.map(function (user, userIndex) {
            return user.editing
                ? {
                    status: React.createElement("div", { className: isActiveInput ? "activeStatusCircle" : "inactiveStatusCircle", onClick: function () {
                            setIsActiveInput(!isActiveInput);
                        } }),
                    name: React.createElement("input", { className: "adminUsersInput", value: nameInput, onChange: function (ev) { return setNameInput(ev.target.value); } }),
                    user: user.username,
                    email: React.createElement("input", { className: "adminUsersInput", value: emailInput, onChange: function (ev) { return setEmailInput(ev.target.value); } }),
                    company: React.createElement("select", { className: "adminUsersDropdown", value: companyInput, onChange: function (ev) { return setCompanyInput(ev.target.value); }, disabled: userData.role !== Roles.Superadmin }, Array.isArray(companies) && companies.map(function (comp, compInd) {
                        return (React.createElement("option", { key: "opt-".concat(user.userID, "-").concat(compInd, "-company"), value: comp.company_id }, comp.company_name));
                    })),
                    role: React.createElement("select", { className: "adminUsersDropdown", value: roleInput, onChange: function (ev) { return setRoleInput(ev.target.value); } }, Object.keys(Roles).map(function (role, ind) {
                        return (React.createElement("option", { key: "opt-".concat(user.userID, "-").concat(ind), value: role }, role));
                    })),
                    actions: React.createElement(TableActions, { actionList: [
                            {
                                action: Actions.Accept,
                                altText: "aceptar",
                                titleText: "Aceptar",
                                keyId: "".concat(userIndex),
                                onClick: function () {
                                    // Current user's state
                                    var currentUserState = __assign(__assign({}, user), { isActive: isActiveInput, name: nameInput, email: emailInput, company: formatCompanyName(companyInput), role: roleInput });
                                    // Save changes
                                    updateUserAttributes(currentUserState);
                                    // Disable Editing
                                    var currentState = userList.filter(function (elem) { return elem.username !== user.username; });
                                    var currentUsersIndex = userList.indexOf(user);
                                    var currentUser = __assign(__assign({}, user), { editing: false });
                                    if (currentUsersIndex > -1) {
                                        var previousUsers = currentState.slice(0, currentUsersIndex);
                                        var followingUsers = currentState.slice(currentUsersIndex);
                                        // setUserList([...previousUsers, currentUser, ...followingUsers])
                                        setFilteredUserList(__spreadArray(__spreadArray(__spreadArray([], previousUsers, true), [currentUser], false), followingUsers, true));
                                    }
                                    else {
                                        // setUserList([...currentState, currentUser])
                                        setFilteredUserList(__spreadArray(__spreadArray([], currentState, true), [currentUser], false));
                                    }
                                    setIsEditing(false);
                                },
                            },
                            {
                                action: Actions.Close,
                                altText: "cancelar",
                                titleText: "Cancelar",
                                iconWidth: '1.2vw',
                                keyId: "".concat(userIndex),
                                onClick: function () {
                                    var currentState = filteredUserList.filter(function (elem) { return elem.username !== user.username; });
                                    var currentUsersIndex = filteredUserList.indexOf(user);
                                    var currentUser = __assign(__assign({}, user), { editing: false });
                                    if (currentUsersIndex > -1) {
                                        var previousUsers = currentState.slice(0, currentUsersIndex);
                                        var followingUsers = currentState.slice(currentUsersIndex);
                                        // setUserList([...previousUsers, currentUser, ...followingUsers])
                                        setFilteredUserList(__spreadArray(__spreadArray(__spreadArray([], previousUsers, true), [currentUser], false), followingUsers, true));
                                    }
                                    else {
                                        // setUserList([...currentState, currentUser])
                                        setFilteredUserList(__spreadArray(__spreadArray([], currentState, true), [currentUser], false));
                                    }
                                    setIsEditing(false);
                                }
                            }
                        ] })
                }
                : {
                    status: React.createElement("div", { className: user.isActive ? "activeStatusCircle" : "inactiveStatusCircle" }),
                    name: user.name,
                    user: user.username,
                    email: React.createElement("div", { className: "emailContainer" },
                        user.email_verified &&
                            React.createElement("img", { title: "Correo Verificado", src: verifiedIcon, alt: "verified", draggable: false }),
                        React.createElement("div", null, user.email)),
                    company: Array.isArray(companies) && companies.find(function (comp) { return comp.company_id === user.company; }) ? companies.find(function (comp) { return comp.company_id === user.company; }).company_name : "No asignado",
                    role: user.role,
                    actions: React.createElement(TableActions, { actionList: [
                            {
                                action: Actions.Edit,
                                altText: 'editar',
                                titleText: 'Editar',
                                keyId: "".concat(userIndex),
                                type: userData.name !== user.username ? ActionTypes.Button : ActionTypes.None,
                                onClick: function () {
                                    if (!isEditing) {
                                        var currentState = filteredUserList.filter(function (elem) { return elem.username !== user.username; });
                                        var currentUsersIndex = filteredUserList.indexOf(user);
                                        var currentUser = __assign(__assign({}, user), { editing: true });
                                        if (currentUsersIndex > -1) {
                                            var previousUsers = currentState.slice(0, currentUsersIndex);
                                            var followingUsers = currentState.slice(currentUsersIndex);
                                            // setUserList([...previousUsers, currentUser, ...followingUsers])
                                            setFilteredUserList(__spreadArray(__spreadArray(__spreadArray([], previousUsers, true), [currentUser], false), followingUsers, true));
                                        }
                                        else {
                                            // setUserList([...currentState, currentUser])
                                            setFilteredUserList(__spreadArray(__spreadArray([], currentState, true), [currentUser], false));
                                        }
                                        setIsActiveInput(user.isActive);
                                        setNameInput(user.name);
                                        setEmailInput(user.email);
                                        setCompanyInput(user.company);
                                        setRoleInput(user.role);
                                        setIsEditing(true);
                                    }
                                    else {
                                        alert("Solo se puede editar un usuario a la vez");
                                    }
                                }
                            },
                            {
                                action: Actions.Delete,
                                altText: 'eliminar',
                                titleText: 'Eliminar',
                                keyId: "".concat(userIndex),
                                type: userData.name !== user.username ? ActionTypes.Button : ActionTypes.None,
                                onClick: function () {
                                    Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Seguro?', html: "\u00BFQuieres eliminar al usuario <b>".concat(user.username, "</b>? Esto eliminar\u00E1 toda su informaci\u00F3n del sistema."), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, entiendo las consecuencias', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0:
                                                    if (!result.isConfirmed) return [3 /*break*/, 2];
                                                    return [4 /*yield*/, deleteUser(user.username, userData.idToken).then(function () {
                                                            toast("Usuario eliminado!", __assign(__assign({}, toastConfig), { type: "success" }));
                                                            // Refresh data
                                                            setUserList([]);
                                                            setFilteredUserList([]);
                                                            initData();
                                                        }).catch(function (err) {
                                                            toast("Error al eliminar usuario. Vuelve a intentarlo más tarde.", __assign(__assign({}, toastConfig), { type: "error" }));
                                                        })];
                                                case 1:
                                                    _a.sent();
                                                    _a.label = 2;
                                                case 2: return [2 /*return*/];
                                            }
                                        });
                                    }); });
                                }
                            }
                        ] })
                };
        });
    };
    useEffect(function () {
        initData();
    }, []);
    return (React.createElement("div", { className: "AdminUsersContainer" }, userList.length > 0
        ?
            React.createElement("div", null,
                React.createElement("div", { className: "AdminUsersToolbarContainer" },
                    React.createElement("div", { className: "AdminUsersToolbarLeft" }, "Usuarios"),
                    React.createElement("div", { className: "AdminUsersToolbarRight" },
                        React.createElement("div", { className: "adminUsersSearchbar" },
                            React.createElement("img", { src: searchIcon, alt: "Search" }),
                            React.createElement("input", { placeholder: "Buscar por nombre o usuario...", onChange: function (ev) { return searchHandler(ev); } })),
                        React.createElement(Link, { className: "adminUsersButton", title: "Crear Usuario", to: "/admin/user/create" },
                            React.createElement("img", { src: addIcon, alt: "Crear" })))),
                filteredUserList.length === 0 && searchText !== ""
                    ? React.createElement("div", { className: "adminUsersNoResults" }, "No se encontraron resultados.")
                    : filteredUserList.length === 0 && usersFetched
                        ? React.createElement("div", { className: "adminUsersNoResults" }, "No hay usuarios.")
                        : React.createElement(Table, { columns: tableColumns, rows: getUserRows(filteredUserList), width: "95vw", paginate: true }))
        : React.createElement("div", { style: {
                height: "calc(100vh - 6.25vw)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
            } },
            React.createElement(Loader, null))));
};
export default AdminUsers;
