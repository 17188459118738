var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Table from "../../components/Table/Table";
import { arrayOrderByProperty, formatCompanyName } from "../../shared/utils";
import searchIcon from "../../assets/icons/searchIcon.png";
import arrowDown from "../../assets/icons/whiteArrowDown.png";
import sortIcon from "../../assets/icons/sortIcon.png";
import { useSelector } from "react-redux";
import { loggedUser } from "../../UserContext/UserReducer";
import { getClients, getCompanies } from "../../services/clientsService";
import Loader from "../../components/Loader/Loader";
import "./Clients.scss";
import { Roles } from "../../shared/constants";
import InvalidPassword from "../../components/InvalidPassword/InvalidPassword";
import warningIcon from '../../assets/icons/warningIcon.png';
var Clients = function () {
    var navigate = useNavigate();
    var userData = useSelector(loggedUser);
    var _a = useState({ nit: false, name: false, regime: false }), colDisplayStates = _a[0], setColDisplayStates = _a[1];
    var cols = [
        {
            display: React.createElement("div", { style: { display: "flex", flexDirection: "row", gap: "0.5vw", justifyContent: "center", alignItems: "center" } },
                React.createElement("div", null, "NIT"),
                colDisplayStates["nit"] ?
                    React.createElement("img", { src: arrowDown, style: { width: "1vw" }, alt: "" })
                    :
                        React.createElement("img", { src: sortIcon, style: { width: "1vw" }, alt: "" })),
            name: "nit",
            onClickEvent: function () {
                if (!colDisplayStates["nit"]) {
                    orderUsers("nit");
                    setColDisplayStates({ nit: true, name: false, regime: false });
                }
                else { // Reset order
                    setFilteredClientList(clientList);
                    setColDisplayStates(function (prevState) { return (__assign(__assign({}, prevState), { nit: !colDisplayStates["nit"] })); });
                }
            },
        },
        {
            //</div>
            display: "",
            name: "warning",
            width: 10,
            avoidClicksForRow: true,
        },
        {
            display: React.createElement("div", { style: { display: "flex", flexDirection: "row", gap: "0.5vw", justifyContent: "center", alignItems: "center" } },
                React.createElement("div", null, "Nombre"),
                colDisplayStates["name"] ?
                    React.createElement("img", { src: arrowDown, style: { width: "1vw" }, alt: "" })
                    :
                        React.createElement("img", { src: sortIcon, style: { width: "1vw" }, alt: "" })),
            name: "name",
            onClickEvent: function () {
                if (!colDisplayStates["name"]) {
                    orderUsers("name");
                    setColDisplayStates({ nit: false, name: true, regime: false });
                }
                else {
                    setFilteredClientList(clientList);
                    setColDisplayStates(function (prevState) { return (__assign(__assign({}, prevState), { name: !colDisplayStates["name"] })); });
                }
            },
        },
        {
            display: React.createElement("div", { style: { display: "flex", flexDirection: "row", gap: "0.5vw", justifyContent: "center", alignItems: "center" } },
                React.createElement("div", null, "R\u00E9gimen"),
                colDisplayStates["regime"] ?
                    React.createElement("img", { src: arrowDown, style: { width: "1vw" }, alt: "" })
                    :
                        React.createElement("img", { src: sortIcon, style: { width: "1vw" }, alt: "" })),
            name: "regime",
            onClickEvent: function () {
                if (!colDisplayStates["regime"]) {
                    orderUsers("regime_name");
                    setColDisplayStates({ nit: false, name: false, regime: true });
                }
                else {
                    setFilteredClientList(clientList);
                    setColDisplayStates(function (prevState) { return (__assign(__assign({}, prevState), { regime: !colDisplayStates["regime"] })); });
                }
            },
        },
    ];
    var _b = useState([]), clientList = _b[0], setClientList = _b[1];
    var _c = useState([]), companyList = _c[0], setCompanyList = _c[1];
    var _d = useState([]), filteredClientList = _d[0], setFilteredClientList = _d[1];
    var _e = useState(""), searchText = _e[0], setSearchText = _e[1];
    var _f = useState(false), clientsFetched = _f[0], setClientsFetched = _f[1];
    var _g = useState({
        regimeFilter: (function (obj) { return true; }),
        movementFilter: (function (obj) { return true; }),
        typeFilter: (function (obj) { return true; }),
        searchFilter: (function (obj) { return true; }),
        enabledFilter: (function (obj) { return obj.is_enabled === 1; }),
        companyFilter: (function (obj) { return true; }),
    }), filters = _g[0], setFilters = _g[1];
    var initData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, compResp, filtersToApply;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getClients(userData.idToken)];
                case 1:
                    response = _a.sent();
                    if (!(userData.role === Roles.Superadmin)) return [3 /*break*/, 3];
                    return [4 /*yield*/, getCompanies(userData.idToken)];
                case 2:
                    compResp = _a.sent();
                    setCompanyList(Array.isArray(compResp) ? compResp : []);
                    _a.label = 3;
                case 3:
                    setClientsFetched(true);
                    setClientList(Array.isArray(response) ? response : []);
                    filtersToApply = Object.keys(filters).map(function (filt) {
                        //@ts-ignore
                        return filters[filt];
                    });
                    setFilteredClientList(applyFilters(Array.isArray(response) ? response : [], filtersToApply));
                    return [2 /*return*/];
            }
        });
    }); };
    var getClientRows = function (allClients) {
        var currentDate = new Date();
        var currentMonth = currentDate.getMonth(); // Implicitly subtract 1 from month number
        var currentYear = currentDate.getFullYear();
        if (currentMonth === 0) {
            currentMonth = 12;
            currentYear -= 1;
        }
        return allClients.map(function (client, clientInd) {
            return {
                nit: client.nit,
                warning: (client.invalid_password === 1) ? React.createElement(InvalidPassword, { icon: warningIcon, title: "Actualizar contrase\u00F1a", onClick: function () { navigate("admin/client/edit/".concat(client.nit)); } }) : "",
                name: client.name,
                regime: client.regime_name,
                onClick: function () { navigate("summary/".concat(client.nit, "/").concat(currentYear, "/").concat(currentMonth)); },
                customStyles: {
                    nit: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    name: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    regime: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    warning: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                }
            };
        });
    };
    var orderUsers = function (property) {
        var newArray = __spreadArray([], clientList, true);
        arrayOrderByProperty(newArray, property);
        setFilteredClientList(newArray);
    };
    var applyFilters = function (data, filters) {
        // Apply each filter function to the array of objects
        return data.filter(function (obj) {
            // Check if the object passes all filter conditions
            return filters.every(function (filter) { return filter(obj); });
        });
    };
    useEffect(function () {
        var filtersToApply = Object.keys(filters).map(function (filt) {
            //@ts-ignore
            return filters[filt];
        });
        setFilteredClientList(applyFilters(clientList, filtersToApply));
        // TODO store filters in context to persist throughout the application
    }, [filters]);
    useEffect(function () {
        initData();
    }, []);
    return (React.createElement("div", { style: { paddingBottom: "5vw" } },
        (userData.name && userData.name !== "") &&
            React.createElement("div", { className: "clientsTitle", style: { width: '95vw', textAlign: 'start', fontWeight: 500, marginBottom: '1vw' } }, "Bienvenido(a) ".concat(userData.name)),
        React.createElement("div", { className: "clientsTitle" }, "Listado de Clientes"),
        React.createElement("div", { className: "clientsToolbar" },
            React.createElement("div", { className: "clientsToolbarLeft" },
                React.createElement("div", { className: "clientsFiltersContainer" },
                    React.createElement("div", { className: "clientsFilterContainer" },
                        React.createElement("div", { className: "clientsFilterTitle" }, "R\u00E9gimen"),
                        React.createElement("select", { className: "clientsDropdown", onChange: function (ev) {
                                var filterByRegimeCallback = ev.target.value === "all"
                                    ? (function (obj) { return true; })
                                    : (function (obj) { return obj.regime_id === parseInt(ev.target.value); });
                                setFilters(__assign(__assign({}, filters), { regimeFilter: filterByRegimeCallback }));
                            } },
                            React.createElement("option", { value: "all" }, "Todos"),
                            React.createElement("option", { value: "1" }, "IVA GENERAL - ISR MENSUAL"),
                            React.createElement("option", { value: "2" }, "IVA GENERAL - ISR TRIMESTRAL"),
                            React.createElement("option", { value: "3" }, "PEQUE\u00D1O CONTRIBUYENTE"))),
                    React.createElement("div", { className: "clientsFilterContainer" },
                        React.createElement("div", { className: "clientsFilterTitle" }, "Movimiento"),
                        React.createElement("select", { className: "clientsDropdown", onChange: function (ev) {
                                var filterByMovementCallback = ev.target.value === "all"
                                    ? (function (obj) { return true; })
                                    : (function (obj) { return obj.has_movement === parseInt(ev.target.value); });
                                setFilters(__assign(__assign({}, filters), { movementFilter: filterByMovementCallback }));
                            } },
                            React.createElement("option", { value: "all" }, "Todos"),
                            React.createElement("option", { value: "1" }, "Con movimiento"),
                            React.createElement("option", { value: "0" }, "Sin movimiento"))),
                    React.createElement("div", { className: "clientsFilterContainer" },
                        React.createElement("div", { className: "clientsFilterTitle" }, "Tipo"),
                        React.createElement("select", { className: "clientsDropdown", onChange: function (ev) {
                                var filterByTypeCallback = ev.target.value === "all"
                                    ? (function (obj) { return true; })
                                    : (function (obj) { return obj.category_id === parseInt(ev.target.value); });
                                setFilters(__assign(__assign({}, filters), { typeFilter: filterByTypeCallback }));
                            } },
                            React.createElement("option", { value: "all" }, "Todos"),
                            React.createElement("option", { value: "1" }, "Sociedad"),
                            React.createElement("option", { value: "2" }, "Individual"))),
                    React.createElement("div", { className: "clientsFilterContainer" },
                        React.createElement("div", { className: "clientsFilterTitle" }, "Estado"),
                        React.createElement("select", { className: "clientsDropdown", onChange: function (ev) {
                                var filterByTypeCallback = ev.target.value === "all"
                                    ? (function (obj) { return true; })
                                    : (function (obj) { return obj.is_enabled === parseInt(ev.target.value); });
                                setFilters(__assign(__assign({}, filters), { enabledFilter: filterByTypeCallback }));
                            } },
                            React.createElement("option", { value: "1" }, "Activos"),
                            React.createElement("option", { value: "all" }, "Todos"),
                            React.createElement("option", { value: "0" }, "Inactivos"))),
                    userData.role === Roles.Superadmin && companyList.length > 0 &&
                        React.createElement("div", { className: "clientsFilterContainer" },
                            React.createElement("div", { className: "clientsFilterTitle" }, "Empresa/Contador"),
                            React.createElement("select", { className: "clientsDropdown", onChange: function (ev) {
                                    var filterByTypeCallback = ev.target.value === "all"
                                        ? (function (obj) { return true; })
                                        : (function (obj) { return obj.company_id === ev.target.value; });
                                    setFilters(__assign(__assign({}, filters), { companyFilter: filterByTypeCallback }));
                                } },
                                React.createElement("option", { value: "all" }, "Todos"),
                                companyList.map(function (comp, compInd) {
                                    return (React.createElement("option", { value: comp.company_id }, comp.company_name));
                                }))))),
            React.createElement("div", { className: "clientsToolbarRight" },
                React.createElement("div", { className: "clientsSearchbar" },
                    React.createElement("img", { src: searchIcon, alt: "Search" }),
                    React.createElement("input", { placeholder: "Buscar por NIT o nombre...", onChange: function (ev) {
                            // searchHandler(ev)
                            setSearchText(ev.target.value);
                            var filterBySearchCallback = ev.target.value === "all"
                                ? (function (obj) { return true; })
                                : (function (obj) { return formatCompanyName(obj.name).includes(formatCompanyName(ev.target.value))
                                    || formatCompanyName(obj.nit).includes(formatCompanyName(ev.target.value)); });
                            setFilters(__assign(__assign({}, filters), { searchFilter: filterBySearchCallback }));
                        } })))),
        (filteredClientList === null || filteredClientList === void 0 ? void 0 : filteredClientList.length) > 0 &&
            React.createElement(Table, { columns: cols, rows: getClientRows(filteredClientList), width: "95vw", paginate: true }),
        (filteredClientList === null || filteredClientList === void 0 ? void 0 : filteredClientList.length) === 0 && searchText !== ""
            ? React.createElement("div", { className: "clientsNoResults" }, "No se encontraron resultados.")
            : (filteredClientList === null || filteredClientList === void 0 ? void 0 : filteredClientList.length) === 0 && !clientsFetched
                ?
                    React.createElement("div", { className: "clientsNoResults" },
                        React.createElement(Loader, null))
                :
                    (filteredClientList === null || filteredClientList === void 0 ? void 0 : filteredClientList.length) === 0 &&
                        React.createElement("div", { className: "clientsNoResults" }, "Usted no tiene asignado ning\u00FAn cliente.")));
};
export default Clients;
