var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var api_url = process.env.API_URL;
export var getClients = function (idToken) { return __awaiter(void 0, void 0, void 0, function () {
    var reqOpt, response, e_1;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqOpt = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/clients"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_1 = _a.sent();
                console.log(e_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getCompanies = function (idToken) { return __awaiter(void 0, void 0, void 0, function () {
    var reqOpt, response, e_2;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqOpt = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/companies"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_2 = _a.sent();
                console.log(e_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getClient = function (idToken, nit) { return __awaiter(void 0, void 0, void 0, function () {
    var reqOpt, response, data, errorMessage, e_3;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqOpt = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/client/").concat(nit), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_3 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var getClientsPurchases = function (idToken, nitParam, monthParam, yearParam) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_4;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    nit: nitParam,
                    month: monthParam,
                    year: yearParam,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/purchases"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_4 = _a.sent();
                console.log(e_4);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getClientsSales = function (idToken, nitParam, monthParam, yearParam) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_5;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    nit: nitParam,
                    month: monthParam,
                    year: yearParam,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/sales"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_5 = _a.sent();
                console.log(e_5);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getClientsResolves = function (idToken, nitParam, monthParam, yearParam) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_6;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    nit: nitParam,
                    month: monthParam,
                    year: yearParam,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/sections"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_6 = _a.sent();
                console.log(e_6);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getSummarySections = function (idToken, nitParam) { return __awaiter(void 0, void 0, void 0, function () {
    var reqOpt, response, e_7;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqOpt = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/sections/").concat(nitParam), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_7 = _a.sent();
                console.log(e_7);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getClientStatus = function (idToken, nit, month, year) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_8;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nit,
                    month: month,
                    year: year,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/getClientStatus"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_8 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var getAllStatus = function (idToken, month, year) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_9;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    month: month,
                    year: year,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken),
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/getAllStatus"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_9 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var enableNit = function (idToken, nit, enableValue) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_10;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nit,
                    enableValue: enableValue,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/enableclient"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_10 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var updateClientStatus = function (idToken, nit, month, year, formId, newStatus, // 'pending' | 'running' | 'validated' | 'frozen' | 'presented' | 'error',
newDocId) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_11;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nit,
                    month: month,
                    year: year,
                    formId: formId,
                    newStatus: newStatus,
                    newDocId: newDocId,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/clientStatusUpdate"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_11 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var updateDownloadStatus = function (idToken, nit, month, year, newPurchStatus, newSalesStatus, newRetenStatus) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_12;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nit,
                    month: month,
                    year: year,
                    newPurchStatus: newPurchStatus,
                    newSalesStatus: newSalesStatus,
                    newRetenStatus: newRetenStatus,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/clientStatusUpdate"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_12 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var discardDocument = function (idToken, isDiscarded, document_id) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_13;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    isDiscarded: isDiscarded,
                    documentId: document_id,
                };
                reqOpt = {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/documents/discard"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_13 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var getActivityLogs = function (idToken) { return __awaiter(void 0, void 0, void 0, function () {
    var reqOpt, response, data, errorMessage, e_14;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqOpt = {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/activity"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_14 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var updateDocumentMonth = function (idToken, month, year, document_id) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_15;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    month: parseInt(month),
                    year: year,
                    documentId: document_id,
                };
                reqOpt = {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/documents/month"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_15 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var updateDocumentItemsTax = function (idToken, nit, taxItems) { return __awaiter(void 0, void 0, void 0, function () {
    var reqOpt, response, data, errorMessage, e_16;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqOpt = {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify({
                        nit: nit,
                        taxItems: taxItems
                    })
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/documents/items"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_16 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var deleteDocument = function (idToken, documentId) { return __awaiter(void 0, void 0, void 0, function () {
    var reqOpt, response, data, errorMessage, e_17;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqOpt = {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/documents/").concat(documentId), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_17 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var createClient = function (idToken, nitParam, regimeIdParam, nameParam, hasMovement, password, nitDelegadoParam, isAutomated, download_pdf, companyId, credito_fiscal, folio_no, birthdate, purchasesDownloadDate, salesDownloadDate, categoryId, noMovePresentationDate) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_18;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nitParam,
                    regimeId: regimeIdParam,
                    name: nameParam,
                    hasMovement: hasMovement,
                    password: password !== "" ? password : null,
                    nitDelegado: nitDelegadoParam !== "" ? nitDelegadoParam : null,
                    isAutomated: isAutomated,
                    companyId: companyId,
                    creditoFiscal: credito_fiscal ? credito_fiscal : null,
                    folio_no: folio_no ? folio_no : null,
                    downloadPdf: download_pdf,
                    birthdate: birthdate ? birthdate : null,
                    purchasesDownloadDate: purchasesDownloadDate ? purchasesDownloadDate : null,
                    salesDownloadDate: salesDownloadDate ? salesDownloadDate : null,
                    categoryId: categoryId ? categoryId : null,
                    noMovePresentationDate: noMovePresentationDate ? noMovePresentationDate : null
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/clients"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_18 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var editClient = function (idToken, nitParam, regimeIdParam, nameParam, hasMovement, password, nitDelegadoParam, isAutomated, credito_fiscal, downloadPdf, folio_no, birthdate, purchasesDownloadDate, salesDownloadDate, categoryId, noMovePresentationDate) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_19;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = { nit: nitParam };
                if (regimeIdParam !== undefined)
                    reqBody["regimeId"] = regimeIdParam;
                if (nameParam)
                    reqBody["name"] = nameParam;
                if (hasMovement !== undefined)
                    reqBody["hasMovement"] = hasMovement;
                if (password)
                    reqBody["password"] = password;
                if (nitDelegadoParam)
                    reqBody["nitDelegado"] = nitDelegadoParam;
                if (isAutomated !== undefined)
                    reqBody["isAutomated"] = isAutomated;
                if (credito_fiscal !== undefined)
                    reqBody["creditoFiscal"] = credito_fiscal;
                if (downloadPdf !== undefined)
                    reqBody["downloadPdf"] = downloadPdf;
                if (birthdate)
                    reqBody["birthdate"] = birthdate;
                if (purchasesDownloadDate)
                    reqBody["purchasesDownloadDate"] = purchasesDownloadDate;
                if (salesDownloadDate)
                    reqBody["salesDownloadDate"] = salesDownloadDate;
                if (categoryId !== undefined)
                    reqBody["categoryId"] = categoryId;
                if (noMovePresentationDate)
                    reqBody["noMovePresentationDate"] = noMovePresentationDate;
                reqOpt = {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/clients"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_19 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var deleteClient = function (idToken, nitParam) { return __awaiter(void 0, void 0, void 0, function () {
    var reqOpt, response, e_20;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqOpt = {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/clients/").concat(nitParam), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_20 = _a.sent();
                console.log(e_20);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var editRemCredit = function (idToken, nitParam, monthParam, yearParam, value) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_21;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    nit: nitParam,
                    month: monthParam,
                    year: yearParam,
                    value: "".concat(value),
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/creditoFiscal"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_21 = _a.sent();
                console.log(e_21);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var editSummaryValue = function (idToken, nitParam, monthParam, yearParam, valueId, value) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_22;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nitParam,
                    month: monthParam,
                    year: yearParam,
                    valueId: valueId,
                    newValue: "".concat(value),
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/valueid"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_22 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var documentsEdit = function (idToken, action, documentIds) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_23;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    action: action,
                    documentIds: documentIds
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/documents/edit"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_23 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var setPastMonthResolves = function (idToken, nitParam, monthParam, yearParam) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_24;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nitParam,
                    month: monthParam,
                    year: yearParam,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/pastMonthResolves"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_24 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var editRemIVA = function (idToken, nitParam, monthParam, yearParam, value, regimeId) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_25;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nitParam,
                    month: monthParam,
                    year: yearParam,
                    value: "".concat(value),
                    regimeId: regimeId,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/retencionesIVA"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_25 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var calcFormsValues = function (idToken, nitParam, monthParam, yearParam) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_26;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nitParam,
                    month: monthParam,
                    year: yearParam,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/calcFormValues"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_26 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var getIvaRetentions = function (idToken, nitParam, monthParam, yearParam) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_27;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    nit: nitParam,
                    month: monthParam,
                    year: yearParam,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/retencionesRecibidasIVA"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_27 = _a.sent();
                console.log(e_27);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var getIsrRetentions = function (idToken, nitParam, monthParam, yearParam) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, e_28;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                reqBody = {
                    nit: nitParam,
                    month: monthParam,
                    year: yearParam,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/retencionesRecibidasISR"), reqOpt).then(function (resp) {
                        return resp.json();
                    }).then(function (data) {
                        return data;
                    })];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                e_28 = _a.sent();
                console.log(e_28);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); };
export var createRetention = function (idToken, documentId, nit, total, date, // yyyy-mm-dd
docTypeId, name, docNit) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_29;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    documentId: documentId,
                    nit: nit,
                    total: total,
                    date: date,
                    docTypeId: docTypeId,
                    name: name,
                    docNit: docNit,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken) // The token should include the company id
                    },
                    body: JSON.stringify(reqBody),
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/retention/create"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_29 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var prepareForm = function (idToken, nit, month, year, formId, regimeTaskName) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_30;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nit,
                    month: parseInt(month),
                    year: parseInt(year),
                    formId: formId,
                    regimeName: regimeTaskName,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/preparacionFormulario"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_30 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var presentForm = function (idToken, nit, month, year, formId, regimeTaskName) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_31;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nit,
                    month: parseInt(month),
                    year: parseInt(year),
                    formId: formId,
                    regimeName: regimeTaskName,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/presentacionFormulario"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_31 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
export var createBotTask = function (idToken, nit, task) { return __awaiter(void 0, void 0, void 0, function () {
    var reqBody, reqOpt, response, data, errorMessage, e_32;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 6, , 7]);
                reqBody = {
                    nit: nit,
                    type: task,
                };
                reqOpt = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": "Bearer ".concat(idToken), // The token should include the company id
                    },
                    body: JSON.stringify(reqBody)
                };
                return [4 /*yield*/, fetch("".concat(api_url, "/downloadNitsDocs"), reqOpt)];
            case 1:
                response = _a.sent();
                if (!response.ok) return [3 /*break*/, 3];
                return [4 /*yield*/, response.json()];
            case 2:
                data = _a.sent();
                return [2 /*return*/, data];
            case 3: return [4 /*yield*/, response.json()];
            case 4:
                errorMessage = _a.sent();
                return [2 /*return*/, { message: errorMessage.message, error: true }];
            case 5: return [3 /*break*/, 7];
            case 6:
                e_32 = _a.sent();
                return [2 /*return*/, { message: "Error de servidor.", error: true }];
            case 7: return [2 /*return*/];
        }
    });
}); };
