import React from "react";
import { createBrowserRouter } from "react-router-dom";
import AuthGuardian from "../AuthGuardian";
import Login from "../pages/Login/Login";
import Admin from "../pages/Admin/Admin";
import NotFound from "../pages/NotFound/NotFound";
import Home from "../pages/Home/Home";
import AdminUsers from "../pages/Admin/AdminUsers/AdminUsers";
import AdminClients from "../pages/Admin/AdminClients/AdminClients";
import CreateUser from "../pages/Admin/CreateUser/CreateUser";
import CreateClient from "../pages/Admin/CreateClient/CreateClient";
import Purchases from "../pages/Bills/Purchases";
import Sales from "../pages/Bills/Sales";
import Summary from "../pages/Summary/Summary";
import AdminCompanies from "../pages/Admin/AdminCompanies/AdminCompanies";
import { Roles } from "../shared/constants";
import Others from "../pages/Other/Others";
import AdminActivity from "../pages/Admin/AdminActivity/AdminActivity";
import CreateCompany from "../pages/Admin/CreateCompany/CreateCompany";
import ClientStatus from "../pages/ClientStatus/ClientStatus";
import Forms from "../pages/Forms/Forms";
import AdminDashboard from "../pages/Admin/AdminDashboard/AdminDashboard";
export var router = createBrowserRouter([
    // This array is to add new routes to the app.
    {
        path: '/',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(Home, null), authentication: [Roles.Superadmin, Roles.Admin, Roles.User] }),
        errorElement: React.createElement(NotFound, null)
    },
    {
        path: '/login',
        element: React.createElement(Login, null),
    },
    {
        path: '/admin',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(Admin, null), authentication: [Roles.Admin, Roles.Superadmin] }),
    },
    {
        path: '/admin/users',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(AdminUsers, null), authentication: [Roles.Admin, Roles.Superadmin] }),
    },
    {
        path: '/admin/clients',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(AdminClients, null), authentication: [Roles.Admin, Roles.Superadmin] }),
    },
    {
        path: '/admin/client/create',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(CreateClient, null), authentication: [Roles.Admin, Roles.Superadmin] }),
    },
    {
        path: '/admin/client/edit/:nit',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(CreateClient, null), authentication: [Roles.Admin, Roles.Superadmin] }),
    },
    {
        path: '/admin/companies',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(AdminCompanies, null), authentication: [Roles.Superadmin] }),
    },
    {
        path: '/admin/company/create',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(CreateCompany, null), authentication: [Roles.Superadmin] }),
    },
    {
        path: '/admin/company/edit/:id',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(CreateCompany, null), authentication: [Roles.Superadmin] }),
    },
    {
        path: '/admin/activity',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(AdminActivity, null), authentication: [Roles.Admin, Roles.Superadmin] }),
    },
    {
        path: '/admin/user/create',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(CreateUser, null), authentication: [Roles.Admin, Roles.Superadmin] }),
    },
    {
        path: '/admin/dashboard/:year/:month',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(AdminDashboard, null), authentication: [Roles.Superadmin] }),
    },
    {
        path: '/purchases/:nit/:year/:month',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(Purchases, null), authentication: [Roles.Superadmin, Roles.Admin, Roles.User] }),
    },
    {
        path: '/sales/:nit/:year/:month',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(Sales, null), authentication: [Roles.Superadmin, Roles.Admin, Roles.User] }),
    },
    {
        path: '/summary/:nit/:year/:month',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(Summary, null), authentication: [Roles.Superadmin, Roles.Admin, Roles.User] }),
    },
    {
        path: '/others/:nit/:year/:month',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(Others, null), authentication: [Roles.Superadmin, Roles.Admin, Roles.User] }),
    },
    {
        path: '/status/:nit/:year/:month',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(ClientStatus, null), authentication: [Roles.Superadmin, Roles.Admin, Roles.User] }),
    },
    {
        path: '/forms/:nit/:year/:month',
        element: React.createElement(AuthGuardian, { RenderComponent: React.createElement(Forms, null), authentication: [Roles.Superadmin, Roles.Admin, Roles.User] }),
    },
    // {
    //   path: '/pdftest',
    //   element: <AuthGuardian 
    //     RenderComponent={
    //       <PDFViewer style={{width: '100vw', height: 'calc(100vh - 7vw)', marginTop: '6.25vw'}}>
    //         <LittleBookGenerator rows={[
    //           {
    //             docId: 'fefefef-dsdsds-fdsfsfs-efefe',
    //             emision: '10/03/2024',
    //             type: 'FACT',
    //             serial: '123456789',
    //             number: '987654321',
    //             nit: '111530539',
    //             name: 'Martin España Soluciones Internacionales Elevadas sobre la Industria de Videojuegos S.A.',
    //             total: '799.99',
    //             isPurchase: 1
    //           },
    //           {
    //             docId: 'fefefef-dsdsds-fdsfsfs-efefe',
    //             emision: '11/03/2024',
    //             type: 'FACT',
    //             serial: '123456789',
    //             number: '987654321',
    //             nit: '111530539',
    //             name: 'Sobre la Industria de Videojuegos S.A.',
    //             total: '1257.99',
    //             isPurchase: 1
    //           },
    //           {
    //             docId: 'fefefef-dsdsds-fdsfsfs-efefe',
    //             emision: '12/03/2024',
    //             type: 'RDON',
    //             serial: '123456789',
    //             number: '987654321',
    //             nit: '111530539',
    //             name: 'Martin España de Videojuegos S.A.',
    //             total: '31.99',
    //             isPurchase: 1
    //           },
    //           {
    //             docId: 'fefefef-dsdsds-fdsfsfs-efefe',
    //             emision: '11/03/2024',
    //             type: 'FACT',
    //             serial: '123456789',
    //             number: '987654321',
    //             nit: '111530539',
    //             name: 'Martin España Soluciones Internacionales Elevadas sobre la Industria de Videojuegos S.A.',
    //             total: '565.99',
    //             isPurchase: 0
    //           },
    //           {
    //             docId: 'fefefef-dsdsds-fdsfsfs-efefe',
    //             emision: '15/03/2024',
    //             type: 'FACT',
    //             serial: '123456789',
    //             number: '987654321',
    //             nit: '111530539',
    //             name: 'Sobre la Industria de Videojuegos S.A.',
    //             total: 112.00,
    //             isPurchase: 0,
    //             taxes: 12.00,
    //           },
    //           {
    //             docId: 'fefefef-dsdsds-fdsfsfs-efefe',
    //             emision: '20/03/2024',
    //             type: 'FACT',
    //             serial: '123456789',
    //             number: '987654321',
    //             nit: '111530539',
    //             name: 'Martin España de Videojuegos S.A.',
    //             total: '19.99',
    //             isPurchase: 0
    //           },
    //         ]}
    //           nit={'111530539'}
    //           month="3"
    //           year="2024"
    //           name="Martin Eduardo España Rivera"
    //         />
    //       </PDFViewer>
    //     }
    //   />,
    // },
    // {
    //   path: '/pdfcookie',
    //   element: <AuthGuardian 
    //     RenderComponent={
    //       <iframe
    //         style={{marginTop: "10vw"}}
    //         src="https://d2mr0jezqntoqh.cloudfront.net/fe9e8c8b-6a59-4965-8ab9-c98d973aa57e/0ccd9344-cfb1-4082-9a5c-0891662c6b08.pdf"
    //       ></iframe>
    //     }
    //   />,
    // },
]);
