import React from "react";
import { Link } from "react-router-dom";
import logo from "../../assets/logo-login.svg";
import "./NoPermissions.scss";
var NoPermissions = function () {
    return (React.createElement("div", { className: "noPermissionsContainer" },
        React.createElement("img", { className: "noPermissionsLogo", src: logo }),
        React.createElement("div", { className: "noPermissionsTitle" }, "No tienes permiso para acceder esta p\u00E1gina."),
        React.createElement("div", { className: "noPermissionsSubTitle" }, "Ponte en contacto con un administrador si crees que se trata de un error o"),
        React.createElement(Link, { className: "noPermissionsLink", to: "/" }, "vuelve al men\u00FA principal.")));
};
export default NoPermissions;
