var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import filterIcon from "../../assets/icons/whiteFilter.png";
import filledFilterIcon from "../../assets/icons/whiteFilledFilter.png";
import "./FilterBar.scss";
import { ActionTypes, Actions, DataTypes, FilterTypes, toastConfig } from "../../shared/constants";
import TableActions from "../TableActions/TableActions";
import { toast } from "react-toastify";
import { createDateFromDMYFormat, formatCompanyName } from "../../shared/utils";
var FilterBar = function (props) {
    var keyList = props.keyList, onFilter = props.onFilter, dataToFilter = props.dataToFilter, originalData = props.originalData, resetFilters = props.resetFilters, setResetFilters = props.setResetFilters, setFiltersActive = props.setFiltersActive;
    var _a = useState(false), isActive = _a[0], setIsActive = _a[1];
    var _b = useState(undefined), currentField = _b[0], setCurrentField = _b[1];
    var _c = useState(FilterTypes.equal), currentOp = _c[0], setCurrentOp = _c[1];
    var _d = useState(""), currentVal = _d[0], setCurrentVal = _d[1];
    var _e = useState(""), currentDropVal = _e[0], setCurrentDropVal = _e[1];
    var _f = useState(new Date().toISOString().split("T")[0] + "T00:00"), currentDateVal = _f[0], setCurrentDateVal = _f[1];
    var _g = useState([]), filterList = _g[0], setFilterList = _g[1];
    var initFilterBar = function () {
        var _a;
        if (keyList.length > 0) {
            setCurrentField(keyList[0]);
            if (keyList[0].options && ((_a = keyList[0].options) === null || _a === void 0 ? void 0 : _a.length) > 0) {
                setCurrentDropVal(keyList[0].options[0]);
            }
        }
    };
    var addFilter = function () {
        if (currentField) {
            var newFilter_1 = {
                value: currentField.dataType === DataTypes.String ? (currentField.options ? currentDropVal : currentVal) : currentDateVal,
                op: currentOp,
                field: currentField
            };
            if (filterList.find(function (elem) { return elem.field.keyName === newFilter_1.field.keyName
                && elem.op === newFilter_1.op
                && elem.value === newFilter_1.value; })) {
                toast("Este filtro ya existe", __assign(__assign({}, toastConfig), { type: "warning" }));
            }
            else {
                if ((dataToFilter === null || dataToFilter === void 0 ? void 0 : dataToFilter.length) > 1) {
                    var newFiltered = applyFilter(newFilter_1, dataToFilter);
                    if (filterList.length === 0) {
                        setIsActive(true);
                        if (setFiltersActive)
                            setFiltersActive(true);
                    }
                    onFilter(newFiltered);
                    setFilterList(function (prev) { return __spreadArray(__spreadArray([], prev, true), [newFilter_1], false); });
                }
                else {
                    toast("No se pueden agregar más filtros", __assign(__assign({}, toastConfig), { type: "warning" }));
                }
            }
        }
    };
    var applyFilter = function (newFilter, filterApplyData) {
        var newFiltered = [];
        if (newFilter.field.dataType === DataTypes.String) {
            newFiltered = filterApplyData.filter(function (elem) { return formatCompanyName(elem[newFilter.field.keyName]).includes(formatCompanyName(newFilter.value)); });
        }
        else {
            var compDate_1 = new Date(newFilter.value);
            if (newFilter.op === FilterTypes.equal) {
                newFiltered = filterApplyData.filter(function (elem) {
                    var elemDate = createDateFromDMYFormat(elem[newFilter.field.keyName]);
                    if (compDate_1.getFullYear() === elemDate.getFullYear()
                        && compDate_1.getMonth() === elemDate.getMonth()
                        && compDate_1.getDate() === elemDate.getDate()) {
                        return elem;
                    }
                });
            }
            else if (newFilter.op === FilterTypes.greaterThan) {
                newFiltered = filterApplyData.filter(function (elem) {
                    var elemDate = createDateFromDMYFormat(elem[newFilter.field.keyName]);
                    if (elemDate > compDate_1) {
                        return elem;
                    }
                });
            }
            else if (newFilter.op === FilterTypes.greaterThanOrEqual) {
                newFiltered = filterApplyData.filter(function (elem) {
                    var elemDate = createDateFromDMYFormat(elem[newFilter.field.keyName]);
                    if (elemDate >= compDate_1) {
                        return elem;
                    }
                });
            }
            else if (newFilter.op === FilterTypes.lessThan) {
                newFiltered = filterApplyData.filter(function (elem) {
                    var elemDate = createDateFromDMYFormat(elem[newFilter.field.keyName]);
                    if (elemDate < compDate_1) {
                        return elem;
                    }
                });
            }
            else if (newFilter.op === FilterTypes.lessThanOrEqual) {
                newFiltered = filterApplyData.filter(function (elem) {
                    var elemDate = createDateFromDMYFormat(elem[newFilter.field.keyName]);
                    if (elemDate <= compDate_1) {
                        return elem;
                    }
                });
            }
        }
        return newFiltered;
    };
    var removeFilter = function (toRemove) {
        var newStateCopy = __spreadArray([], filterList, true);
        newStateCopy.splice(toRemove, 1);
        if (newStateCopy.length > 0) {
            newStateCopy.forEach(function (filt, ind) {
                onFilter(applyFilter(filt, ind === 0 ? originalData : dataToFilter));
            });
        }
        else {
            onFilter(originalData);
        }
        if (newStateCopy.length === 0) {
            setIsActive(false);
        }
        setFilterList(newStateCopy);
    };
    var removeAllFilters = function () {
        setFilterList([]);
        onFilter(originalData);
        setIsActive(false);
        if (setFiltersActive)
            setFiltersActive(false);
    };
    useEffect(function () {
        initFilterBar();
    }, []);
    useEffect(function () {
        if (resetFilters) {
            filterList.forEach(function (filt, ind) {
                onFilter(applyFilter(filt, ind === 0 ? originalData : dataToFilter));
            });
            if (setResetFilters) {
                setResetFilters(false);
            }
        }
    }, [resetFilters]);
    return (React.createElement("div", { className: "filtersParentContainer" },
        React.createElement("div", { className: "filterBarContainer" },
            React.createElement("button", { className: "filterBarButton", title: isActive ? "Limpiar Filtros" : "Agregar Filtros", onClick: isActive ? function () { return removeAllFilters(); } : undefined, style: { cursor: isActive ? "pointer" : undefined } },
                React.createElement("img", { src: isActive ? filledFilterIcon : filterIcon })),
            React.createElement("div", { style: { display: "flex", flexDirection: "row", gap: "1vw" } },
                React.createElement("select", { className: "filterTransDropdown", onChange: function (ev) {
                        // setCurrentVal("");
                        // setCurrentDropVal("");
                        setCurrentOp(FilterTypes.equal);
                        setCurrentField(keyList.find(function (elem) { return elem.keyName === ev.target.value; }));
                    } }, keyList.map(function (elem, ind) {
                    return (React.createElement("option", { key: "filterOpt-".concat(ind, "-").concat(elem.keyName), value: elem.keyName }, elem.displayName));
                })),
                React.createElement("select", { className: "filterTransDropdown", value: currentOp, onChange: function (ev) { return setCurrentOp(ev.target.value); } },
                    React.createElement("option", { value: FilterTypes.equal }, "Es igual a"),
                    (currentField === null || currentField === void 0 ? void 0 : currentField.dataType) !== DataTypes.String &&
                        React.createElement(React.Fragment, null,
                            React.createElement("option", { value: FilterTypes.greaterThan }, "Es mayor que"),
                            React.createElement("option", { value: FilterTypes.greaterThanOrEqual }, "Es mayor o igual a"),
                            React.createElement("option", { value: FilterTypes.lessThan }, "Es menor que"),
                            React.createElement("option", { value: FilterTypes.lessThanOrEqual }, "Es menor o igual a"))),
                (currentField === null || currentField === void 0 ? void 0 : currentField.options)
                    ?
                        React.createElement("select", { className: "filterTransDropdown", value: currentDropVal, onChange: function (ev) { return setCurrentDropVal(ev.target.value); } }, currentField.options.map(function (valOpt, ind) {
                            return (React.createElement("option", { key: "filterValOpt-".concat(ind), value: valOpt }, valOpt));
                        }))
                    :
                        (currentField === null || currentField === void 0 ? void 0 : currentField.dataType) === DataTypes.Datetime
                            ? React.createElement("input", { className: "filterBarTransInput", type: "datetime-local", value: currentDateVal, onChange: function (ev) { return setCurrentDateVal(ev.target.value); } })
                            : React.createElement("input", { className: "filterBarTransInput", value: currentVal, onChange: function (ev) { return setCurrentVal(ev.target.value); } })),
            React.createElement("button", { className: "filterFilledButton", onClick: function () { return addFilter(); } }, "Agregar Filtro")),
        filterList.map(function (filter, ind) {
            return (React.createElement("div", { key: "indFilter-".concat(ind, "-").concat(filter.op), className: "filterBarContainer" },
                React.createElement("div", { className: "individualFilterHeader" }, "".concat(ind + 1)),
                React.createElement("div", { className: "individualFilter" }, "".concat(filter.field.displayName, " ").concat(filter.op, " ").concat(filter.field.dataType === DataTypes.String ? filter.value : new Date(filter.value).toDateString())),
                React.createElement(TableActions, { actionList: [
                        {
                            action: Actions.Close,
                            titleText: "Quitar filtro",
                            altText: "quitar",
                            type: ActionTypes.Button,
                            iconWidth: "1vw",
                            iconMargin: "0.3vw 0vw 0vw 0vw",
                            onClick: function () { return removeFilter(ind); }
                        }
                    ] })));
        })));
};
export default FilterBar;
