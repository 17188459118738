import React, { Fragment, useRef } from "react";
import editIcon from "../../assets/icons/editIcon.png";
import deleteIcon from "../../assets/icons/deleteIcon.png";
import acceptIcon from "../../assets/icons/acceptIcon.png";
import closeIcon from "../../assets/icons/closeIcon.png";
import arrowRightIcon from "../../assets/icons/arrwoRightIcon.png";
import arrowLeftIcon from "../../assets/icons/arrowLeftIcon.png";
import pdfIcon from "../../assets/icons/pdfIcon.png";
// import pdfIcon from "../../assets/icons/pdfIcon_blue.png";
import uploadFileIcon from "../../assets/icons/uploadIcon.png";
import discardIcon from "../../assets/icons/discardIcon.png";
import returnIconImage from "../../assets/icons/returnIcon.png";
import disableIcon from "../../assets/icons/pendingIcon.png";
import enableIcon from "../../assets/icons/doneIcon.png";
import { Actions, ActionTypes } from "../../shared/constants";
import "./TableActions.scss";
var TableActions = function (props) {
    var actionList = props.actionList;
    var defaultIconWidth = "1.2vw";
    var fileInputRef = useRef(null);
    var getIcon = function (actionSelected) {
        var returnIcon = editIcon;
        switch (actionSelected) {
            case Actions.Edit:
                returnIcon = editIcon;
                break;
            case Actions.Delete:
                returnIcon = deleteIcon;
                break;
            case Actions.Accept:
                returnIcon = acceptIcon;
                break;
            case Actions.Close:
                returnIcon = closeIcon;
                break;
            case Actions.MoveLeft:
                returnIcon = arrowLeftIcon;
                break;
            case Actions.MoveRight:
                returnIcon = arrowRightIcon;
                break;
            case Actions.Dismiss:
                returnIcon = discardIcon;
                break;
            case Actions.PDF:
                returnIcon = pdfIcon;
                break;
            case Actions.UploadFile:
                returnIcon = uploadFileIcon;
                break;
            case Actions.Return:
                returnIcon = returnIconImage;
                break;
            case Actions.Enable:
                returnIcon = enableIcon;
                break;
            case Actions.Disable:
                returnIcon = disableIcon;
                break;
        }
        return returnIcon;
    };
    var onKeyboardChanges = function (ev, enterCallback) {
        if (ev.key === "Enter") {
            if (enterCallback)
                enterCallback();
        }
    };
    return (React.createElement("div", { className: "actionsContainer" }, actionList.map(function (actElem, actInd) {
        if (actElem.type === ActionTypes.Button || actElem.type === undefined) {
            return (React.createElement("div", { key: "".concat(actElem.action, "_").concat(actInd, "_").concat(actElem.keyId || new Date().getTime()), className: "actionIconContainer", onClick: actElem.onClick ? function () { return actElem.onClick(); } : undefined, title: actElem.titleText, tabIndex: 0, onKeyDown: function (ev) { return onKeyboardChanges(ev, actElem.onClick ? function () { return actElem.onClick(); } : function () { }); }, draggable: false },
                React.createElement("img", { src: getIcon(actElem.action), alt: actElem.altText, draggable: false, style: {
                        width: actElem.iconWidth ? actElem.iconWidth : defaultIconWidth,
                        margin: actElem.iconMargin ? actElem.iconMargin : undefined,
                    } })));
        }
        else if (actElem.type === ActionTypes.Link) {
            return (React.createElement("a", { key: "".concat(actElem.action, "_").concat(actInd, "_").concat(actElem.type, "_").concat(actElem.keyId || new Date().getTime()), href: actElem.navigationURL || "", target: "_blank", className: "actionIconContainer", onClick: actElem.onClick ? function () { return actElem.onClick(); } : undefined, title: actElem.titleText, draggable: false },
                React.createElement("img", { src: getIcon(actElem.action), alt: actElem.altText, draggable: false, style: {
                        width: actElem.iconWidth ? actElem.iconWidth : defaultIconWidth,
                        margin: actElem.iconMargin ? actElem.iconMargin : undefined,
                    } })));
        }
        else if (actElem.type === ActionTypes.FileExplorer) {
            return (React.createElement("div", { key: "".concat(actElem.action, "_").concat(actInd, "_").concat(actElem.type, "_").concat(actElem.keyId || new Date().getTime()), className: "actionIconContainer", onClick: function () {
                    if (fileInputRef) {
                        fileInputRef.current.click();
                    }
                }, tabIndex: 0, onKeyDown: function (ev) { return onKeyboardChanges(ev, function () {
                    if (fileInputRef)
                        fileInputRef.current.click();
                }); }, title: actElem.titleText, draggable: false },
                React.createElement("input", { type: "file", accept: actElem.acceptExtensions, onChange: function (ev) { return actElem.onFileChange(ev); }, ref: fileInputRef, style: { display: 'none' } }),
                React.createElement("img", { src: getIcon(actElem.action), alt: actElem.altText, draggable: false, style: {
                        width: actElem.iconWidth ? actElem.iconWidth : defaultIconWidth,
                        margin: actElem.iconMargin ? actElem.iconMargin : undefined,
                    } })));
        }
        else {
            return (React.createElement(Fragment, { key: "".concat(actElem.action, "_").concat(actInd, "_").concat(actElem.type, "_").concat(actElem.keyId || new Date().getTime()) }));
        }
    })));
};
export default TableActions;
