var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useRef, useState } from 'react';
import "./AccordionTable.scss";
// @ts-ignore
import LazyLoad from 'react-lazyload';
import RowPlaceholder from '../RowPlaceholder/RowPlaceholder';
// Table component that supports expandable rows
var AccordionTable = function (props) {
    var cols = props.cols, rows = props.rows, lastRow = props.lastRow, width = props.width, expandedRows = props.expandedRows, setExpandedRows = props.setExpandedRows;
    var _a = useState(undefined), expandedRow = _a[0], setExpandedRow = _a[1];
    var tableRef = useRef(null);
    var toggleRow = function (rowIndex) {
        if (expandedRows && setExpandedRows) {
            var stateCopy = __spreadArray([], expandedRows, true);
            stateCopy[rowIndex] = !expandedRows[rowIndex];
            setExpandedRows(stateCopy);
        }
        else {
            if (expandedRow === rowIndex) {
                setExpandedRow(null);
            }
            else {
                setExpandedRow(rowIndex);
            }
        }
    };
    var calcEqualColWidth = function () {
        var totalWidth = 100;
        var totalCols = cols.length;
        cols.forEach(function (col, colInd) {
            if (col.widthPercent !== undefined) {
                totalWidth -= col.widthPercent;
                totalCols -= 1;
            }
        });
        return Math.round(totalWidth / totalCols);
    };
    return (React.createElement("div", { className: 'accordionTableContainer', style: { width: width || "80vw" } },
        React.createElement("div", { className: 'accordionTableHeader ' }, cols.map(function (col, colInd) {
            return (React.createElement("div", { key: "acc-header-col-".concat(colInd), className: 'accordionTableHeaderColumn', style: {
                    width: col.widthPercent ? "".concat(col.widthPercent, "%") : "".concat(calcEqualColWidth(), "%"),
                    cursor: col.onClick ? "pointer" : undefined
                }, onClick: col.onClick ? function () { return col.onClick(); } : undefined }, col.display));
        })),
        React.createElement("div", { className: 'accordionTableBody' },
            rows.map(function (row, rowInd) {
                return (React.createElement(React.Fragment, { key: "acc-row-".concat(rowInd) },
                    rowInd < 300 ? (React.createElement("div", { className: 'accordionTableRow' }, row.colsValues.map(function (rowCol, rowColInd) {
                        return (React.createElement("div", { key: "acc-row-col-".concat(rowInd, "-").concat(rowColInd), className: 'accordionTableRowCol', onClick: rowCol.avoidClickExpansion ? undefined : function () { return toggleRow(rowInd); }, style: {
                                width: rowCol.widthPercent ? "".concat(rowCol.widthPercent, "%") : "".concat(calcEqualColWidth(), "%"),
                                fontSize: rowCol.fontSize ? "".concat(rowCol.fontSize, "vw") : undefined,
                            } },
                            React.createElement("div", null, rowCol.elem)));
                    }))) : (React.createElement(LazyLoad, { debounce: 100, height: 50, offset: 500, scrollContainer: ".accordionTableBody", once: true, placeholder: React.createElement(RowPlaceholder, null) },
                        React.createElement("div", { className: 'accordionTableRow', id: "accordionTableRowRef" }, row.colsValues.map(function (rowCol, rowColInd) {
                            return (React.createElement("div", { key: "acc-row-col-".concat(rowInd, "-").concat(rowColInd), className: 'accordionTableRowCol', onClick: rowCol.avoidClickExpansion ? undefined : function () { return toggleRow(rowInd); }, style: {
                                    width: rowCol.widthPercent ? "".concat(rowCol.widthPercent, "%") : "".concat(calcEqualColWidth(), "%"),
                                    fontSize: rowCol.fontSize ? "".concat(rowCol.fontSize, "vw") : undefined,
                                } },
                                React.createElement("div", null, rowCol.elem)));
                        })))),
                    (expandedRows && expandedRows[rowInd]) || (!expandedRows && expandedRow === rowInd) ? (React.createElement(React.Fragment, null, row.items.map(function (subRow, subRowInd) {
                        return (React.createElement(LazyLoad, { key: "acc-subrow-".concat(subRowInd), offset: 500, debounce: 100, height: 50, once: true, placeholder: React.createElement(RowPlaceholder, null) },
                            React.createElement("div", { className: "accordionTableSubRow" }, subRow.map(function (subRowCol, subRowColInd) {
                                return (React.createElement("div", { key: "acc-subrow-col-".concat(subRowInd, "-").concat(subRowColInd), className: 'accordionTableRowCol', onClick: subRowCol.onClick ? function () { return subRowCol.onClick(); } : undefined, style: {
                                        width: subRowCol.widthPercent ? "".concat(subRowCol.widthPercent, "%") : "".concat(calcEqualColWidth(), "%"),
                                        fontSize: subRowCol.fontSize ? "".concat(subRowCol.fontSize, "vw") : undefined,
                                        color: subRowCol.fontColor || undefined,
                                        cursor: subRowCol.onClick ? "pointer" : undefined,
                                        backgroundColor: subRowCol.backgroundColor || undefined,
                                    } },
                                    React.createElement("div", null, subRowCol.elem)));
                            }))));
                    }))) : (React.createElement(React.Fragment, null))));
            }),
            React.createElement("div", { className: 'accordionTableLastRow' }, lastRow &&
                React.createElement("div", { className: 'accordionTableRow' }, lastRow.colsValues.map(function (rowCol, rowColInd) {
                    return (React.createElement("div", { key: "acc-last-row-col-".concat(rowColInd), className: 'accordionTableRowCol', style: {
                            width: rowCol.widthPercent ? "".concat(rowCol.widthPercent, "%") : "".concat(calcEqualColWidth(), "%"),
                            fontSize: rowCol.fontSize ? "".concat(rowCol.fontSize, "vw") : undefined,
                            color: rowCol.fontColor ? rowCol.fontColor : undefined,
                            backgroundColor: '#c4bfbe' ? '#c4bfbe' : undefined,
                            fontWeight: rowCol.fontWeight ? rowCol.fontWeight : undefined,
                        } },
                        React.createElement("div", null, rowCol.elem)));
                }))))));
};
export default AccordionTable;
