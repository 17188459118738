var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { loggedUser, SET_USER_LOGGED_OUT } from "../../UserContext/UserReducer";
import { useSelector } from "react-redux/es/hooks/useSelector";
import { Roles, popupConfig } from "../../shared/constants";
import logo from "../../assets/logo-login.svg";
import arrowDownIcon from "../../assets/icons/fullArrowDownIcon.png";
import arrowUpIcon from "../../assets/icons/fullArrowUpIcon.png";
import arrowUpSelectedIcon from "../../assets/icons/fullSelectedArrowUpIcon.png";
import Swal from "sweetalert2";
import "./Navbar.scss";
import { clearAllCookies } from "../../shared/utils";
import { getClient } from "../../services/clientsService";
var Navbar = function () {
    var userData = useSelector(loggedUser);
    var location = useLocation();
    var params = useParams();
    var dispatch = useDispatch();
    var _a = useState([]), optionsHoveredPure = _a[0], setOptionsHoveredPure = _a[1];
    var _b = useState([]), optionsHovered = _b[0], setOptionsHovered = _b[1];
    var _c = useState(false), subOptionsHovered = _c[0], setSubOptionsHovered = _c[1];
    var _d = useState(undefined), clientData = _d[0], setClientData = _d[1];
    var navOptions = [
        {
            icon: undefined,
            name: "Menú de Opciones",
            subOptions: [
                {
                    name: "Estado del Cliente",
                    path: "/status/".concat(params.nit || "0", "/").concat(params.year || 2024, "/").concat(params.month || 1)
                },
                {
                    name: "Resumen del Mes",
                    icon: undefined,
                    path: "/summary/".concat(params.nit || "0", "/").concat(params.year || 2024, "/").concat(params.month || 1)
                },
                {
                    name: "Formularios",
                    path: "/forms/".concat(params.nit || "0", "/").concat(params.year || 2024, "/").concat(params.month || 1)
                },
                {
                    name: "Compras",
                    icon: undefined,
                    path: "/purchases/".concat(params.nit || "0", "/").concat(params.year || 2024, "/").concat(params.month || 1),
                    regimes: [1, 2], // All but Peq Contribuyente
                },
                {
                    name: "Ventas",
                    icon: undefined,
                    path: "/sales/".concat(params.nit || "0", "/").concat(params.year || 2024, "/").concat(params.month || 1)
                },
                {
                    name: "Retenciones",
                    icon: undefined,
                    path: "/others/".concat(params.nit || "0", "/").concat(params.year || 2024, "/").concat(params.month || 1)
                },
            ],
            access: [Roles.Superadmin, Roles.Admin, Roles.User],
            accessPaths: ["summary", "purchases", "sales", "others", "status", "forms"], // Only will show this if any of this paths is in the location pathname
        },
        {
            icon: undefined,
            name: "Administración",
            path: "/admin",
            access: [Roles.Superadmin, Roles.Admin],
        },
    ];
    var initNavbar = function () {
        var newState = [];
        navOptions.forEach(function () {
            newState.push(false);
        });
        setOptionsHovered(newState);
        setOptionsHoveredPure(newState);
    };
    var initNavbarClient = function () { return __awaiter(void 0, void 0, void 0, function () {
        var clientResp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getClient(userData.idToken, params.nit)];
                case 1:
                    clientResp = _a.sent();
                    if (!clientResp.error) {
                        setClientData(clientResp);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var changeOptionHoverState = function (index, value) {
        var newArray = __spreadArray([], optionsHovered, true);
        newArray[index] = value || !newArray[index];
        setOptionsHovered(newArray);
    };
    var changeOptionHoverPureState = function (index, value) {
        var newArray = __spreadArray([], optionsHovered, true);
        newArray[index] = value || !newArray[index];
        setOptionsHoveredPure(newArray);
    };
    var pathContains = function (pathList) {
        var contains = false;
        pathList.forEach(function (path) {
            if (location.pathname.includes(path)) {
                contains = true;
            }
        });
        return contains;
    };
    useEffect(function () {
        initNavbar();
    }, []);
    useEffect(function () {
        if (params.nit) {
            initNavbarClient();
        }
        else {
            setClientData(undefined);
        }
    }, [params.nit]);
    return (React.createElement("div", { className: "navbarContainer" },
        React.createElement("div", { className: "navbarSubcontainerLeft" },
            React.createElement(Link, { className: "navbarLogo", to: "/" },
                React.createElement("img", { src: logo, alt: "logo" }))),
        React.createElement("div", { className: "navbarSubcontainerCenter" }, navOptions.map(function (elem, ind) {
            return (elem.access === undefined ||
                (elem.access.includes(userData.role)) &&
                    (elem.accessPaths === undefined || pathContains(elem.accessPaths)))
                ? elem.subOptions
                    ? (React.createElement("div", { key: elem.name.replace(" ", "") + "-".concat(ind), className: "navbarComplexOptionContainer", onMouseEnter: function () { return changeOptionHoverState(ind, true); }, onMouseLeave: function () { return changeOptionHoverState(ind, false); } },
                        React.createElement("div", { className: "navbarComplexOptionDropdown", style: {
                                display: optionsHovered[ind] ? "block" : "inline-block",
                            } },
                            React.createElement("div", { key: elem.name.replace(" ", "_") + "".concat(ind), className: "navbarOption", tabIndex: 0, onMouseEnter: function () { return changeOptionHoverPureState(ind, true); }, onMouseLeave: function () { return changeOptionHoverPureState(ind, false); } },
                                elem.name,
                                React.createElement("img", { style: { width: "1vw", marginLeft: "0.5vw" }, src: optionsHovered[ind] ? (optionsHoveredPure[ind] ? arrowUpSelectedIcon : arrowUpIcon) : arrowDownIcon, alt: "" })),
                            optionsHovered[ind] &&
                                React.createElement("div", { className: "navbarSuboptionsContainer", style: {
                                        display: optionsHovered[ind] ? "block" : "none"
                                    } }, elem.subOptions.map(function (subOpt, subOptInd) {
                                    return location.pathname.includes(subOpt.path) ||
                                        (subOpt.regimes && clientData && !subOpt.regimes.includes(clientData.regime_id))
                                        ? React.createElement("div", { key: subOpt.path.replace("/", "_") + "".concat(ind) + "".concat(subOptInd) }) : (React.createElement(Link, { key: subOpt.path.replace("/", "_") + "".concat(ind) + "".concat(subOptInd), className: "navbarSuboption", to: subOpt.path, onClick: function () { return changeOptionHoverState(ind); } }, subOpt.name));
                                })))))
                    : (React.createElement(Link, { key: elem.path.replace("/", "_") + "".concat(ind), className: "navbarOption ".concat(elem.path === location.pathname ? "navbarSelectedOption" : ""), tabIndex: 0, to: elem.path }, elem.name))
                :
                    React.createElement("div", { key: "yetAnotherMappingKey-".concat(ind) });
        })),
        React.createElement("div", { className: "navbarSubcontainerRight" },
            React.createElement("button", { className: "navbarLogoutButton", onClick: function () {
                    Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Seguro?', html: "\u00BFQuieres cerrar sesi\u00F3n?", icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, cerrar sesión', cancelButtonText: 'Cancelar' })).then(function (result) {
                        if (result.isConfirmed) {
                            dispatch(SET_USER_LOGGED_OUT(loggedUser));
                            clearAllCookies();
                        }
                    });
                } }, "Cerrar Sesi\u00F3n"))));
};
export default Navbar;
