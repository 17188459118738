var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from "react";
import "./NewDocPopup.scss";
import { toast } from "react-toastify";
import { generaIvaTaxRate, littleContributorTaxRate, toastConfig } from "../../shared/constants";
import { createDocument, getDocumentTypes } from "../../services/documentsService";
import { useSelector } from "react-redux";
import { loggedUser } from "../../UserContext/UserReducer";
import { isParseableToFloat } from "../../shared/utils";
var NewDocPopup = function (props) {
    var openState = props.openState, setIsOpen = props.setIsOpen, isPurchase = props.isPurchase, refreshData = props.refreshData, params = props.params, regimeId = props.regimeId;
    var userData = useSelector(loggedUser);
    var popupRef = useRef(null);
    var _a = useState(""), docIdentifier = _a[0], setDocIdentifier = _a[1];
    var _b = useState("1"), typeInput = _b[0], setTypeInput = _b[1];
    var _c = useState(""), dateInput = _c[0], setDateInput = _c[1];
    var _d = useState(""), serialInput = _d[0], setSerialInput = _d[1];
    var _e = useState(""), numberInput = _e[0], setNumberInput = _e[1];
    // const [nitInput, setNitInput] = useState("");
    var _f = useState(""), docNitInput = _f[0], setDocNitInput = _f[1];
    var _g = useState(""), nameInput = _g[0], setNameInput = _g[1];
    var _h = useState(""), descInput = _h[0], setDescInput = _h[1];
    var _j = useState(""), quantInput = _j[0], setQuantInput = _j[1];
    var _k = useState(""), totalInput = _k[0], setTotalInput = _k[1];
    var _l = useState(""), taxInput = _l[0], setTaxInput = _l[1];
    var _m = useState(""), exentInput = _m[0], setExentInput = _m[1];
    var _o = useState([{ document_name: "FACT", document_type_id: "1" }]), typeOptions = _o[0], setTypeOptions = _o[1];
    var initData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var doctypes;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getDocumentTypes(userData.idToken)];
                case 1:
                    doctypes = _a.sent();
                    if (doctypes.error) {
                        toast("Ocurrió un error al obtener los tipos de documento", __assign(__assign({}, toastConfig), { type: "error", position: "bottom-center" }));
                    }
                    else {
                        setTypeOptions(doctypes);
                        if (doctypes.length > 0) {
                            setTypeInput(doctypes[0].document_type_id);
                        }
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var validateInputs = function () {
        var inputsAreValid = true;
        if (docIdentifier === "") {
            inputsAreValid = false;
            toast("Debe ingresar un número de constancia", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (dateInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar una fecha", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (serialInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar un número de serie", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (numberInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar un número de factura", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (docNitInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar un n\u00FAmero de NIT ".concat(isPurchase ? "Emisor" : "Receptor"), __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (nameInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar un nombre", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (descInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar una descripción", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (quantInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar un monto gravable", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        else if (!isParseableToFloat(quantInput)) {
            inputsAreValid = false;
            toast("El monto debe ser un número", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (exentInput !== "" && !isParseableToFloat(exentInput)) {
            inputsAreValid = false;
            toast("El exento debe ser un número", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (taxInput !== "" && !isParseableToFloat(taxInput)) {
            inputsAreValid = false;
            toast("El impuesto debe ser un número", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (totalInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar un total", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        else if (!isParseableToFloat(totalInput)) {
            inputsAreValid = false;
            toast("El total debe ser un número", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        return inputsAreValid;
    };
    var createNewDoc = function () { return __awaiter(void 0, void 0, void 0, function () {
        var newDocResp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateInputs()) return [3 /*break*/, 2];
                    return [4 /*yield*/, createDocument(userData.idToken, docIdentifier, params.nit, parseFloat(totalInput), dateInput, isPurchase ? 1 : 0, typeInput === "" ? 1 : parseInt(typeInput), nameInput, serialInput, numberInput, docNitInput, typeInput === '21' ? 6 : 1, // If is DUCA (21) then this should be IMPORT (6)
                        taxInput === "" ? 0 : parseFloat(taxInput), parseFloat(quantInput), descInput, exentInput === "" ? 0 : parseFloat(exentInput))];
                case 1:
                    newDocResp = _a.sent();
                    // Notify success or error
                    if (newDocResp.error) {
                        toast(newDocResp.message ? newDocResp.message : "Ocurrió un error, por favor intente de nuevo en un momento", __assign(__assign({}, toastConfig), { type: "error", position: "bottom-center" }));
                    }
                    else {
                        toast("Documento creado exitosamente", __assign(__assign({}, toastConfig), { type: "success", position: "bottom-center" }));
                        // Clear inputs to enter new document
                        clearInputs();
                        refreshData();
                    }
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var calcTotal = function (newValue, isTotal, isExent) {
        var newTax = 0;
        var newQuant = 0;
        var newNumberValue = 0;
        var taxRateToBeUsed = regimeId && regimeId === 3 ? littleContributorTaxRate : generaIvaTaxRate;
        if (newValue !== '' && isParseableToFloat(newValue)) {
            newNumberValue = parseFloat(newValue);
            if (isTotal) {
                if (typeInput === '21') { // Special behavior for DUCAS
                    setTaxInput('');
                    setQuantInput(newNumberValue.toFixed(2));
                    setExentInput('');
                }
                else {
                    newTax = regimeId === 3 ? newNumberValue / taxRateToBeUsed : (newNumberValue - newNumberValue / taxRateToBeUsed);
                    newQuant = regimeId === 3 ? newNumberValue - newTax : newNumberValue / taxRateToBeUsed;
                    // Subtract exent from taxes if exent is less than or equal to taxes
                    if (exentInput !== '' && isParseableToFloat(exentInput) && parseFloat(exentInput) <= newTax) {
                        newTax -= parseFloat(exentInput);
                    }
                    else if (exentInput !== '' && isParseableToFloat(exentInput) && parseFloat(exentInput) > newTax) {
                        setExentInput(''); // If exent is greater than tax wheneve total changes, exent should reset
                    }
                    // Set new values
                    setTaxInput(newTax.toFixed(2));
                    setQuantInput(newQuant.toFixed(2));
                }
            }
            else if (isExent) {
                // Subtract exent from taxes if exent is less than or equal to taxes
                if (newNumberValue <= parseFloat(taxInput)) {
                    if (totalInput !== '' && isParseableToFloat(totalInput)) {
                        newTax = regimeId === 3 ? parseFloat(totalInput) / taxRateToBeUsed : parseFloat(totalInput) - parseFloat(totalInput) / taxRateToBeUsed;
                        newTax -= newNumberValue;
                        setTaxInput(newTax.toFixed(2));
                    }
                }
                else {
                    if (totalInput !== '' && isParseableToFloat(totalInput)) {
                        newTax = regimeId === 3 ? parseFloat(totalInput) / taxRateToBeUsed : parseFloat(totalInput) - parseFloat(totalInput) / taxRateToBeUsed;
                        setTaxInput(newTax.toFixed(2));
                    }
                    toast('El exento no puede ser mayor que el impuesto', __assign(__assign({}, toastConfig), { type: 'warning', position: 'bottom-center' }));
                }
            }
        }
        else if (newValue === '') {
            if (isTotal) {
                setTaxInput('');
                setQuantInput('');
            }
            else if (isExent) {
                if (totalInput !== '' && isParseableToFloat(totalInput)) {
                    newTax = regimeId === 3 ? parseFloat(totalInput) / taxRateToBeUsed : parseFloat(totalInput) - parseFloat(totalInput) / taxRateToBeUsed;
                    setTaxInput(newTax.toFixed(2));
                }
            }
            else if (typeInput === '21') { // Special behavior for DUCAS
                setExentInput('');
                setTaxInput('');
                setQuantInput(totalInput);
            }
            else { // Every other case calculate again the tax and quant
                if (totalInput !== '' && isParseableToFloat(totalInput)) {
                    newQuant = parseFloat(totalInput) / taxRateToBeUsed;
                    newTax = parseFloat(totalInput) - newQuant;
                    setTaxInput(newTax.toFixed(2));
                    setQuantInput(newQuant.toFixed(2));
                }
            }
        }
        else {
            toast('Debe ingresar un número', __assign(__assign({}, toastConfig), { type: 'warning', position: 'bottom-center' }));
        }
    };
    var clearInputs = function () {
        setDocIdentifier("");
        setDateInput("");
        setSerialInput("");
        setNumberInput("");
        setNameInput("");
        setQuantInput("");
        setExentInput("");
        setDocNitInput("");
        setDescInput("");
        setTaxInput("");
        setTotalInput("");
    };
    useEffect(function () {
        if (typeOptions.length <= 1 && openState) {
            initData();
        }
    }, [openState]);
    useEffect(function () {
        calcTotal('');
    }, [typeInput]); // Run the effect when type of document changes
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                // Clicked outside the popup
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return function () {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []); // Run the effect only once when the component mounts
    return (openState
        ?
            React.createElement("div", { className: "newDocPopupContainer", ref: popupRef },
                React.createElement("div", { className: "newDocPopupHeader" }, "Crear Documento"),
                React.createElement("div", { className: "newDocPopupBody" },
                    React.createElement("div", { className: "newDocPopupInputContainer", style: { marginTop: "0.5vw" } },
                        React.createElement("div", { className: "newDocPopupInputLeft" }, "Tipo"),
                        React.createElement("div", { className: "newDocPopupInputRight" },
                            React.createElement("select", { className: "newDocPopupDropdown", value: typeInput, onChange: function (ev) { return setTypeInput(ev.target.value); } }, typeOptions.map(function (opt, optInd) {
                                return (React.createElement("option", { key: "newDocTypeOpt-".concat(opt.document_type_id, "-").concat(optInd), value: opt.document_type_id }, opt.document_name));
                            })))),
                    React.createElement("div", { className: "newDocPopupInputContainer" },
                        React.createElement("div", { className: "newDocPopupInputLeft" }, "Constancia"),
                        React.createElement("div", { className: "newDocPopupInputRight" },
                            React.createElement("input", { className: "newDocPopupTransInput", placeholder: "000000000", value: docIdentifier, onChange: function (ev) { setDocIdentifier(ev.target.value); } }))),
                    React.createElement("div", { className: "newDocPopupInputContainer" },
                        React.createElement("div", { className: "newDocPopupInputLeft" }, "Fecha"),
                        React.createElement("div", { className: "newDocPopupInputRight" },
                            React.createElement("input", { className: "newDocPopupTransInput", type: "date", value: dateInput, onChange: function (ev) { setDateInput(ev.target.value); } }))),
                    React.createElement("div", { className: "newDocPopupInputContainer" },
                        React.createElement("div", { className: "newDocPopupInputLeft" }, "Serie"),
                        React.createElement("div", { className: "newDocPopupInputRight" },
                            React.createElement("input", { className: "newDocPopupTransInput", placeholder: "000000000", value: serialInput, onChange: function (ev) { return setSerialInput(ev.target.value); } }))),
                    React.createElement("div", { className: "newDocPopupInputContainer" },
                        React.createElement("div", { className: "newDocPopupInputLeft" }, "N\u00FAmero"),
                        React.createElement("div", { className: "newDocPopupInputRight" },
                            React.createElement("input", { className: "newDocPopupTransInput", placeholder: "000000000", value: numberInput, onChange: function (ev) { return setNumberInput(ev.target.value); } }))),
                    React.createElement("div", { className: "newDocPopupInputContainer" },
                        React.createElement("div", { className: "newDocPopupInputLeft" }, "NIT ".concat(isPurchase ? "Emisor" : "Receptor")),
                        React.createElement("div", { className: "newDocPopupInputRight" },
                            React.createElement("input", { className: "newDocPopupTransInput", placeholder: "000000000", value: docNitInput, onChange: function (ev) { return setDocNitInput(ev.target.value); } }))),
                    React.createElement("div", { className: "newDocPopupInputContainer" },
                        React.createElement("div", { className: "newDocPopupInputLeft" }, "Nombre"),
                        React.createElement("div", { className: "newDocPopupInputRight" },
                            React.createElement("input", { className: "newDocPopupTransInput", placeholder: "Lorena Ipsum", value: nameInput, onChange: function (ev) { return setNameInput(ev.target.value); } }))),
                    React.createElement("div", { className: "newDocPopupInputContainer" },
                        React.createElement("div", { className: "newDocPopupInputLeft" }, "Descripci\u00F3n"),
                        React.createElement("div", { className: "newDocPopupInputRight" },
                            React.createElement("input", { className: "newDocPopupTransInput", placeholder: "Descripci\u00F3n", value: descInput, onChange: function (ev) { return setDescInput(ev.target.value); } }))),
                    React.createElement("div", { className: "newDocPopupInputContainer" },
                        React.createElement("div", { className: "newDocPopupInputLeft" }, "Total"),
                        React.createElement("div", { className: "newDocPopupInputRight" },
                            React.createElement("input", { className: "newDocPopupTransInput", placeholder: "0.00", value: totalInput, onChange: function (ev) {
                                    setTotalInput(ev.target.value);
                                    calcTotal(ev.target.value, true, false);
                                } }))),
                    React.createElement("div", { className: "newDocPopupInputContainer" },
                        React.createElement("div", { className: "newDocPopupInputLeft" }, "Exento"),
                        React.createElement("div", { className: "newDocPopupInputRight" },
                            React.createElement("input", { className: "newDocPopupTransInput", placeholder: "0.00", value: exentInput, onChange: function (ev) {
                                    setExentInput(ev.target.value);
                                    calcTotal(ev.target.value, false, true);
                                }, disabled: typeInput === '21' }))),
                    React.createElement("div", { className: "newDocPopupInputContainer" },
                        React.createElement("div", { className: "newDocPopupInputLeft" }, "Monto"),
                        React.createElement("div", { className: "newDocPopupInputRight" },
                            React.createElement("input", { className: "newDocPopupTransInput", 
                                // placeholder="0.00"
                                value: quantInput, disabled: true }))),
                    React.createElement("div", { className: "newDocPopupInputContainer" },
                        React.createElement("div", { className: "newDocPopupInputLeft" }, "Impuesto"),
                        React.createElement("div", { className: "newDocPopupInputRight" },
                            React.createElement("input", { className: "newDocPopupTransInput", 
                                // placeholder="0.00"
                                value: taxInput, disabled: true })))),
                React.createElement("div", { className: "newDocPopupFooter" },
                    React.createElement("button", { className: "newDocPopupFilledButton newDocPopupDanger", onClick: function () { return setIsOpen(false); } }, "Cerrar"),
                    React.createElement("button", { className: "newDocPopupFilledButton newDocPopupDanger", onClick: function () { return clearInputs(); } }, "Limpiar"),
                    React.createElement("button", { className: "newDocPopupFilledButton newDocPopupSuccess", onClick: function () { return createNewDoc(); } }, "Guardar y nuevo")))
        :
            React.createElement(React.Fragment, null));
};
export default NewDocPopup;
