var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import searchIcon from "../../../assets/icons/searchIcon.png";
import arrowDown from "../../../assets/icons/whiteArrowDown.png";
import sortIcon from "../../../assets/icons/sortIcon.png";
import { useSelector } from "react-redux";
import "./AdminDashboard.scss";
import { loggedUser } from "../../../UserContext/UserReducer";
import { getAllStatus, getCompanies } from "../../../services/clientsService";
import { Roles } from '../../../shared/constants';
import { formatCompanyName } from "../../../shared/utils";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import DateTransitioner from "../../../components/DateTransitioner/DateTransitioner";
import pendingIcon from '../../../assets/icons/pendingIcon.png';
import doneIcon from '../../../assets/icons/doneIcon.png';
import validatedIcon from '../../../assets/icons/validatedIcon.png';
import warningIcon from '../../../assets/icons/warningIcon.png';
import runningIcon from '../../../assets/icons/runningIcon.png';
import schedulerIcon from '../../../assets/icons/schedulerIcon.png';
import Tooltip from "../../../components/Tooltip/Tooltip";
import InvalidPassword from "../../../components/InvalidPassword/InvalidPassword";
import AutomatedClientIcon from "../../../components/AutomatedClientIcon/AutomatedClientIcon";
var AdminDashboard = function () {
    var params = useParams();
    // Navigate to another page
    var navigate = useNavigate();
    var _a = useState(params.month), currentDisplayMonth = _a[0], setCurrentDisplayMonth = _a[1];
    var _b = useState(params.year), currentDisplayYear = _b[0], setCurrentDisplayYear = _b[1];
    var userData = useSelector(loggedUser);
    var _c = useState(false), loading = _c[0], setLoading = _c[1];
    var _d = useState({ nit: false, name: false, regime: false, status: false }), colDisplayStates = _d[0], setColDisplayStates = _d[1];
    var cols = [
        {
            display: React.createElement("div", { style: { display: "flex", flexDirection: "row", gap: "0.5vw", justifyContent: "center", alignItems: "center" } }),
            name: "automated_nit"
        },
        {
            display: React.createElement("div", { style: { display: "flex", flexDirection: "row", gap: "0.5vw", justifyContent: "center", alignItems: "center" } },
                React.createElement("div", null, "NIT"),
                colDisplayStates["nit"] ?
                    React.createElement("img", { src: arrowDown, style: { width: "1vw" }, alt: "" })
                    :
                        React.createElement("img", { src: sortIcon, style: { width: "1vw" }, alt: "" })),
            name: "nit"
        },
        {
            display: "",
            name: "warning",
            avoidClicksForRow: true,
        },
        {
            display: React.createElement("div", { style: { display: "flex", flexDirection: "row", gap: "0.5vw", justifyContent: "center", alignItems: "center" } },
                React.createElement("div", null, "Nombre"),
                colDisplayStates["name"] ?
                    React.createElement("img", { src: arrowDown, style: { width: "1vw" }, alt: "" })
                    :
                        React.createElement("img", { src: sortIcon, style: { width: "1vw" }, alt: "" })),
            name: "name"
        },
        {
            display: React.createElement("div", { style: { display: "flex", flexDirection: "row", gap: "0.5vw", justifyContent: "center", alignItems: "center" } },
                React.createElement("div", null, "R\u00E9gimen"),
                colDisplayStates["regime"] ?
                    React.createElement("img", { src: arrowDown, style: { width: "1vw" }, alt: "" })
                    :
                        React.createElement("img", { src: sortIcon, style: { width: "1vw" }, alt: "" })),
            name: "regime"
        },
        {
            display: React.createElement("div", { style: { display: "flex", flexDirection: "row", gap: "0.5vw", justifyContent: "center", alignItems: "center" } },
                React.createElement("div", null, "Estatus Descargas")),
            name: "status"
        },
        {
            display: React.createElement("div", { style: { display: "flex", flexDirection: "row", gap: "0.5vw", justifyContent: "center", alignItems: "center" } },
                React.createElement("div", null, "Estatus Formularios")),
            name: "status_formulario"
        },
    ];
    var _e = useState([]), clientList = _e[0], setClientList = _e[1];
    var _f = useState([]), companyList = _f[0], setCompanyList = _f[1];
    var _g = useState([]), filteredClientList = _g[0], setFilteredClientList = _g[1];
    var _h = useState(""), searchText = _h[0], setSearchText = _h[1];
    var _j = useState(false), clientsFetched = _j[0], setClientsFetched = _j[1];
    var _k = useState({
        regimeFilter: (function (obj) { return true; }),
        movementFilter: (function (obj) { return true; }),
        typeFilter: (function (obj) { return true; }),
        searchFilter: (function (obj) { return true; }),
        enabledFilter: (function (obj) { return obj.is_enabled === 1; }),
        companyFilter: (function (obj) { return true; }),
    }), filters = _k[0], setFilters = _k[1];
    var initData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, compResp, filtersToApply;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    setLoading(true);
                    return [4 /*yield*/, getAllStatus(userData.idToken, parseInt(params.month, 10), parseInt(params.year, 10))];
                case 1:
                    response = _a.sent();
                    if (!(userData.role === Roles.Superadmin)) return [3 /*break*/, 3];
                    return [4 /*yield*/, getCompanies(userData.idToken)];
                case 2:
                    compResp = _a.sent();
                    setCompanyList(Array.isArray(compResp) ? compResp : []);
                    _a.label = 3;
                case 3:
                    setClientsFetched(true);
                    setClientList(Array.isArray(response) ? response : []);
                    setLoading(false);
                    filtersToApply = Object.keys(filters).map(function (filt) {
                        //@ts-ignore
                        return filters[filt];
                    });
                    setFilteredClientList(applyFilters(Array.isArray(response) ? response : [], filtersToApply));
                    return [2 /*return*/];
            }
        });
    }); };
    var getClientRows = function (allClients) {
        var currentDate = new Date();
        var currentMonth = currentDate.getMonth(); // Implicitly subtract 1 from month number
        var currentYear = currentDate.getFullYear();
        if (currentMonth === 0) {
            currentMonth = 12;
            currentYear -= 1;
        }
        return allClients.map(function (client, clientInd) {
            return {
                automated_nit: client.is_automated ? React.createElement(AutomatedClientIcon, null) : "",
                nit: client.nit,
                warning: (client.invalid_password === 1) ? React.createElement(InvalidPassword, { icon: warningIcon, title: "Actualizar contrase\u00F1a", onClick: function () { navigate("/admin/client/edit/".concat(client.nit)); } }) : "",
                name: React.createElement("span", { className: "dashboardNameLink", onClick: function () { return navigate("/status/".concat(client.nit, "/").concat(currentDisplayYear, "/").concat(currentDisplayMonth)); } }, client.name),
                regime: client.regime_name,
                status: validatedStatus(client, true),
                status_formulario: validatedStatus(client, false),
                customStyles: {
                    nit: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    name: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    regime: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    descarga_compras: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    status: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                    warning: {
                        backgroundColor: client.is_enabled === 1 ? undefined : '#CDCDCD',
                    },
                }
            };
        });
    };
    var validatedStatus = function (client, show_downloads) {
        return (React.createElement("div", null,
            client.purchases_status && show_downloads && client.has_movement === 1 && (React.createElement("div", { style: { display: 'inline-block', padding: '15px' } },
                React.createElement(Tooltip, { text: client.purchases_status === 'downloaded' ? 'Descargado' : (client.purchases_status === 'error' ? 'Error' : (client.purchases_status === 'running' ? 'Ejecutando' : 'Pendiente')) },
                    React.createElement("img", { src: client.purchases_status === 'downloaded' ? doneIcon : (client.purchases_status === 'error' ? warningIcon : (client.purchases_status === 'running' ? runningIcon : pendingIcon)), width: 40, alt: "Pending" })),
                React.createElement("p", { style: { marginTop: 1, fontSize: '75%' } },
                    React.createElement("span", { style: { display: 'block' } }, "Descarga"),
                    React.createElement("span", { style: { display: 'block' } }, "Compras")))),
            client.sales_status && show_downloads && client.has_movement === 1 && (React.createElement("div", { style: { display: 'inline-block', padding: '15px' } },
                React.createElement(Tooltip, { text: client.sales_status === 'downloaded' ? 'Descargado' : (client.sales_status === 'error' ? 'Error' : (client.sales_status === 'pending' ? 'Pendiente' : (client.sales_status === 'programmed' ? 'Programado' : 'Pendiente'))) },
                    React.createElement("img", { src: client.sales_status === 'downloaded' ? doneIcon : (client.sales_status === 'error' ? warningIcon : (client.sales_status === 'pending' ? pendingIcon : (client.sales_status === 'programmed' ? schedulerIcon : pendingIcon))), width: 40, alt: "Pending" })),
                React.createElement("p", { style: { marginTop: 1, fontSize: '75%' } },
                    React.createElement("span", { style: { display: 'block' } }, "Descarga"),
                    React.createElement("span", { style: { display: 'block' } }, "Ventas")))),
            client.retentions_status && show_downloads && client.has_movement === 1 && (React.createElement("div", { style: { display: 'inline-block', padding: '10px' } },
                React.createElement(Tooltip, { text: client.retentions_status === 'downloaded' ? 'Descargado' : (client.retentions_status === 'error' ? 'Error' : (client.retentions_status === 'running' ? 'Ejecutando' : (client.retentions_status === 'programmed' ? 'Programado' : 'Pendiente'))) },
                    React.createElement("img", { src: client.retentions_status === 'downloaded' ? doneIcon : (client.retentions_status === 'error' ? warningIcon : (client.retentions_status === 'running' ? runningIcon : (client.retentions_status === 'programmed' ? schedulerIcon : pendingIcon))), width: 40, alt: "Pending" })),
                React.createElement("p", { style: { marginTop: 1, fontSize: '75%' } },
                    React.createElement("span", { style: { display: 'block' } }, "Descarga"),
                    React.createElement("span", { style: { display: 'block' } }, "Retenciones")))),
            client.form1311Status && show_downloads === false && (React.createElement("div", { style: { display: 'inline-block', padding: '20px' } },
                React.createElement(Tooltip, { text: client.form1311Status === 'validated' ? 'Validado' : (client.form1311Status === 'error' ? 'Error' : (client.form1311Status === 'programmed' ? 'Programado' : (client.form1311Status === 'presented' ? 'Presentado' : 'Pendiente'))) },
                    React.createElement("img", { src: client.form1311Status === 'validated' ? validatedIcon : (client.form1311Status === 'error' ? warningIcon : (client.form1311Status === 'programmed' ? schedulerIcon : (client.form1311Status === 'presented' ? doneIcon : pendingIcon))), width: 40, alt: "Done" })),
                React.createElement("p", { style: { marginTop: 1, fontSize: '75%' } },
                    React.createElement("span", { style: { display: 'block' } }, "SAT"),
                    React.createElement("span", { style: { display: 'block' } }, "1311")))),
            client.form2237Status && show_downloads === false && (React.createElement("div", { style: { display: 'inline-block', padding: '20px' } },
                React.createElement(Tooltip, { text: client.form2237Status === 'validated' ? 'Validado' : (client.form2237Status === 'error' ? 'Error' : (client.form2237Status === 'programmed' ? 'Programado' : (client.form2237Status === 'presented' ? 'Presentado' : 'Pendiente'))) },
                    React.createElement("img", { src: client.form2237Status === 'validated' ? validatedIcon : (client.form2237Status === 'error' ? warningIcon : (client.form2237Status === 'programmed' ? schedulerIcon : (client.form2237Status === 'presented' ? doneIcon : pendingIcon))), width: 40, alt: "Done" })),
                React.createElement("p", { style: { marginTop: 1, fontSize: '75%' } },
                    React.createElement("span", { style: { display: 'block' } }, "SAT"),
                    React.createElement("span", { style: { display: 'block' } }, "2237")))),
            client.form2046Status && show_downloads === false && (React.createElement("div", { style: { display: 'inline-block', padding: '15px' } },
                React.createElement(Tooltip, { text: client.form2046Status === 'validated' ? 'Validado' : (client.form2046Status === 'error' ? 'Error' : (client.form2046Status === 'programmed' ? 'Programado' : (client.form2046Status === 'presented' ? 'Presentado' : 'Pendiente'))) },
                    React.createElement("img", { src: client.form2046Status === 'validated' ? validatedIcon : (client.form2046Status === 'error' ? warningIcon : (client.form2046Status === 'programmed' ? schedulerIcon : (client.form2046Status === 'presented' ? doneIcon : pendingIcon))), width: 40, alt: "Done" })),
                React.createElement("p", { style: { marginTop: 1, fontSize: '75%' } },
                    React.createElement("span", { style: { display: 'block' } }, "SAT"),
                    React.createElement("span", { style: { display: 'block' } }, "2046")))),
            client.form1361Status && show_downloads === false && client.has_movement === 0 && (React.createElement("div", { style: { display: 'inline-block', padding: '15px' } },
                React.createElement(Tooltip, { text: client.form1361Status === 'validated' ? 'Validado' : (client.form1361Status === 'error' ? 'Error' : (client.form1361Status === 'programmed' ? 'Programado' : (client.form1361Status === 'presented' ? 'Presentado' : 'Pendiente'))) },
                    React.createElement("img", { src: client.form1361Status === 'validated' ? validatedIcon : (client.form1361Status === 'error' ? warningIcon : (client.form1361Status === 'programmed' ? schedulerIcon : (client.form1361Status === 'presented' ? doneIcon : pendingIcon))), width: 40, alt: "Done" })),
                React.createElement("p", { style: { marginTop: 1, fontSize: '75%' } },
                    React.createElement("span", { style: { display: 'block' } }, "SAT"),
                    React.createElement("span", { style: { display: 'block' } }, "1361")))),
            client.form1608Status && show_downloads === false && client.has_movement === 0 && (React.createElement("div", { style: { display: 'inline-block', padding: '15px' } },
                React.createElement(Tooltip, { text: client.form1608Status === 'validated' ? 'Validado' : (client.form1608Status === 'error' ? 'Error' : (client.form1608Status === 'programmed' ? 'Programado' : (client.form1608Status === 'presented' ? 'Presentado' : 'Pendiente'))) },
                    React.createElement("img", { src: client.form1608Status === 'validated' ? validatedIcon : (client.form1608Status === 'error' ? warningIcon : (client.form1608Status === 'programmed' ? schedulerIcon : (client.form1608Status === 'presented' ? doneIcon : pendingIcon))), width: 40, alt: "Done" })),
                React.createElement("p", { style: { marginTop: 1, fontSize: '75%' } },
                    React.createElement("span", { style: { display: 'block' } }, "SAT"),
                    React.createElement("span", { style: { display: 'block' } }, "1608"))))));
    };
    var applyFilters = function (data, filters) {
        // Apply each filter function to the array of objects
        return data.filter(function (obj) {
            // Check if the object passes all filter conditions
            return filters.every(function (filter) { return filter(obj); });
        });
    };
    useEffect(function () {
        var filtersToApply = Object.keys(filters).map(function (filt) {
            //@ts-ignore
            return filters[filt];
        });
        setFilteredClientList(applyFilters(clientList, filtersToApply));
        // TODO store filters in context to persist throughout the application
    }, [filters, clientList]);
    useEffect(function () {
        initData();
        setCurrentDisplayMonth(params.month);
        setCurrentDisplayYear(params.year);
    }, [params]);
    return (React.createElement("div", { className: "container", style: { paddingBottom: "5vw" } },
        React.createElement("div", { className: "clientsTitle", style: { width: '95vw', textAlign: 'start', marginBottom: '1vw' } }, "Dashboard"),
        React.createElement(DateTransitioner, { defaultMonth: currentDisplayMonth, defaultYear: currentDisplayYear, redirectionPath: "/admin/dashboard" }),
        React.createElement("div", { className: "clientsToolbar" },
            React.createElement("div", { className: "clientsToolbarLeft" },
                React.createElement("div", { className: "clientsFiltersContainer" },
                    React.createElement("div", { className: "clientsFilterContainer" },
                        React.createElement("div", { className: "clientsFilterTitle" }, "R\u00E9gimen"),
                        React.createElement("select", { className: "clientsDropdown", onChange: function (ev) {
                                var filterByRegimeCallback = ev.target.value === "all"
                                    ? (function (obj) { return true; })
                                    : (function (obj) { return obj.regime_id === parseInt(ev.target.value); });
                                setFilters(__assign(__assign({}, filters), { regimeFilter: filterByRegimeCallback }));
                            } },
                            React.createElement("option", { value: "all" }, "Todos"),
                            React.createElement("option", { value: "1" }, "IVA GENERAL - ISR MENSUAL"),
                            React.createElement("option", { value: "2" }, "IVA GENERAL - ISR TRIMESTRAL"),
                            React.createElement("option", { value: "3" }, "PEQUE\u00D1O CONTRIBUYENTE"))),
                    React.createElement("div", { className: "clientsFilterContainer" },
                        React.createElement("div", { className: "clientsFilterTitle" }, "Movimiento"),
                        React.createElement("select", { className: "clientsDropdown", onChange: function (ev) {
                                var filterByMovementCallback = ev.target.value === "all"
                                    ? (function (obj) { return true; })
                                    : (function (obj) { return obj.has_movement === parseInt(ev.target.value); });
                                setFilters(__assign(__assign({}, filters), { movementFilter: filterByMovementCallback }));
                            } },
                            React.createElement("option", { value: "all" }, "Todos"),
                            React.createElement("option", { value: "1" }, "Con movimiento"),
                            React.createElement("option", { value: "0" }, "Sin movimiento"))),
                    React.createElement("div", { className: "clientsFilterContainer" },
                        React.createElement("div", { className: "clientsFilterTitle" }, "Estado"),
                        React.createElement("select", { className: "clientsDropdown", onChange: function (ev) {
                                var filterByTypeCallback = ev.target.value === "all"
                                    ? (function (obj) { return true; })
                                    : (function (obj) { return obj.is_enabled === parseInt(ev.target.value); });
                                setFilters(__assign(__assign({}, filters), { enabledFilter: filterByTypeCallback }));
                            } },
                            React.createElement("option", { value: "1" }, "Activos"),
                            React.createElement("option", { value: "all" }, "Todos"),
                            React.createElement("option", { value: "0" }, "Inactivos"))),
                    userData.role === Roles.Superadmin && companyList.length > 0 &&
                        React.createElement("div", { className: "clientsFilterContainer" },
                            React.createElement("div", { className: "clientsFilterTitle" }, "Empresa/Contador"),
                            React.createElement("select", { className: "clientsDropdown", onChange: function (ev) {
                                    var filterByTypeCallback = ev.target.value === "all"
                                        ? (function (obj) { return true; })
                                        : (function (obj) { return obj.company_id === ev.target.value; });
                                    setFilters(__assign(__assign({}, filters), { companyFilter: filterByTypeCallback }));
                                } },
                                React.createElement("option", { value: "all" }, "Todos"),
                                companyList.map(function (comp, compInd) {
                                    return (React.createElement("option", { value: comp.company_id }, comp.company_name));
                                }))))),
            React.createElement("div", { className: "clientsToolbarRight" },
                React.createElement("div", { className: "clientsSearchbar" },
                    React.createElement("img", { src: searchIcon, alt: "Search" }),
                    React.createElement("input", { placeholder: "Buscar por NIT o nombre...", onChange: function (ev) {
                            // searchHandler(ev)
                            setSearchText(ev.target.value);
                            var filterBySearchCallback = ev.target.value === "all"
                                ? (function (obj) { return true; })
                                : (function (obj) { return formatCompanyName(obj.name).includes(formatCompanyName(ev.target.value))
                                    || formatCompanyName(obj.nit).includes(formatCompanyName(ev.target.value)); });
                            setFilters(__assign(__assign({}, filters), { searchFilter: filterBySearchCallback }));
                        } })))),
        (filteredClientList === null || filteredClientList === void 0 ? void 0 : filteredClientList.length) > 0 && !loading &&
            React.createElement(Table, { columns: cols, rows: getClientRows(filteredClientList), width: "95vw", tableRowPadding: "0", paginate: true }),
        (filteredClientList === null || filteredClientList === void 0 ? void 0 : filteredClientList.length) === 0 && searchText !== ""
            ? React.createElement("div", { className: "clientsNoResults" }, "No se encontraron resultados.")
            : (filteredClientList === null || filteredClientList === void 0 ? void 0 : filteredClientList.length) === 0 && !clientsFetched
                ?
                    React.createElement("div", { className: "clientsNoResults" })
                :
                    (filteredClientList === null || filteredClientList === void 0 ? void 0 : filteredClientList.length) === 0 &&
                        React.createElement("div", { className: "clientsNoResults" }, "Usted no tiene asignado ning\u00FAn cliente."),
        loading && React.createElement(Loader, null)));
};
export default AdminDashboard;
