var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toastConfig } from "../../../shared/constants";
import "./CreateClient.scss";
import { createClient, editClient, getClient, getCompanies } from "../../../services/clientsService";
import { useSelector } from "react-redux";
import { loggedUser } from "../../../UserContext/UserReducer";
import Loader from "../../../components/Loader/Loader";
var CreateClient = function () {
    var params = useParams();
    var navigate = useNavigate();
    var userData = useSelector(loggedUser);
    var _a = useState([]), companyList = _a[0], setCompanyList = _a[1];
    var _b = useState({
        nit: "",
        name: "",
        company: "",
        regime: "",
        movement: "",
        auto: "",
        remCredit: "",
        folio: "",
        password: "",
        delegate: "",
        autoPdf: "",
        birthdate: "",
        purchasesDownloadDate: "",
        salesDownloadDate: "",
        categoryId: "",
        noMovePresentationDate: ""
    }), originalEditValues = _b[0], setOriginalEditValues = _b[1];
    var _c = useState(false), isCreatingClient = _c[0], setIsCreatingClient = _c[1];
    var _d = useState(""), nitInput = _d[0], setNitInput = _d[1];
    var _e = useState(""), nameInput = _e[0], setNameInput = _e[1];
    var _f = useState(""), companyInput = _f[0], setCompanyInput = _f[1];
    var _g = useState("1"), regimeInput = _g[0], setRegimeInput = _g[1];
    var _h = useState("nomove"), movementInput = _h[0], setMovementInput = _h[1];
    var _j = useState("auto"), autoInput = _j[0], setAutoInput = _j[1];
    var _k = useState(""), remCredit = _k[0], setRemCredit = _k[1];
    var _l = useState(""), ivaCredit = _l[0], setIvaCredit = _l[1];
    var _m = useState(new Date().toISOString().slice(0, 7)), monthInput = _m[0], setMonthInput = _m[1];
    var _o = useState(""), folio = _o[0], setFolio = _o[1];
    var _p = useState(""), passwordInput = _p[0], setPasswordInput = _p[1];
    var _q = useState(""), delegateInput = _q[0], setDelegateInput = _q[1];
    var _r = useState("auto"), autoPdf = _r[0], setAutoPdf = _r[1];
    var _s = useState(""), birthDateInput = _s[0], setBirthDateInput = _s[1];
    var _t = useState('6'), purchDateInput = _t[0], setPurchDateInput = _t[1];
    var _u = useState('6'), salesDateInput = _u[0], setSalesDateInput = _u[1];
    var _v = useState('1'), presentationDateInput = _v[0], setPresentationDateInput = _v[1];
    var _w = useState("2"), clientTypeInput = _w[0], setClientTypeInput = _w[1];
    var createNewClient = function () { return __awaiter(void 0, void 0, void 0, function () {
        var trimmedNitInput, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!fieldsAreValid()) return [3 /*break*/, 2];
                    setIsCreatingClient(true);
                    trimmedNitInput = nitInput.trim();
                    return [4 /*yield*/, createClient(userData.idToken, trimmedNitInput, parseInt(regimeInput), nameInput, movementInput === "nomove" ? 0 : 1, passwordInput, delegateInput, autoInput === "noauto" ? 0 : 1, autoPdf === "noauto" ? 0 : 1, userData.role === "Superadmin" ? companyInput : null, parseFloat(remCredit), parseInt(folio), birthDateInput, parseInt(purchDateInput), parseInt(salesDateInput), parseInt(clientTypeInput), parseInt(presentationDateInput))];
                case 1:
                    resp = _a.sent();
                    if (resp.error) {
                        toast(resp.message || "Error al crear cliente.", __assign(__assign({}, toastConfig), { type: "error" }));
                    }
                    else if (resp) {
                        toast("Cliente creado.", __assign(__assign({}, toastConfig), { type: "success" }));
                        navigate('/admin/clients');
                    }
                    else {
                        toast("Error al crear cliente.", __assign(__assign({}, toastConfig), { type: "error" }));
                    }
                    setIsCreatingClient(false);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var editExistingClient = function () { return __awaiter(void 0, void 0, void 0, function () {
        var trimmedNitInput, resp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!fieldsAreValid()) return [3 /*break*/, 2];
                    setIsCreatingClient(true);
                    trimmedNitInput = nitInput.trim();
                    return [4 /*yield*/, editClient(userData.idToken, trimmedNitInput, originalEditValues.regime !== regimeInput ? parseInt(regimeInput) : undefined, originalEditValues.name !== nameInput ? nameInput : undefined, originalEditValues.movement !== movementInput ? (movementInput === "nomove" ? 0 : 1) : undefined, passwordInput !== "" ? passwordInput : undefined, originalEditValues.delegate !== delegateInput ? delegateInput : undefined, originalEditValues.auto !== autoInput ? (autoInput === "noauto" ? 0 : 1) : undefined, originalEditValues.remCredit !== remCredit ? parseFloat(remCredit) : undefined, originalEditValues.autoPdf !== autoPdf ? (autoPdf === "noauto" ? 0 : 1) : undefined, parseInt(folio), originalEditValues.birthdate !== birthDateInput ? birthDateInput : undefined, originalEditValues.purchasesDownloadDate !== purchDateInput ? parseInt(purchDateInput) : undefined, originalEditValues.salesDownloadDate !== salesDateInput ? parseInt(salesDateInput) : undefined, originalEditValues.categoryId !== clientTypeInput ? parseInt(clientTypeInput) : undefined, originalEditValues.noMovePresentationDate !== presentationDateInput ? parseInt(presentationDateInput) : undefined)];
                case 1:
                    resp = _a.sent();
                    if (resp.error) {
                        toast(resp.message || "Error al editar cliente.", __assign(__assign({}, toastConfig), { type: "error" }));
                    }
                    else if (resp) {
                        toast("Cliente actualizado.", __assign(__assign({}, toastConfig), { type: "success" }));
                        navigate('/admin/clients');
                    }
                    else {
                        toast("Error al editar cliente.", __assign(__assign({}, toastConfig), { type: "error" }));
                    }
                    setIsCreatingClient(false);
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var initEditData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var nitData;
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0: return [4 /*yield*/, getClient(userData.idToken, params.nit)];
                case 1:
                    nitData = _c.sent();
                    if (nitData) {
                        setNitInput(params.nit);
                        setNameInput(nitData.name);
                        setCompanyInput(nitData.company_id);
                        setRegimeInput("".concat(nitData.regime_id));
                        setRemCredit(nitData.credito_fiscal);
                        setAutoInput(nitData.is_automated === 1 ? "auto" : "noauto");
                        setPasswordInput(nitData.password);
                        setDelegateInput((nitData.nit_delegado === 'null') ? '' : nitData.nit_delegado);
                        setMovementInput(nitData.has_movement === 1 ? "move" : "nomove");
                        setAutoPdf(nitData.download_pdf === 1 ? "auto" : "noauto");
                        setBirthDateInput(((_a = nitData.birthdate) === null || _a === void 0 ? void 0 : _a.split("T")[0]) || "");
                        setPurchDateInput("".concat(nitData.purchases_download_date) || "6");
                        setSalesDateInput("".concat(nitData.sales_download_date) || "6");
                        setClientTypeInput("".concat(nitData.category_id) || "2");
                        setPresentationDateInput("".concat(nitData.presentation_date) || "1");
                        setOriginalEditValues({
                            nit: params.nit,
                            name: nitData.name,
                            company: nitData.company_id,
                            regime: "".concat(nitData.regime_id),
                            movement: nitData.has_movement === 1 ? "move" : "nomove",
                            auto: nitData.is_automated === 1 ? "auto" : "noauto",
                            remCredit: nitData.credito_fiscal,
                            folio: "1",
                            password: nitData.password,
                            delegate: nitData.nit_delegado || "",
                            autoPdf: nitData.download_pdf === 1 ? "auto" : "noauto",
                            birthdate: ((_b = nitData.birthdate) === null || _b === void 0 ? void 0 : _b.split("T")[0]) || "",
                            purchasesDownloadDate: "".concat(nitData.purchases_download_date) || "6",
                            salesDownloadDate: "".concat(nitData.sales_download_date) || "6",
                            categoryId: "".concat(nitData.category_id),
                            noMovePresentationDate: "".concat(nitData.presentation_date) || "1",
                        });
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var initCompanies = function () { return __awaiter(void 0, void 0, void 0, function () {
        var companiesResp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCompanies(userData.idToken)];
                case 1:
                    companiesResp = _a.sent();
                    if (companiesResp && companiesResp.length > 0) {
                        setCompanyInput(companiesResp[0].company_id);
                        setCompanyList(companiesResp);
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (params.nit) {
            initEditData();
        }
        initCompanies();
    }, []);
    var fieldsAreValid = function () {
        var validated = true;
        if (nitInput === "") {
            toast("El NIT no puede estar vacío.", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        if (nameInput === "") {
            toast("El nombre no puede estar vacío.", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        // if (remCredit === "") {
        //   toast("El crédito remanente no puede estar vacío.", {...toastConfig, type: "error"});
        // validated = false;
        // }
        if (passwordInput === "" && autoInput === "auto" && !params.nit) {
            toast("La contraseña no puede estar vacía.", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        if (birthDateInput === "" && autoInput === "auto") {
            toast("Debe ingresar una fecha de nacimiento", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        if (purchDateInput === "" && autoInput === "auto") {
            toast("Debe ingresar una fecha de descarga para compras", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        else if (isNaN(parseInt(purchDateInput)) && autoInput === "auto") {
            toast("La fecha de descarga de compras debe ser un número", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        else if (parseInt(purchDateInput) > 28 && autoInput === "auto") {
            toast("La fecha de descarga de compras debe ser menor o igual a 28", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        else if (parseInt(purchDateInput) < 6 && autoInput === "auto") {
            toast("La fecha de descarga de compras debe ser mayor o igual a 6", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        if (salesDateInput === "" && autoInput === "auto") {
            toast("Debe ingresar una fecha de descarga para ventas", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        else if (isNaN(parseInt(salesDateInput)) && autoInput === "auto") {
            toast("La fecha de descarga de ventas debe ser un número", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        else if (parseInt(salesDateInput) > 28 && autoInput === "auto") {
            toast("La fecha de descarga de ventas debe ser menor o igual a 28", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        else if (parseInt(salesDateInput) < 1 && autoInput === "auto") {
            toast("La fecha de descarga de ventas debe ser mayor o igual a 1", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        if (presentationDateInput === "" && autoInput === "auto") {
            toast("Debe ingresar una fecha presentación de Formularios", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        else if (isNaN(parseInt(presentationDateInput)) && autoInput === "auto") {
            toast("La fecha de presentación de formularios debe ser un número", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        else if (parseInt(presentationDateInput) > 28 && autoInput === "auto") {
            toast("La fecha de presentación de formularios debe ser menor o igual a 28", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        else if (parseInt(presentationDateInput) < 1 && autoInput === "auto") {
            toast("La fecha de presentación de formularios debe ser mayor o igual a 1", __assign(__assign({}, toastConfig), { type: "error" }));
            validated = false;
        }
        return validated;
    };
    return (React.createElement("div", { className: "createClientContainer" },
        React.createElement("div", { className: "createClientFormContainer" },
            React.createElement("div", { className: "createClientTitle" }, params.nit ? 'Editar Cliente' : 'Crear Cliente'),
            React.createElement("div", { className: "createClientFieldContainer" },
                React.createElement("div", { className: "createClientFieldTitle" },
                    "NIT ",
                    React.createElement("b", { style: { color: "#F68218" } }, "*")),
                React.createElement("input", { className: "createClientFieldInput", placeholder: "123456789", value: nitInput, onChange: function (ev) { return setNitInput(ev.target.value); }, disabled: params.nit !== undefined && params.nit !== null })),
            React.createElement("div", { className: "createClientFieldContainer" },
                React.createElement("div", { className: "createClientFieldTitle" },
                    "Nombre ",
                    React.createElement("b", { style: { color: "#F68218" } }, "*")),
                React.createElement("input", { className: "createClientFieldInput", placeholder: "Roberto Ejemplar", value: nameInput, onChange: function (ev) { return setNameInput(ev.target.value); } })),
            userData.role === "Superadmin" &&
                React.createElement("div", { className: "createClientFieldContainer" },
                    React.createElement("div", { className: "createClientFieldTitle" },
                        "Empresa ",
                        React.createElement("b", { style: { color: "#F68218" } }, "*")),
                    React.createElement("select", { className: "createClientFieldDropdown", value: companyInput, onChange: function (ev) { return setCompanyInput(ev.target.value); } }, companyList.map(function (comp, compInd) {
                        return (React.createElement("option", { key: comp.company_id, value: comp.company_id }, comp.company_name));
                    }))),
            React.createElement("div", { className: "createClientFieldContainer" },
                React.createElement("div", { className: "createClientFieldTitle" },
                    "R\u00E9gimen ",
                    React.createElement("b", { style: { color: "#F68218" } }, "*")),
                React.createElement("select", { className: "createClientFieldDropdown", value: regimeInput, onChange: function (ev) { return setRegimeInput(ev.target.value); } },
                    React.createElement("option", { value: "1" }, "IVA General - ISR Mensual"),
                    React.createElement("option", { value: "2" }, "IVA General - ISR Trimestral"),
                    React.createElement("option", { value: "3" }, "Peque\u00F1o Contribuyente"))),
            React.createElement("div", { className: "createClientFieldContainer" },
                React.createElement("div", { className: "createClientFieldTitle" },
                    "Tipo de Cliente ",
                    React.createElement("b", { style: { color: "#F68218" } }, "*")),
                React.createElement("select", { className: "createClientFieldDropdown", value: clientTypeInput, onChange: function (ev) { return setClientTypeInput(ev.target.value); } },
                    React.createElement("option", { value: "1" }, "Sociedad"),
                    React.createElement("option", { value: "2" }, "Individual"))),
            React.createElement("div", { className: "createClientFieldContainer" },
                React.createElement("div", { className: "createClientFieldTitle" },
                    "Tipo ",
                    React.createElement("b", { style: { color: "#F68218" } }, "*")),
                React.createElement("select", { className: "createClientFieldDropdown", value: movementInput, onChange: function (ev) { return setMovementInput(ev.target.value); } },
                    React.createElement("option", { value: "move" }, "Con Movimiento"),
                    React.createElement("option", { value: "nomove" }, "Sin Movimiento"))),
            React.createElement("div", { className: "createClientFieldContainer" },
                React.createElement("div", { className: "createClientFieldTitle" },
                    "Automatizaci\u00F3n ",
                    React.createElement("b", { style: { color: "#F68218" } }, "*")),
                React.createElement("select", { className: "createClientFieldDropdown", value: autoInput, onChange: function (ev) { return setAutoInput(ev.target.value); } },
                    React.createElement("option", { value: "noauto" }, "Cliente No Automatizado"),
                    React.createElement("option", { value: "auto" }, "Cliente Automatizado"))),
            autoInput === "auto" &&
                React.createElement(React.Fragment, null,
                    React.createElement("hr", { className: "createClientSeparator" }),
                    React.createElement("div", { className: "createClientFieldContainer" },
                        React.createElement("div", { className: "createClientFieldTitle" },
                            "Fecha de nacimiento ",
                            React.createElement("b", { style: { color: "#F68218" } }, "*")),
                        React.createElement("input", { className: "createClientFieldInput", type: "date", autoComplete: "bday", value: birthDateInput, onChange: function (ev) { return setBirthDateInput(ev.target.value); } })),
                    React.createElement("div", { className: "createClientFieldContainer" },
                        React.createElement("div", { className: "createClientFieldTitle" },
                            "Contrase\u00F1a del portal ",
                            React.createElement("b", { style: { color: "#F68218" } }, "*")),
                        React.createElement("input", { className: "createClientFieldInput", placeholder: "Contrase\u00F1a", type: "password", autoComplete: "off", value: passwordInput, onChange: function (ev) { return setPasswordInput(ev.target.value); } })),
                    React.createElement("div", { className: "createClientFieldContainer" },
                        React.createElement("div", { className: "createClientFieldTitle" }, "NIT delegado"),
                        React.createElement("input", { className: "createClientFieldInput", placeholder: "987654321", value: delegateInput, onChange: function (ev) { return setDelegateInput(ev.target.value); } })),
                    React.createElement("div", { className: "createClientFieldContainer" },
                        React.createElement("div", { className: "createClientFieldTitle" },
                            "Descarga de archivos PDF ",
                            React.createElement("b", { style: { color: "#F68218" } }, "*")),
                        React.createElement("select", { className: "createClientFieldDropdown", value: autoPdf, onChange: function (ev) { return setAutoPdf(ev.target.value); } },
                            React.createElement("option", { value: "auto" }, "Autom\u00E1tica"),
                            React.createElement("option", { value: "noauto" }, "Manual"))),
                    movementInput === "move" && (React.createElement("div", { className: "createClientFieldContainer" },
                        React.createElement("div", { className: "createClientFieldTitle" }, "Fecha de Descarga Mensual: Compras"),
                        React.createElement("input", { className: "createClientFieldInput", placeholder: "6", value: purchDateInput, onChange: function (ev) {
                                var inputValue = ev.target.value;
                                if (/^\d+$/.test(inputValue) || inputValue === "") { // Only accept numbers
                                    setPurchDateInput(inputValue);
                                }
                            } }))),
                    movementInput === "move" && (React.createElement("div", { className: "createClientFieldContainer" },
                        React.createElement("div", { className: "createClientFieldTitle" }, "Fecha de Descarga Mensual: Ventas"),
                        React.createElement("input", { className: "createClientFieldInput", placeholder: "6", value: salesDateInput, onChange: function (ev) {
                                var inputValue = ev.target.value;
                                if (/^\d+$/.test(inputValue) || inputValue === "") { // Only accept numbers
                                    setSalesDateInput(inputValue);
                                }
                            } }))),
                    movementInput === "nomove" && (React.createElement("div", { className: "createClientFieldContainer" },
                        React.createElement("div", { className: "createClientFieldTitle" }, "Fecha de presentaci\u00F3n de declaraciones sin movimiento"),
                        React.createElement("input", { className: "createClientFieldInput", placeholder: "1", value: presentationDateInput, onChange: function (ev) {
                                var inputValue = ev.target.value;
                                if (/^\d+$/.test(inputValue) || inputValue === "") { // Only accept numbers
                                    setPresentationDateInput(inputValue);
                                }
                            } })))),
            isCreatingClient
                ?
                    React.createElement(Loader, null)
                :
                    React.createElement("button", { className: "createClientButton", onClick: params.nit ? function () { return editExistingClient(); } : function () { return createNewClient(); } }, params.nit ? 'Guardar cambios' : 'Crear cliente'))));
};
export default CreateClient;
