export var LoginSteps;
(function (LoginSteps) {
    LoginSteps["Main"] = "main";
    LoginSteps["Challenge"] = "challenge";
    LoginSteps["Forgot"] = "forgot";
    LoginSteps["Success"] = "success";
})(LoginSteps || (LoginSteps = {}));
export var Roles;
(function (Roles) {
    Roles["Superadmin"] = "Superadmin";
    Roles["Admin"] = "Admin";
    Roles["User"] = "User";
    Roles["Bot"] = "Bot";
})(Roles || (Roles = {}));
export var DataTypes;
(function (DataTypes) {
    DataTypes["String"] = "string";
    DataTypes["Date"] = "date";
    DataTypes["Datetime"] = "datetime";
})(DataTypes || (DataTypes = {}));
export var FilterTypes;
(function (FilterTypes) {
    FilterTypes["equal"] = "=";
    FilterTypes["greaterThan"] = ">";
    FilterTypes["greaterThanOrEqual"] = ">=";
    FilterTypes["lessThan"] = "<";
    FilterTypes["lessThanOrEqual"] = "<=";
})(FilterTypes || (FilterTypes = {}));
export var toastConfig = {
    position: "bottom-right",
    autoClose: 3000,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: false,
    theme: "light",
    pauseOnFocusLoss: false,
};
export var popupConfig = {
    confirmButtonColor: '#467a06',
    cancelButtonColor: '#5B5B5B',
};
export var Actions;
(function (Actions) {
    Actions["Edit"] = "edit";
    Actions["Delete"] = "delete";
    Actions["Accept"] = "accept";
    Actions["Close"] = "close";
    Actions["MoveLeft"] = "left";
    Actions["MoveRight"] = "right";
    Actions["Dismiss"] = "dismiss";
    Actions["PDF"] = "pdf";
    Actions["UploadFile"] = "upload";
    Actions["Return"] = "return";
    Actions["Enable"] = "enable";
    Actions["Disable"] = "disable";
})(Actions || (Actions = {}));
export var ActionTypes;
(function (ActionTypes) {
    ActionTypes["Button"] = "button";
    ActionTypes["Link"] = "link";
    ActionTypes["FileExplorer"] = "file";
    ActionTypes["Text"] = "text";
    ActionTypes["None"] = "none";
})(ActionTypes || (ActionTypes = {}));
export var months = [
    {
        value: "1",
        name: "Enero",
    },
    {
        value: "2",
        name: "Febrero",
    },
    {
        value: "3",
        name: "Marzo",
    },
    {
        value: "4",
        name: "Abril",
    },
    {
        value: "5",
        name: "Mayo",
    },
    {
        value: "6",
        name: "Junio",
    },
    {
        value: "7",
        name: "Julio",
    },
    {
        value: "8",
        name: "Agosto",
    },
    {
        value: "9",
        name: "Septiembre",
    },
    {
        value: "10",
        name: "Octubre",
    },
    {
        value: "11",
        name: "Noviembre",
    },
    {
        value: "12",
        name: "Diciembre",
    },
];
export var genColumnsMapping = {
    docId: "Constancia",
    emision: "Emisión",
    type: "Tipo",
    serial: "Serie",
    number: "Número",
    nit: "NIT",
    name: "Nombre",
    goods: "Bienes",
    services: "Servicios",
    export: "Exportaciones",
    import: "Importación",
    gas: "Petróleo",
    little: "Pequeño Contribuyente",
    exent: "Exento",
    taxes: "IVA",
    total: "Total"
};
export var activityActionTypes = [
    'Actualizar mes de documento',
    'Agregar permisos',
    'Crear cliente',
    'Crear documento',
    'Crear empresa/contador',
    'Crear usuario',
    'Descartar documento',
    'Descarga documentos',
    'Deshabilitar nit',
    'Deshabilitar usuario',
    'Editar cliente',
    'Editar empresa/contador',
    'Editar tipo de impuesto',
    'Editar usuario',
    'Eliminar archivo',
    'Eliminar cliente',
    'Eliminar empresa/contador',
    'Eliminar retención',
    'Eliminar usuario',
    'Habilitar nit',
    'Habilitar usuario',
    'Iniciar sesión',
    'Modificar formulario',
    'Preparación formulario',
    'Presentación formulario',
    'Remover permisos',
    'Restaurar documento',
    'Subir archivo',
    'Subir formulario',
];
export var clientStatusTypes;
(function (clientStatusTypes) {
    clientStatusTypes["Pending"] = "pending";
    clientStatusTypes["Programmed"] = "programmed";
    clientStatusTypes["Running"] = "running";
    clientStatusTypes["Downloaded"] = "downloaded";
    clientStatusTypes["Error"] = "error";
    clientStatusTypes["Validated"] = "validated";
    clientStatusTypes["Frozen"] = "frozen";
    clientStatusTypes["Presented"] = "presented";
})(clientStatusTypes || (clientStatusTypes = {}));
export var retenTypes = [
    {
        name: "Retención de ISR",
        id: 4
    },
    {
        name: "Retención de IVA",
        id: 3
    },
    // {
    //   name: "Exención de IVA",
    //   id: 5
    // },
];
export var formTypes = [
    {
        name: "SAT-1311",
        id: 1311,
        regimes: [1],
    },
    {
        name: "SAT-2237",
        id: 2237,
        regimes: [1, 2],
    },
    {
        name: "SAT-2046",
        id: 2046,
        regimes: [3],
    },
    // {
    //   name: "SAT-1608",
    //   id: 1608,
    // },
    // {
    //   name: "SAT-1361",
    //   id: 1361,
    // },
];
export var formStatus = [
    {
        name: "Paso 2 de 4: Validado",
        id: "validated",
    },
    {
        name: "Paso 3 de 4: Congelado",
        id: "frozen",
    },
    {
        name: "Paso 4 de 4: Presentado",
        id: "presented",
    },
];
export var generaIvaTaxRate = 1.12;
export var littleContributorTaxRate = 20; // 5/100 = 1/20
