var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import "./ClientStatus.scss";
import { useParams } from "react-router-dom";
import { getClient, getClientStatus } from "../../services/clientsService";
import { useSelector } from "react-redux";
import { loggedUser } from "../../UserContext/UserReducer";
import DateTransitioner from "../../components/DateTransitioner/DateTransitioner";
import { clientStatusTypes } from "../../shared/constants";
import Loader from "../../components/Loader/Loader";
import Table from "../../components/Table/Table";
import ClientStateBar from "../../components/ClientStateBar/ClientStateBar";
import { formatDate } from "../../shared/utils";
var ClientStatus = function () {
    var params = useParams();
    var userData = useSelector(loggedUser);
    var _a = useState({ nit: params.nit, company_id: "", name: "" }), nitInfo = _a[0], setNitInfo = _a[1];
    var _b = useState({}), nitStatus = _b[0], setNitStatus = _b[1];
    var _c = useState(params.month), currentDisplayMonth = _c[0], setCurrentDisplayMonth = _c[1];
    var _d = useState(params.year), currentDisplayYear = _d[0], setCurrentDisplayYear = _d[1];
    var _e = useState(false), loadingData = _e[0], setLoadingData = _e[1];
    var _f = useState(0), hasMovement = _f[0], setHasMovement = _f[1];
    var _g = useState(0), isAutomated = _g[0], setIsAutomated = _g[1];
    var _h = useState(""), regimeTaskName = _h[0], setRegimeTaskName = _h[1];
    var statusTableColumns = [
        {
            display: "Fase",
            name: "task",
            fontWeight: "700",
            color: "#5B5B5B",
            textAlign: "start",
        },
        {
            display: "Estado",
            name: "status",
        },
    ];
    var formStatusTableColumns = [
        {
            display: "Formulario",
            name: "task",
            fontWeight: "700",
            color: "#5B5B5B",
            textAlign: "start",
        },
        {
            display: "Estado",
            name: "status",
        },
    ];
    var initData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, clientData, statusData;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoadingData(true);
                    return [4 /*yield*/, Promise.all([
                            getClient(userData.idToken, params.nit),
                            getClientStatus(userData.idToken, params.nit, parseInt(params.month, 10), parseInt(params.year, 10)),
                        ])];
                case 1:
                    _a = _b.sent(), clientData = _a[0], statusData = _a[1];
                    if (clientData && !clientData.error) {
                        setNitInfo(clientData);
                    }
                    if (statusData && !statusData.error) {
                        setNitStatus(statusData);
                    }
                    setLoadingData(false);
                    // Save the client data we request from the backend to a hook for easy use
                    setHasMovement(clientData.has_movement);
                    setIsAutomated(clientData.is_automated);
                    switch (clientData.regime_task_name) {
                        case "isr_trimestral":
                            setRegimeTaskName("ISR trimestral");
                            break;
                        case "isr_mensual":
                            setRegimeTaskName("ISR mensual");
                            break;
                        case "pequeno_contribuyente":
                            setRegimeTaskName("Pequeño contribuyente");
                            break;
                        default:
                            setRegimeTaskName("");
                            break;
                    }
                    return [2 /*return*/];
            }
        });
    }); };
    var getStatusParts = function (currStatus, dateName) {
        var statusParts = [];
        var downloadStatuses = ['purchasesStatus', 'salesStatus', 'retentionsStatus'];
        var formsStatuses = ['form1311Status', 'form2237Status', 'form2046Status', 'form1361Status', 'form1608Status'];
        if (downloadStatuses.includes(currStatus)) {
            statusParts.push({
                name: "Pendiente",
                completed: true,
                // @ts-ignore
                info: nitStatus[currStatus] && nitStatus[currStatus] === clientStatusTypes.Pending && nitStatus[dateName]
                    ? React.createElement(React.Fragment, null,
                        React.createElement("div", null, "Registrado el"),
                        React.createElement("div", null,
                            React.createElement("b", null, formatDate(new Date(nitStatus[dateName].split("T")[0])))))
                    : undefined
            }, {
                name: "Programado",
                // @ts-ignore
                completed: nitStatus[currStatus] && ([clientStatusTypes.Error, clientStatusTypes.Programmed, clientStatusTypes.Running, clientStatusTypes.Downloaded].includes(nitStatus[currStatus])),
                // @ts-ignore
                info: nitStatus[currStatus] && nitStatus[currStatus] === clientStatusTypes.Programmed && nitStatus[dateName]
                    ? React.createElement(React.Fragment, null,
                        React.createElement("div", null, "Registrado el"),
                        React.createElement("div", null,
                            React.createElement("b", null, formatDate(new Date(nitStatus[dateName].split("T")[0])))))
                    : undefined
            }, {
                name: "En curso",
                // @ts-ignore
                completed: nitStatus[currStatus] && ([clientStatusTypes.Running, clientStatusTypes.Downloaded].includes(nitStatus[currStatus])),
                // @ts-ignore
                error: nitStatus[currStatus] && nitStatus[currStatus] === clientStatusTypes.Error,
                // @ts-ignore
                info: nitStatus[currStatus] && (nitStatus[currStatus] === clientStatusTypes.Running || nitStatus[currStatus] === clientStatusTypes.Error) && nitStatus[dateName]
                    ? React.createElement(React.Fragment, null,
                        React.createElement("div", null, "Registrado el"),
                        React.createElement("div", null,
                            React.createElement("b", null, formatDate(new Date(nitStatus[dateName].split("T")[0])))))
                    : undefined
            }, {
                name: "Descargadas",
                // @ts-ignore
                completed: nitStatus[currStatus] && nitStatus[currStatus] === clientStatusTypes.Downloaded,
                // @ts-ignore
                info: nitStatus[currStatus] && nitStatus[currStatus] === clientStatusTypes.Downloaded && nitStatus[dateName]
                    ? React.createElement(React.Fragment, null,
                        React.createElement("div", null, "Registrado el"),
                        React.createElement("div", null,
                            React.createElement("b", null, formatDate(new Date(nitStatus[dateName].split("T")[0])))))
                    : undefined
            });
        }
        else if (formsStatuses.includes(currStatus)) {
            statusParts.push({
                name: "Pendiente",
                completed: true,
                // @ts-ignore
                info: nitStatus[currStatus] && nitStatus[currStatus] === clientStatusTypes.Pending && nitStatus[dateName]
                    ? React.createElement(React.Fragment, null,
                        React.createElement("div", null, "Registrado el"),
                        React.createElement("div", null,
                            React.createElement("b", null, formatDate(new Date(nitStatus[dateName].split("T")[0])))))
                    : undefined
            }, {
                name: "Validado",
                // @ts-ignore
                completed: nitStatus[currStatus] && ([clientStatusTypes.Validated, clientStatusTypes.Frozen, clientStatusTypes.Presented].includes(nitStatus[currStatus])),
                // @ts-ignore
                info: nitStatus[currStatus] && nitStatus[currStatus] === clientStatusTypes.Validated && nitStatus[dateName]
                    ? React.createElement(React.Fragment, null,
                        React.createElement("div", null, "Registrado el"),
                        React.createElement("div", null,
                            React.createElement("b", null, formatDate(new Date(nitStatus[dateName].split("T")[0])))))
                    : undefined
            }, {
                name: "Congelado",
                // @ts-ignore
                completed: nitStatus[currStatus] && ([clientStatusTypes.Frozen, clientStatusTypes.Presented].includes(nitStatus[currStatus])),
                // @ts-ignore
                info: nitStatus[currStatus] && nitStatus[currStatus] === clientStatusTypes.Frozen && nitStatus[dateName]
                    ? React.createElement(React.Fragment, null,
                        React.createElement("div", null, "Registrado el"),
                        React.createElement("div", null,
                            React.createElement("b", null, formatDate(new Date(nitStatus[dateName].split("T")[0])))))
                    : undefined
            }, {
                name: "Presentado",
                // @ts-ignore
                completed: nitStatus[currStatus] && nitStatus[currStatus] === clientStatusTypes.Presented,
                // @ts-ignore
                info: nitStatus[currStatus] && nitStatus[currStatus] === clientStatusTypes.Presented && nitStatus[dateName]
                    ? React.createElement(React.Fragment, null,
                        React.createElement("div", null, "Registrado el"),
                        React.createElement("div", null,
                            React.createElement("b", null, formatDate(new Date(nitStatus[dateName].split("T")[0])))))
                    : undefined
            });
        }
        return statusParts;
    };
    var getStatusName = function (currStatus) {
        var statusName = "";
        switch (currStatus) {
            case "purchasesStatus":
                statusName = "Descarga de Compras";
                break;
            case "salesStatus":
                statusName = "Descarga de Ventas";
                break;
            case "retentionsStatus":
                statusName = "Descarga de Retenciones";
                break;
            case "form1311Status":
                statusName = "SAT-1311";
                break;
            case "form2046Status":
                statusName = "SAT-2046";
                break;
            case "form2237Status":
                statusName = "SAT-2237";
                break;
            case "form1608Status":
                statusName = "SAT-1608";
                break;
            case "form1361Status":
                statusName = "SAT-1361";
                break;
        }
        return statusName;
    };
    var getDownloadStatusRows = function () {
        var rows = Object.keys(nitStatus).map(function (statElem, statElemInd) {
            if (statElem.includes('Status') && !statElem.includes('form')) {
                var dateName = Object.keys(nitStatus).find(function (obj) { return obj.includes(statElem.replace("Status", "")) && !obj.includes("Status"); });
                return {
                    task: getStatusName(statElem),
                    status: React.createElement("div", { style: {
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        } },
                        React.createElement(ClientStateBar, { identifier: statElem, states: getStatusParts(statElem, dateName) })),
                };
            }
        });
        return rows.filter(function (elem) { return elem !== undefined; });
    };
    var getFormStatusRows = function () {
        var rows = Object.keys(nitStatus).map(function (statElem, statElemInd) {
            if (statElem.includes('Status') && statElem.includes('form')) {
                var dateName = Object.keys(nitStatus).find(function (obj) { return obj.includes(statElem.replace("Status", "")) && !obj.includes("Status"); });
                return {
                    task: getStatusName(statElem),
                    status: React.createElement("div", { style: {
                            width: "100%",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center"
                        } },
                        React.createElement(ClientStateBar, { identifier: statElem, states: getStatusParts(statElem, dateName) })),
                };
            }
        });
        return rows.filter(function (elem) { return elem !== undefined; });
    };
    useEffect(function () {
        if (params.month !== currentDisplayMonth
            || params.year !== currentDisplayYear
            || Object.keys(nitStatus).length === 0) {
            initData();
            setCurrentDisplayMonth(params.month);
            setCurrentDisplayYear(params.year);
        }
    }, [params]);
    return (React.createElement("div", { className: "clientStatusContainer" },
        React.createElement("div", { className: "clientStatusTitle" }, "".concat(params.nit, " - ").concat(nitInfo.name)),
        React.createElement(DateTransitioner, { defaultMonth: currentDisplayMonth, defaultYear: currentDisplayYear, redirectionPath: "/status/".concat(params.nit) }),
        loadingData
            ? React.createElement(Loader, null)
            : React.createElement(React.Fragment, null,
                React.createElement("div", { className: "clientStatusActions" }),
                Object.keys(nitStatus).length >= 0 &&
                    React.createElement("div", { className: "clientStatusSubTitle" }, "Cliente ".concat(isAutomated === 1 ? 'automatizado' : 'no automatizado', " ").concat(hasMovement === 1 ? 'con movimiento' : 'sin movimiento', " ").concat(regimeTaskName ? "de ".concat(regimeTaskName) : '')),
                hasMovement === 1 && Object.keys(nitStatus).length > 0 && getDownloadStatusRows().length > 0 &&
                    React.createElement("div", { className: "clientStatusSubTitle" }, "Procesos del Bot"),
                hasMovement === 1 && Object.keys(nitStatus).length > 0 && getDownloadStatusRows().length > 0 &&
                    React.createElement("div", { style: {
                            marginBottom: "1vw"
                        } },
                        React.createElement(Table, { columns: statusTableColumns, rows: getDownloadStatusRows(), tableRowPadding: "0.5vw 1vw", tableContrastColor: "#E0E0E0", tableContrastTextColor: "#5B5B5B", width: "70vw" })),
                Object.keys(nitStatus).length > 0 && getFormStatusRows().length > 0 &&
                    React.createElement("div", { className: "clientStatusSubTitle" }, "Formularios"),
                Object.keys(nitStatus).length > 0 && getFormStatusRows().length > 0 &&
                    React.createElement(Table, { columns: formStatusTableColumns, rows: getFormStatusRows(), tableRowPadding: "0.5vw 1vw", tableContrastColor: "#E0E0E0", tableContrastTextColor: "#5B5B5B", width: "70vw" }),
                Object.keys(nitStatus).length === 0 &&
                    React.createElement("div", { className: "clientStatusNoStatus" }, "** No hay tareas programadas para este mes **"))));
};
export default ClientStatus;
