import React from 'react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'
import { PersistGate } from 'redux-persist/integration/react'
import { store, persistor } from './UserContext/store'
import { ClientContextProvider } from './context/ClientContext'
import App from "./App"
import './style/theme.scss'

createRoot(document.getElementById('root')).render(
  <>
    {/* strict mode is causing double rendering */}
    {/* <React.StrictMode>
    </React.StrictMode>, */}
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <ClientContextProvider>
          <App />
        </ClientContextProvider>
      </PersistGate>
    </Provider>
  </>
)
