var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useState } from "react";
import { RouterProvider } from 'react-router-dom';
import { router } from './routing/routes';
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { toastConfig } from "./shared/constants";
import { SET_REFRESHED_AUTH_TOKEN, SET_USER_LOGGED_OUT, loggedUser } from "./UserContext/UserReducer";
import { refreshToken } from "./services/auth";
import { clearAllCookies } from "./shared/utils";
import LoadingScreen from "./pages/LoadingScreen/LoadingScreen";
var App = function () {
    var userData = useSelector(loggedUser);
    var _a = useState(false), isLoggedIn = _a[0], setIsLoggedIn = _a[1];
    var _b = useState(null), refreshInterval = _b[0], setRefreshInterval = _b[1];
    var dispatch = useDispatch();
    var _c = useState(true), isLoadingRefToken = _c[0], setIsLoadingRefToken = _c[1];
    var _d = useState((3600 * 1000)), refreshTokenTime = _d[0], setRefreshTokenTime = _d[1];
    var handleRefreshToken = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, newAuthToken, newIdToken, newRefreshTime, newGroups;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    if (!userData.refreshToken) return [3 /*break*/, 2];
                    setIsLoadingRefToken(true);
                    return [4 /*yield*/, refreshToken(userData.refreshToken)];
                case 1:
                    response = _d.sent();
                    newAuthToken = (_a = response === null || response === void 0 ? void 0 : response.AuthenticationResult) === null || _a === void 0 ? void 0 : _a.AccessToken;
                    newIdToken = (_b = response === null || response === void 0 ? void 0 : response.AuthenticationResult) === null || _b === void 0 ? void 0 : _b.IdToken;
                    newRefreshTime = (_c = response === null || response === void 0 ? void 0 : response.AuthenticationResult) === null || _c === void 0 ? void 0 : _c.ExpiresIn;
                    newGroups = response === null || response === void 0 ? void 0 : response.groups;
                    if (newAuthToken) {
                        dispatch(SET_REFRESHED_AUTH_TOKEN({
                            authToken: newAuthToken,
                            idToken: newIdToken,
                            groups: newGroups || []
                        }));
                        setIsLoggedIn(true);
                        if (newRefreshTime) {
                            setRefreshTokenTime(newRefreshTime * 1000);
                        }
                        setIsLoadingRefToken(false);
                    }
                    else {
                        toast("La sesión ha expirado.", __assign(__assign({}, toastConfig), { type: "error" }));
                        setIsLoggedIn(false);
                        dispatch(SET_USER_LOGGED_OUT(loggedUser));
                        clearAllCookies();
                        setIsLoadingRefToken(false);
                    }
                    return [3 /*break*/, 3];
                case 2:
                    dispatch(SET_USER_LOGGED_OUT(loggedUser));
                    setIsLoggedIn(false);
                    clearAllCookies();
                    setIsLoadingRefToken(false);
                    _d.label = 3;
                case 3: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        handleRefreshToken();
    }, []);
    useEffect(function () {
        if (isLoggedIn) {
            setRefreshInterval(setInterval(function () {
                handleRefreshToken();
            }, refreshTokenTime));
        }
        return function () {
            if (refreshInterval)
                clearInterval(refreshInterval);
        };
    }, [isLoggedIn]);
    return (React.createElement(React.Fragment, null,
        !isLoadingRefToken
            ?
                React.createElement(RouterProvider, { router: router })
            :
                React.createElement(LoadingScreen, null),
        React.createElement(ToastContainer, null)));
};
export default App;
