var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FormTable from "../../components/FormTable/FormTable";
import { arrayOrderByProperty, getPreviousMonth, getPreviousMonthYear, groupObjectsByProperty } from "../../shared/utils";
import DateTransitioner from "../../components/DateTransitioner/DateTransitioner";
import { calcFormsValues, createBotTask, editRemCredit, editRemIVA, getClient, getClientsResolves, getSummarySections, prepareForm, presentForm, setPastMonthResolves } from "../../services/clientsService";
import { useSelector } from "react-redux";
import { loggedUser } from "../../UserContext/UserReducer";
import { useClientContext } from "../../context/ClientContext";
import Loader from "../../components/Loader/Loader";
import TableActions from "../../components/TableActions/TableActions";
import { ActionTypes, Actions, toastConfig, popupConfig } from "../../shared/constants";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import "./Summary.scss";
import SummaryMonthGenerator from "../../components/SummaryMonthGenerator/SummaryMonthGenerator";
import { PDFDownloadLink } from "@react-pdf/renderer";
import InputForm from "../../components/InputForm/InputForm";
import InvalidPassword from "../../components/InvalidPassword/InvalidPassword";
import warningIcon from '../../assets/icons/warningIcon.png';
var Summary = function () {
    var navigate = useNavigate();
    var params = useParams();
    var userData = useSelector(loggedUser);
    var _a = useClientContext(), sections = _a.sections, setSections = _a.setSections;
    var _b = useState([]), formSections = _b[0], setFormSections = _b[1];
    var _c = useState(params.month), currentDisplayMonth = _c[0], setCurrentDisplayMonth = _c[1];
    var _d = useState(params.year), currentDisplayYear = _d[0], setCurrentDisplayYear = _d[1];
    var _e = useState({
        nit: params.nit,
        company_id: "",
        name: "",
        birthdate: "",
        credito_fiscal: 0,
        download_pdf: 0,
        has_movement: 0,
        is_automated: 0,
        nit_delegado: "",
        password: "",
        regime_id: 0,
        regime_task_name: "",
        invalid_password: 0,
    }), nitInfo = _e[0], setNitInfo = _e[1];
    var _f = useState(false), loadingSections = _f[0], setLoadingSections = _f[1];
    var _g = useState("0"), originalCreditoFiscal = _g[0], setOriginalCreditoFiscal = _g[1];
    var _h = useState("0"), originalCreditoIVA = _h[0], setOriginalCreditoIVA = _h[1];
    var _j = useState("0"), creditoFiscal = _j[0], setCreditoFiscal = _j[1];
    var _k = useState("0"), creditoIva = _k[0], setCreditoIva = _k[1];
    var _l = useState({
        '1311': 0,
        '2237': 0,
        '2046': 0,
    }), taxesToPay = _l[0], setTaxesToPay = _l[1];
    // Needs to be the previous one to display correctly
    var trimestralMeses = ['12', '3', '6', '9'];
    // 1,2,3,4,5,6,7,8,9,10,11,12
    //       4     7     10     
    var newTaxesToPay = __assign({}, taxesToPay); // Create a shallow copy of the current state
    var initData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var _a, dataToUse, clientInfo, sectionsData, groupedForms, groupedTables, dataSections, hasSummaryTablesSection;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setLoadingSections(true);
                    return [4 /*yield*/, Promise.all([
                            getClientsResolves(userData.idToken, params.nit, params.month, params.year),
                            getClient(userData.idToken, params.nit),
                            getSummarySections(userData.idToken, params.nit),
                        ])];
                case 1:
                    _a = _b.sent(), dataToUse = _a[0], clientInfo = _a[1], sectionsData = _a[2];
                    if (clientInfo) {
                        setNitInfo(clientInfo);
                    }
                    // TODO fix case for Peq Contribuyente
                    // If context's sections are empty then make api call to save values
                    // const sectionsData:TableFormData[] = sections.length > 0 ? sections : await getSummarySections(userData.idToken, params.nit);
                    if (sections.length === 0) {
                        setSections(Array.isArray(sectionsData) ? sectionsData : []);
                    }
                    groupedForms = groupObjectsByProperty(Array.isArray(sectionsData) ? sectionsData : [], "form_id") // Group cells by form_id (1311|2237)
                    ;
                    groupedTables = groupObjectsByProperty(Array.isArray(sectionsData) ? sectionsData : [], "section_id") // Group cells by section_id (table)
                    ;
                    dataSections = [];
                    hasSummaryTablesSection = false;
                    groupedForms.forEach(function (form, formInd) {
                        if ((form[0].section_id !== 7 && form[0].section_id !== 8) || ((form[0].section_id === 7 || form[0].section_id === 8) && !hasSummaryTablesSection)) {
                            var newSection = {
                                sectionId: form[0].section_id,
                                sectionName: form[0].name,
                                formId: form[0].form_id,
                                tables: [],
                                isSummarySection: form[0].section_id === 7 || form[0].section_id === 8
                            };
                            dataSections.push(newSection);
                            if ((form[0].section_id === 7 || form[0].section_id === 8) && !hasSummaryTablesSection) {
                                hasSummaryTablesSection = true;
                            }
                        }
                    });
                    // For each table (array of cells)
                    groupedTables.forEach(function (table, tableInd) {
                        var finalRows = [];
                        var rowParts = groupObjectsByProperty(table, "row_index"); // Group cells by row_index (row)
                        // For each row (array of cells)
                        rowParts.forEach(function (tableRow, tableRowInd) {
                            // Define row object
                            var newRow = {
                                id: "".concat(tableInd, "-").concat(tableRowInd),
                                columns: []
                            };
                            var highlightRow = false;
                            arrayOrderByProperty(tableRow, "column_index");
                            // For each row column (cell)
                            tableRow.forEach(function (rowPart, rowPartInd) {
                                var resolvedCell = dataToUse && Array.isArray(dataToUse) ? dataToUse.find(function (elem) { return elem.value_id === rowPart.value_id; }) : null;
                                if (resolvedCell) {
                                    // Initialize credit values
                                    if (resolvedCell.value_id === 92 && resolvedCell.value) {
                                        setCreditoFiscal(resolvedCell.value);
                                        setOriginalCreditoFiscal(resolvedCell.value);
                                    }
                                    if (resolvedCell.value_id === 110 && resolvedCell.value) {
                                        setCreditoIva(resolvedCell.value);
                                        setOriginalCreditoIVA(resolvedCell.value);
                                    }
                                    if (resolvedCell.value_id === 15 && clientInfo && clientInfo.regime_id === 1) { // 1311 for General Regime
                                        newTaxesToPay['1311'] = parseFloat(resolvedCell.value);
                                    }
                                    if (resolvedCell.value_id === 125 && clientInfo && (clientInfo.regime_id === 1 || clientInfo.regime_id === 2)) { // 2237 for General and Trimestral Regime
                                        newTaxesToPay['2237'] = parseFloat(resolvedCell.value);
                                    }
                                    if (resolvedCell.value_id === 170 && clientInfo && clientInfo.regime_id === 3) { // 2046 for Little Regime
                                        newTaxesToPay['2046'] = parseFloat(resolvedCell.value);
                                    }
                                    // Format number to currency
                                    var formatter = new Intl.NumberFormat('es-GT', {
                                        style: 'currency',
                                        currency: 'GTQ',
                                        maximumFractionDigits: rowPart.form_id === 1311 || rowPart.form_id === 0 || rowPart.form_id === 1
                                            ? resolvedCell.value.includes(".") ? 2 : 0
                                            : 0,
                                    });
                                    var newValue = formatter.format(parseFloat(resolvedCell.value.replace(/,/g, ''))) /* Q 2,500 */
                                        .replace(" ", "") /* Q2,500 */
                                        .replace("-", "") /* Remove minus operand */
                                        .slice(1).trim(); /* 2,500 */
                                    // Highlight next row columns
                                    highlightRow = true;
                                }
                                // Validate yellow color
                                if (rowPart.hex_color === "#F8FF7F") {
                                    var formatter = new Intl.NumberFormat('es-GT', {
                                        style: 'currency',
                                        currency: 'GTQ',
                                        maximumFractionDigits: rowPart.form_id === 1311 || rowPart.form_id === 0 || rowPart.form_id === 1
                                            ? (resolvedCell === null || resolvedCell === void 0 ? void 0 : resolvedCell.value.includes(".")) ? 2 : 0
                                            : 0,
                                    });
                                    var newValue = formatter.format(parseFloat(resolvedCell === null || resolvedCell === void 0 ? void 0 : resolvedCell.value.replace(/,/g, ''))) /* Q 2,500 */
                                        .replace(" ", "") /* Q2,500 */
                                        .slice(1).trim(); /* 2,500 */
                                    // Add current row column
                                    var newRowCol = {
                                        header: React.createElement(InputForm, { rowPart: rowPart, resolvedCellValues: resolvedCell, nit: params.nit, month: params.month, year: params.year, token: userData.idToken }),
                                        accessor: "".concat(tableInd, "-").concat(tableRowInd, "-").concat(rowPartInd, "-").concat(rowPart.value_id),
                                        bgColor: rowPart.hex_color,
                                        // TODO Add className property for automated bot to extract data
                                    };
                                    newRow.columns.push(newRowCol);
                                }
                                else {
                                    var formatter = new Intl.NumberFormat('es-GT', {
                                        style: 'currency',
                                        currency: 'GTQ',
                                        maximumFractionDigits: rowPart.form_id === 1311 || rowPart.form_id === 0 || rowPart.form_id === 1
                                            ? (resolvedCell === null || resolvedCell === void 0 ? void 0 : resolvedCell.value.includes(".")) ? 2 : 0
                                            : 0,
                                    });
                                    var newValue = formatter.format(parseFloat(resolvedCell === null || resolvedCell === void 0 ? void 0 : resolvedCell.value.replace(/,/g, ''))) /* Q 2,500 */
                                        .replace(" ", "") /* Q2,500 */
                                        .slice(1).trim(); /* 2,500 */
                                    var newRowCol = {
                                        header: (resolvedCell === null || resolvedCell === void 0 ? void 0 : resolvedCell.value) ? newValue : rowPart.value,
                                        accessor: "".concat(tableInd, "-").concat(tableRowInd, "-").concat(rowPartInd, "_").concat(rowPart.value_id),
                                        bgColor: rowPart.row_index === 0 ? "#E0E0E0" : rowPart.hex_color,
                                        // TODO Add className property for automated bot to extract data
                                    };
                                    newRow.columns.push(newRowCol);
                                }
                            });
                            finalRows.push(newRow);
                        });
                        dataSections.forEach(function (sect, sectInd) {
                            if ((table[0].section_id === 7 || table[0].section_id === 8) && sect.isSummarySection) {
                                sect.tables.push(finalRows);
                            }
                            else if (sect.formId === table[0].form_id && !sect.isSummarySection) {
                                sect.tables.push(finalRows);
                            }
                        });
                    });
                    // Update the state hook in one simple call
                    setTaxesToPay(newTaxesToPay); // Apply the updated object to the state once
                    // const sectionFormDocuments:any = await Promise.all(dataSections.map(async (sect, sectInd) => {
                    //   if (sect.formId > 1 && !sect.isSummarySection) {
                    //     return getPresentedForm(userData.idToken, params.nit, params.month, params.year, sect.formId);
                    //   }
                    // }))
                    // const mappedSectionFormDocuments:any = []
                    // sectionFormDocuments.forEach((sectFormDoc:any[], sectFormDocInd:number) => {
                    //   if (sectFormDoc && Array.isArray(sectFormDoc) && sectFormDoc.length > 0) {
                    //     mappedSectionFormDocuments.push(...sectFormDoc)
                    //   }
                    // })
                    // setFormDocuments(mappedSectionFormDocuments);
                    setFormSections(dataSections);
                    setLoadingSections(false);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (params.month !== currentDisplayMonth
            || params.year !== currentDisplayYear
            || formSections.length === 0) {
            initData();
            setCurrentDisplayMonth(params.month);
            setCurrentDisplayYear(params.year);
        }
    }, [params]);
    return (React.createElement("div", { className: 'summaryContainer' },
        React.createElement("div", { className: "summaryNitTitleContainer" },
            React.createElement("div", { className: "summaryNitTitle" }, "".concat(params.nit, " - ").concat(nitInfo.name || "")),
            nitInfo.invalid_password === 1 && React.createElement("div", { className: "summaryPasswordIndicator" },
                React.createElement(InvalidPassword, { icon: warningIcon, title: "Actualizar contrase\u00F1a", onClick: function () { navigate("/admin/client/edit/".concat(params.nit)); } }))),
        React.createElement(DateTransitioner, { redirectionPath: "/summary/".concat(params.nit), defaultMonth: currentDisplayMonth, defaultYear: currentDisplayYear }),
        React.createElement("div", { className: "summaryActionsContainer" },
            React.createElement("div", { className: "summaryActionsSubcontainer" },
                React.createElement("button", { className: "summaryFilledButton", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                        var updateDataResp;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, setPastMonthResolves(userData.idToken, params.nit, params.month, params.year)];
                                case 1:
                                    updateDataResp = _a.sent();
                                    if (updateDataResp.error) {
                                        toast(updateDataResp.message || "No fue posible actualizar el resumen", __assign(__assign({}, toastConfig), { type: "error" }));
                                    }
                                    else if (updateDataResp) {
                                        toast("Resumen actualizado", __assign(__assign({}, toastConfig), { type: "success" }));
                                        initData();
                                    }
                                    else {
                                        toast("No fue posible actualizar el resumen", __assign(__assign({}, toastConfig), { type: "error" }));
                                    }
                                    return [2 /*return*/];
                            }
                        });
                    }); } }, "Actualizar resumen con datos del periodo anterior"),
                nitInfo.has_movement === 1 &&
                    React.createElement("button", { className: "summaryFilledButton", onClick: function () {
                            Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Seguro?', html: "\n                  <div>\u00BFQuieres recalcular la informaci\u00F3n de todos los formularios?</div>\n                ", icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, entiendo las consecuencias', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                var calcResponse;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!result.isConfirmed) return [3 /*break*/, 2];
                                            return [4 /*yield*/, calcFormsValues(userData.idToken, params.nit, params.month, params.year)];
                                        case 1:
                                            calcResponse = _a.sent();
                                            if (calcResponse.error) {
                                                toast(calcResponse.message || 'No fue posible recalcular', __assign(__assign({}, toastConfig), { type: 'error' }));
                                            }
                                            else if (calcResponse) {
                                                toast('Datos recalculados con éxito', __assign(__assign({}, toastConfig), { type: 'success' }));
                                                initData();
                                            }
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); });
                        } }, "Recalcular datos"),
                React.createElement(PDFDownloadLink, { className: "billsFilledButton", document: React.createElement(SummaryMonthGenerator, { data: formSections, nitInfo: nitInfo, params: params }), fileName: "resumen-".concat(params.nit, "-").concat(params.year, "-").concat(params.month) }, "Descargar resumen del mes"),
                params.month === getPreviousMonth("".concat(new Date().getMonth() + 1)) &&
                    params.year === getPreviousMonthYear("".concat(new Date().getMonth() + 1), "".concat(new Date().getFullYear())) &&
                    nitInfo.has_movement === 1 &&
                    React.createElement("button", { className: "summaryFilledButton", onClick: function () {
                            Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Ejecutar Bot?', html: "\n                    <div>\u00BFDesea ejecutar el bot de manera manual?</div>\n                  ", inputLabel: "Por favor seleccione lo que desea descargar", input: "select", inputOptions: { all: "Todo", purchases: "Facturas de compras", sales: "Facturas de ventas", reten: "Retenciones de IVA e ISR" }, icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, ejecutar', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                var botResp;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0:
                                            if (!result.isConfirmed) return [3 /*break*/, 2];
                                            return [4 /*yield*/, createBotTask(userData.idToken, params.nit, result.value)];
                                        case 1:
                                            botResp = _a.sent();
                                            if (botResp.error) {
                                                toast(botResp.message || 'Ocurrió un error', __assign(__assign({}, toastConfig), { type: 'error' }));
                                            }
                                            else if (botResp) {
                                                toast('Proceso del bot iniciado', __assign(__assign({}, toastConfig), { type: 'success' }));
                                            }
                                            else {
                                                toast('Ocurrió un error', __assign(__assign({}, toastConfig), { type: 'error' }));
                                            }
                                            _a.label = 2;
                                        case 2: return [2 /*return*/];
                                    }
                                });
                            }); });
                        } }, "Ejecutar bot de descargas")),
            nitInfo.regime_id !== 3 &&
                React.createElement("div", { className: "summaryCreditContainer" },
                    React.createElement("div", null, "Cr\u00E9dito Fiscal Remanente del Periodo Anterior"),
                    React.createElement("input", { className: "summaryTransInput", value: creditoFiscal, onChange: function (ev) {
                            if (!isNaN(parseFloat(ev.target.value)) || ev.target.value === "") {
                                setCreditoFiscal(ev.target.value);
                            }
                            else {
                                toast("El crédtio fiscal solamente puede contener números.", __assign(__assign({}, toastConfig), { type: "warning" }));
                            }
                        }, onBlur: function () {
                            if (creditoFiscal === "") {
                                setCreditoFiscal("0");
                            }
                        } }),
                    (originalCreditoFiscal !== creditoFiscal && creditoFiscal !== "") &&
                        React.createElement(TableActions, { actionList: [
                                {
                                    action: Actions.Accept,
                                    altText: "Guardar",
                                    titleText: "Guardar cambios",
                                    type: ActionTypes.Button,
                                    onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        var creditResp;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, editRemCredit(userData.idToken, params.nit, params.month, params.year, creditoFiscal)];
                                                case 1:
                                                    creditResp = _a.sent();
                                                    if (creditResp) {
                                                        initData();
                                                        toast("Se ha guardado el crédito fiscal.", __assign(__assign({}, toastConfig), { type: "success" }));
                                                    }
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); }
                                },
                                {
                                    action: Actions.Close,
                                    iconWidth: "1vw",
                                    altText: "Cancelar",
                                    titleText: "Cancelar cambios",
                                    type: ActionTypes.Button,
                                    onClick: function () { setCreditoFiscal("".concat(originalCreditoFiscal)); }
                                }
                            ] })),
            React.createElement("div", { className: "summaryCreditContainer" },
                React.createElement("div", null, "Remanente de retenciones del IVA del per\u00EDodo anterior"),
                React.createElement("input", { className: "summaryTransInput", value: creditoIva, onChange: function (ev) {
                        if (!isNaN(parseFloat(ev.target.value)) || ev.target.value === "") {
                            setCreditoIva(ev.target.value);
                        }
                        else {
                            toast("El remanente de IVA solamente puede contener números.", __assign(__assign({}, toastConfig), { type: "warning" }));
                        }
                    }, onBlur: function () {
                        if (creditoIva === "") {
                            setCreditoIva("0");
                        }
                    } }),
                (originalCreditoIVA !== creditoIva && creditoIva !== "") &&
                    React.createElement(TableActions, { actionList: [
                            {
                                action: Actions.Accept,
                                altText: "Guardar",
                                titleText: "Guardar cambios",
                                type: ActionTypes.Button,
                                onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                    var creditResp;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, editRemIVA(userData.idToken, params.nit, params.month, params.year, creditoIva, nitInfo.regime_id)];
                                            case 1:
                                                creditResp = _a.sent();
                                                if (creditResp.error) {
                                                    toast(creditResp.message || "No fue posible guardar el remanente de IVA.", __assign(__assign({}, toastConfig), { type: "error" }));
                                                }
                                                else if (creditResp) {
                                                    initData();
                                                    toast("Se ha guardado el remanente de IVA.", __assign(__assign({}, toastConfig), { type: "success" }));
                                                }
                                                else {
                                                    toast("No fue posible guardar el remanente de IVA.", __assign(__assign({}, toastConfig), { type: "error" }));
                                                }
                                                return [2 /*return*/];
                                        }
                                    });
                                }); }
                            },
                            {
                                action: Actions.Close,
                                iconWidth: "1vw",
                                altText: "Cancelar",
                                titleText: "Cancelar cambios",
                                type: ActionTypes.Button,
                                onClick: function () { setCreditoIva("".concat(creditoIva)); }
                            }
                        ] }))),
        loadingSections
            ? React.createElement("div", { className: "summaryLoaderContainer" },
                React.createElement(Loader, null))
            : formSections.length === 0
                ?
                    React.createElement("div", { className: "summaryLoaderContainer summaryNoData" }, "No hay datos.")
                :
                    formSections.map(function (section, sectionInd) {
                        return (React.createElement("div", { key: "".concat(section.sectionId, "-").concat(sectionInd) },
                            (section.formId > 1) && (((section.formId === 1608 || section.formId === 1361) && trimestralMeses.includes(currentDisplayMonth) && (nitInfo.has_movement === 0)) || (section.formId !== 1608 && section.formId !== 1361)) ?
                                React.createElement("div", { className: "summarySectionTitle" }, section.sectionName)
                                :
                                    section.isSummarySection &&
                                        React.createElement("div", { className: "summarySectionTitle" }, "Resumen"),
                            React.createElement("div", { style: section.isSummarySection ? { display: "flex", flexDirection: "row", gap: "1vw" } : undefined }, section.tables.map(function (table, tableInd) {
                                if (section.isSummarySection) {
                                    return (React.createElement("div", { key: "".concat(sectionInd, "-").concat(tableInd) },
                                        React.createElement(FormTable, { rows: table, width: "47vw" })));
                                }
                                else if ((section.formId > 1) && (((section.formId === 1608 || section.formId === 1361) && trimestralMeses.includes(currentDisplayMonth) && (nitInfo.has_movement === 0)) || (section.formId !== 1608 && section.formId !== 1361))) {
                                    //  && (trimestralMeses.includes(currentDisplayMonth))
                                    return (React.createElement(FormTable, { key: "".concat(sectionInd, "-").concat(tableInd), rows: table, width: "95vw" }));
                                }
                            })),
                            (section.formId > 1) && (((section.formId === 1608 || section.formId === 1361) && trimestralMeses.includes(currentDisplayMonth) && (nitInfo.has_movement === 0)) || (section.formId !== 1608 && section.formId !== 1361)) &&
                                React.createElement("div", { className: "summaryConfirmationActions" },
                                    React.createElement("button", { className: "summaryFilledButton", style: {
                                            width: '15vw'
                                        }, onClick: function () {
                                            Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Seguro?', html: "\n                            <div>\u00BFQuieres iniciar el proceso automatizado? Esto le permitir\u00E1 al bot\n                            ingresar a Declaraguate para llenar y validar el formulario\n                            <b>".concat(section.sectionName.split(" ")[section.sectionName.split(" ").length - 1], "</b> para el NIT:\n                            <b>").concat(params.nit, "</b> del mes <b>").concat(params.month, "</b> del <b>").concat(params.year, "\n                            </b>.</div>\n                          "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, entiendo las consecuencias', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                                var botResponse;
                                                return __generator(this, function (_a) {
                                                    switch (_a.label) {
                                                        case 0:
                                                            if (!result.isConfirmed) return [3 /*break*/, 2];
                                                            return [4 /*yield*/, prepareForm(userData.idToken, params.nit, params.month, params.year, section.formId, nitInfo.regime_task_name)];
                                                        case 1:
                                                            botResponse = _a.sent();
                                                            if (botResponse.error) {
                                                                toast(botResponse.message || 'No fue posible preparar el formulario', __assign(__assign({}, toastConfig), { type: 'error' }));
                                                            }
                                                            else if (botResponse) {
                                                                toast('Proceso de BOT iniciado con éxito', __assign(__assign({}, toastConfig), { type: 'success' }));
                                                            }
                                                            _a.label = 2;
                                                        case 2: return [2 /*return*/];
                                                    }
                                                });
                                            }); });
                                        } }, "Preparar Formulario"),
                                    Object.keys(taxesToPay).includes("".concat(section.formId)) && taxesToPay["".concat(section.formId)] === 0 && nitInfo.has_movement === 1 &&
                                        React.createElement("button", { className: "summaryFilledButton", style: {
                                                width: '15vw'
                                            }, onClick: function () {
                                                Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Seguro?', html: "\n                              <div>\u00BFQuieres iniciar el proceso automatizado? Esto le permitir\u00E1 al bot\n                              ingresar a Declaraguate para presentar el formulario\n                              <b>".concat(section.sectionName.split(" ")[section.sectionName.split(" ").length - 1], "</b> para el NIT:\n                              <b>").concat(params.nit, "</b> del mes <b>").concat(params.month, "</b> del <b>").concat(params.year, "\n                              </b>.</div>\n                            "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, entiendo las consecuencias', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                                    var botResponse;
                                                    return __generator(this, function (_a) {
                                                        switch (_a.label) {
                                                            case 0:
                                                                if (!result.isConfirmed) return [3 /*break*/, 2];
                                                                return [4 /*yield*/, presentForm(userData.idToken, params.nit, params.month, params.year, section.formId, nitInfo.regime_task_name)];
                                                            case 1:
                                                                botResponse = _a.sent();
                                                                if (botResponse.error) {
                                                                    toast(botResponse.message || 'No fue posible preparar el formulario', __assign(__assign({}, toastConfig), { type: 'error' }));
                                                                }
                                                                else if (botResponse) {
                                                                    toast('Proceso de BOT iniciado con éxito', __assign(__assign({}, toastConfig), { type: 'success' }));
                                                                }
                                                                _a.label = 2;
                                                            case 2: return [2 /*return*/];
                                                        }
                                                    });
                                                }); });
                                            } }, "Presentar Formulario"))));
                    })));
};
export default Summary;
