var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import searchIcon from "../../../assets/icons/searchIcon.png";
import addIcon from "../../../assets/icons/addIcon.png";
import { arrayOrderByProperty } from "../../../shared/utils";
import Table from "../../../components/Table/Table";
import Loader from "../../../components/Loader/Loader";
import TableActions from "../../../components/TableActions/TableActions";
import { Actions, popupConfig, toastConfig } from "../../../shared/constants";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import "./AdminCompanies.scss";
import { getCompanies } from "../../../services/clientsService";
import { useSelector } from "react-redux";
import { loggedUser } from "../../../UserContext/UserReducer";
import { deleteCompany } from "../../../services/companyService";
var AdminCompanies = function () {
    var cols = [
        {
            display: "ID",
            name: "id",
            onClickEvent: function () { orderCompanies("id"); },
        },
        {
            display: "Nombre",
            name: "name",
            onClickEvent: function () { orderCompanies("name"); },
        },
        {
            display: "Acciones",
            name: "actions",
            avoidClicksForRow: true,
        },
    ];
    var navigate = useNavigate();
    var userData = useSelector(loggedUser);
    var _a = useState(""), searchText = _a[0], setSearchText = _a[1];
    var _b = useState([]), companyList = _b[0], setCompanyList = _b[1];
    var _c = useState([]), filteredCompanyList = _c[0], setFilteredCompanyList = _c[1];
    var _d = useState(false), companiesFetched = _d[0], setCompaniesFetched = _d[1];
    var orderCompanies = function (property) {
        var newArray = __spreadArray([], companyList, true);
        arrayOrderByProperty(newArray, property);
        setFilteredCompanyList(newArray);
    };
    var searchHandler = function (ev) {
        setSearchText(ev.target.value);
        var searchVal = ev.target.value.toLowerCase();
        if (searchVal === "") {
            setFilteredCompanyList(companyList);
        }
        else {
            var filteredResults = companyList.filter(function (company) { return company.company_name.toLowerCase().includes(searchVal)
                || company.company_id.toLowerCase().includes(searchVal); });
            setFilteredCompanyList(filteredResults);
        }
    };
    var getCompanyRows = function (allCompanies) {
        return allCompanies.map(function (comp, ind) {
            return {
                id: comp.company_id,
                name: comp.company_name,
                actions: React.createElement(TableActions, { actionList: [
                        {
                            action: Actions.Edit,
                            altText: "editar",
                            titleText: "Editar Empresa",
                            onClick: function () {
                                navigate("/admin/company/edit/".concat(comp.company_id));
                            }
                        },
                        {
                            action: Actions.Delete,
                            altText: "eliminar",
                            titleText: "Eliminar Empresa",
                            onClick: function () {
                                Swal.fire(__assign(__assign({}, popupConfig), { title: '¿Seguro?', html: "\n                    <div>\n                      \u00BFQuieres eliminar la empresa <b>".concat(comp.company_name, "</b> con id\n                      <b>").concat(comp.company_id, "</b>? Se eliminar\u00E1 toda su informaci\u00F3n relacionada, incluyendo contadores, facturas, etc.\n                    </div>\n                  "), icon: 'warning', showCancelButton: true, confirmButtonText: 'Sí, entiendo las consecuencias', cancelButtonText: 'Cancelar' })).then(function (result) { return __awaiter(void 0, void 0, void 0, function () {
                                    var deleteResp;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0:
                                                if (!result.isConfirmed) return [3 /*break*/, 2];
                                                return [4 /*yield*/, deleteCompany(userData.idToken, comp.company_id)];
                                            case 1:
                                                deleteResp = _a.sent();
                                                if (deleteResp) {
                                                    toast("Empresa eliminada correctamente", __assign(__assign({}, toastConfig), { type: "success" }));
                                                    initData();
                                                }
                                                _a.label = 2;
                                            case 2: return [2 /*return*/];
                                        }
                                    });
                                }); });
                            }
                        },
                    ] }),
            };
        });
    };
    var initData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var compResp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getCompanies(userData.idToken)];
                case 1:
                    compResp = _a.sent();
                    setCompanyList(compResp);
                    setFilteredCompanyList(compResp);
                    setCompaniesFetched(true);
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        initData();
    }, []);
    return (React.createElement("div", { className: "adminCompaniesContainer" },
        React.createElement("div", { className: "adminCompaniesToolbar" },
            React.createElement("div", { className: "adminCompaniesToolbarLeft" },
                React.createElement("div", { className: "adminCompaniesTitle" }, "Empresas - Contadores")),
            React.createElement("div", { className: "adminCompaniesToolbarRight" },
                React.createElement("div", { className: "adminCompaniesSearchbar" },
                    React.createElement("img", { src: searchIcon, alt: "Search" }),
                    React.createElement("input", { placeholder: "Buscar por nombre o id...", onChange: function (ev) { return searchHandler(ev); } })),
                React.createElement(Link, { className: "adminCompaniesButton", title: "Crear Empresa", to: "/admin/company/create" },
                    React.createElement("img", { src: addIcon, alt: "Crear" })))),
        filteredCompanyList.length > 0 &&
            React.createElement(Table, { columns: cols, rows: getCompanyRows(filteredCompanyList), width: "95vw", paginate: true }),
        filteredCompanyList.length === 0 && searchText !== ""
            ? React.createElement("div", { className: "adminCompaniesNoResults" }, "No se encontraron resultados.")
            : filteredCompanyList.length === 0 && companiesFetched
                ?
                    React.createElement("div", { className: "adminCompaniesNoResults" }, "No se encontraron resultados.")
                :
                    filteredCompanyList.length === 0 &&
                        React.createElement("div", { className: "adminCompaniesNoResults" },
                            React.createElement(Loader, null))));
};
export default AdminCompanies;
