var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useEffect, useRef, useState } from "react";
import "./NewFormPopup.scss";
import { toast } from "react-toastify";
import { formStatus, formTypes, toastConfig } from "../../shared/constants";
import { useSelector } from "react-redux";
import { loggedUser } from "../../UserContext/UserReducer";
import { isParseableToFloat } from "../../shared/utils";
import { createRetention, updateClientStatus } from "../../services/clientsService";
var NewFormPopup = function (props) {
    var openState = props.openState, setIsOpen = props.setIsOpen, refreshData = props.refreshData, params = props.params, clientData = props.clientData;
    var userData = useSelector(loggedUser);
    var popupRef = useRef(null);
    var _a = useState(""), docIdentifier = _a[0], setDocIdentifier = _a[1];
    var _b = useState("1311"), typeInput = _b[0], setTypeInput = _b[1];
    var _c = useState(""), accessInput = _c[0], setAccessInput = _c[1];
    var _d = useState(""), totalInput = _d[0], setTotalInput = _d[1];
    var _e = useState("2 de 4: Validado"), stateInput = _e[0], setStateInput = _e[1];
    var _f = useState('validated'), innerStateInput = _f[0], setInnerStateInput = _f[1];
    var validateInputs = function () {
        var inputsAreValid = true;
        if (docIdentifier === "") {
            inputsAreValid = false;
            toast("Debe ingresar un número de formulario", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (accessInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar un n\u00FAmero de acceso", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        if (totalInput === "") {
            inputsAreValid = false;
            toast("Debe ingresar un total", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        else if (!isParseableToFloat(totalInput)) {
            inputsAreValid = false;
            toast("El total debe ser un número", __assign(__assign({}, toastConfig), { type: "warning", position: "bottom-center" }));
        }
        return inputsAreValid;
    };
    var createNewForm = function () { return __awaiter(void 0, void 0, void 0, function () {
        var currDate, newRetentionResp, statusResp;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!validateInputs()) return [3 /*break*/, 4];
                    currDate = new Date();
                    return [4 /*yield*/, createRetention(userData.idToken, docIdentifier, params.nit, parseFloat(totalInput), "".concat(params.year, "-").concat(params.month.padStart(2, '0'), "-").concat(String(currDate.getDate()).padStart(2, '0')), typeInput === "" ? 1311 : parseInt(typeInput), stateInput, accessInput)];
                case 1:
                    newRetentionResp = _a.sent();
                    if (!newRetentionResp.error) return [3 /*break*/, 2];
                    toast(newRetentionResp.message ? newRetentionResp.message : "Ocurrió un error, por favor intente de nuevo en un momento", __assign(__assign({}, toastConfig), { type: "error", position: "bottom-center" }));
                    return [3 /*break*/, 4];
                case 2: return [4 /*yield*/, updateClientStatus(userData.idToken, params.nit, parseInt(params.month), parseInt(params.year), typeInput === "" ? 1311 : parseInt(typeInput), innerStateInput, docIdentifier)];
                case 3:
                    statusResp = _a.sent();
                    if (statusResp.error) {
                        toast(newRetentionResp.message ? newRetentionResp.message : "No fue posible actualizar el estado del formulario SAT-".concat(typeInput), __assign(__assign({}, toastConfig), { type: "error", position: "bottom-center" }));
                    }
                    toast("Documento creado exitosamente", __assign(__assign({}, toastConfig), { type: "success", position: "bottom-center" }));
                    // Clear inputs to enter new document
                    clearInputs();
                    refreshData();
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var clearInputs = function () {
        setDocIdentifier("");
        setAccessInput("");
        setTotalInput("");
    };
    useEffect(function () {
        var allowedRegimes = [];
        formTypes.forEach(function (reg) {
            if (reg.regimes.includes(clientData.regime_id)) {
                allowedRegimes.push(reg);
            }
        });
        if (allowedRegimes.length > 0) {
            setTypeInput("".concat(allowedRegimes[0].id));
        }
    }, [clientData]);
    useEffect(function () {
        var handleClickOutside = function (event) {
            if (popupRef.current && !popupRef.current.contains(event.target)) {
                // Clicked outside the popup
                setIsOpen(false);
            }
        };
        document.addEventListener('click', handleClickOutside);
        return function () {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []); // Run the effect only once when the component mounts
    return (openState
        ?
            React.createElement("div", { className: "newFormPopupContainer", ref: popupRef },
                React.createElement("div", { className: "newFormPopupHeader" }, "Subir Formulario"),
                React.createElement("div", { className: "newFormPopupBody" },
                    React.createElement("div", { className: "newFormPopupInputContainer", style: { marginTop: "0.5vw" } },
                        React.createElement("div", { className: "newFormPopupInputLeft" }, "Tipo"),
                        React.createElement("div", { className: "newFormPopupInputRight" },
                            React.createElement("select", { className: "newFormPopupDropdown", value: typeInput, onChange: function (ev) { return setTypeInput(ev.target.value); } }, formTypes.map(function (opt, optInd) {
                                if (opt.regimes.includes(clientData.regime_id)) {
                                    return (React.createElement("option", { key: "newRetenTypeOpt-".concat(opt.id, "-").concat(optInd), value: opt.id }, opt.name));
                                }
                            })))),
                    React.createElement("div", { className: "newFormPopupInputContainer" },
                        React.createElement("div", { className: "newFormPopupInputLeft" }, "No. de formulario"),
                        React.createElement("div", { className: "newFormPopupInputRight" },
                            React.createElement("input", { className: "newFormPopupTransInput", placeholder: "000000000", value: docIdentifier, onChange: function (ev) { setDocIdentifier(ev.target.value); } }))),
                    React.createElement("div", { className: "newFormPopupInputContainer" },
                        React.createElement("div", { className: "newFormPopupInputLeft" }, "No. de acceso"),
                        React.createElement("div", { className: "newFormPopupInputRight" },
                            React.createElement("input", { className: "newFormPopupTransInput", placeholder: "000000000", value: accessInput, onChange: function (ev) { return setAccessInput(ev.target.value); } }))),
                    React.createElement("div", { className: "newFormPopupInputContainer" },
                        React.createElement("div", { className: "newFormPopupInputLeft" }, "Estado"),
                        React.createElement("div", { className: "newFormPopupInputRight" },
                            React.createElement("select", { className: "newFormPopupDropdown", value: stateInput, onChange: function (ev) {
                                    setStateInput(ev.target.value);
                                    var newInnerState = formStatus.find(function (elem) { return elem.name === ev.target.value; });
                                    setInnerStateInput(newInnerState ? newInnerState.id : 'validated');
                                } }, formStatus.map(function (opt, optInd) {
                                return (React.createElement("option", { key: "newRetenStateOpt-".concat(opt.id, "-").concat(optInd), value: opt.name }, opt.name));
                            })))),
                    React.createElement("div", { className: "newFormPopupInputContainer" },
                        React.createElement("div", { className: "newFormPopupInputLeft" }, "Total a pagar"),
                        React.createElement("div", { className: "newFormPopupInputRight" },
                            React.createElement("input", { className: "newFormPopupTransInput", value: totalInput, placeholder: "0.00", onChange: function (ev) { return setTotalInput(ev.target.value); } })))),
                React.createElement("div", { className: "newFormPopupFooter" },
                    React.createElement("button", { className: "newFormPopupFilledButton newFormPopupDanger", onClick: function () { return setIsOpen(false); } }, "Cerrar"),
                    React.createElement("button", { className: "newFormPopupFilledButton newFormPopupDanger", onClick: function () { return clearInputs(); } }, "Limpiar"),
                    React.createElement("button", { className: "newFormPopupFilledButton newFormPopupSuccess", onClick: function () { return createNewForm(); } }, "Guardar y nuevo")))
        :
            React.createElement(React.Fragment, null));
};
export default NewFormPopup;
